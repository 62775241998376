import React, { useState, useEffect } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { Row, Col, ProgressBar, Dropdown } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { FilterContainer, Filters } from "./filterConatiner/filterConatiner";
import { QuoteCard, QuoteSkelton } from "./quoteCard/quoteCard";
import { AddOnsCard } from "./addOnCard/addOnCard";
import { SendQuotes } from "../../components/Popup/SendQuotes";
import { Loader, MultiSelect } from "components";
import KnowMorePopup from "./quotesPopup/knowMorePopup/knowMorePopup";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import ClaimModal from "./quotesPopup/renewal-claim/renewal-claim";
import {
  TabWrapper,
  Tab,
  BackButton,
  FloatButton,
  ToasterOla,
  Toaster,
} from "components";
import { reloadPage, RedirectFn, scrollToTop } from "utils";
import * as yup from "yup";
import swal from "sweetalert";
import { useLocation } from "react-router";
import {
  getQuotesData,
  getPremData,
  compareQuotes,
  clear,
  setQuotesList,
  MasterLogoList,
  setQuotesLoaded,
  setBuyNowSingleQuoteUpdate,
  SetLoadingCancelled,
  CancelAll,
  clearSaveQuoteError,
  setQuoteBundle,
  shortTerm,
  selectedTab,
  setzdAvailablity,
  shortTermType,
  addonConfig as clearAddonConfig,
  AddonConfig,
} from "./quote.slice";
import { setTempData } from "./filterConatiner/quoteFilter.slice";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import "./quotePage.scss";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { string } from "prop-types";
import _ from "lodash";
import { CompareContainer } from "./compare-container/compare-container";
import { useMediaPredicate } from "react-media-hook";
import { getFuelType, Prefill, LinkTrigger } from "../Home/home.slice";
import { set_temp_data, tabClick as TabClick } from "modules/Home/home.slice";
import PrevInsurerPopup2 from "./quotesPopup/prevInsurerPopup/prevInsurerPopup2";
import PrevInsurerPopup3 from "./quotesPopup/prevInsurerPopup/prevInsurerPopup3";
import { Url } from "../proposal/proposal.slice";
import CustomTooltip from "../../components/tooltip/CustomTooltip";
import { getAddonName, ContactFn } from "./quoteUtil";
import AbiblPopup from "./AbiblPopup/AbiblPopup";
import TimeoutPopup from "./AbiblPopup/TimeoutPopup";
import { useIdleTimer } from "react-idle-timer";
import { HideDuring } from "react-hide-on-scroll";
import {
  DuplicateEnquiryId,
  clrDuplicateEnquiry,
} from "modules/proposal/proposal.slice";
import { TypeReturn } from "modules/type";

export const QuotesPage = (props) => {
  const lessthan993 = useMediaPredicate("(max-width: 993px)");
  const lessthan1350 = useMediaPredicate("(max-width: 1350px)");
  const lessthan360 = useMediaPredicate("(max-width: 360px)");
  const { temp_data, prefillLoading, tabClick } = useSelector(
    (state) => state.home
  );
  const { typeAccess } = useSelector((state) => state.login);
  const { saveQuote, tempData } = useSelector((state) => state.quoteFilter);
  const loginData = useSelector((state) => state.login);
  const { duplicateEnquiry } = useSelector((state) => state.proposal);
  const {
    quotesList,
    quoteComprehesive,
    quotetThirdParty,
    quoteShortTerm,
    versionId,
    loading,
    quoteListLoading,
    errorIcBased,
    saveQuoteLoader,
    updateQuoteLoader,
    masterLogos,
    quotesLoaded,
    addOnsAndOthers,
    buyNowSingleQuoteUpdate,
    loadingCancelled,
    saveQuoteError,
    zdAvailablity,
    addonConfig,
  } = useSelector((state) => state.quotes);
  const dispatch = useDispatch();
  const location = useLocation();
  let query = new URLSearchParams(location.search);
  const enquiry_id = query.get("enquiry_id");
  const token = query.get("token") || localStorage?.SSO_user_motor;
  const { type } = props?.match?.params;
  const typeId = query.get("typeid");
  const [tab, setTab] = useState("tab1");
  const [mobileComp, setMobileComp] = useState(false);
  const journey_type = query.get("journey_type");
  const history = useHistory();
  const lessthan767 = useMediaPredicate("(max-width: 767px)");
  const lessthan359 = useMediaPredicate("(max-width: 359px)");
  const lessthan400 = useMediaPredicate("(max-width: 400px)");
  const ConfigNcb =
    token || process.env.REACT_APP_BROKER === "UIB" ? true : false;
  const keyTrigger = query.get("key");
  const extPath = `${
    process.env.REACT_APP_BASENAME !== "NA"
      ? `/${process.env.REACT_APP_BASENAME}`
      : ""
  }`;

  let userAgent = navigator.userAgent;
  let isMobileIOS = false; //initiate as false
  // device detection
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream && lessthan767) {
    isMobileIOS = true;
  }

  console.log("quoterun");

  // //---------------OLA Temp B2C block-------------------------
  // useEffect(() => {
  // 	if (
  // 		process.env?.REACT_APP_API_BASE_URL === "https://olaapi.fynity.in/api" &&
  // 		!token
  // 	) {
  // 		if (
  // 			process.env?.REACT_APP_API_BASE_URL === "https://olaapi.fynity.in/api"
  // 		) {
  // 			swal("Access Control Error. User login required.").then(() =>
  // 				reloadPage("https://ola-dashboard.fynity.in/")
  // 			);
  // 		} else if (
  // 			process.env?.REACT_APP_API_BASE_URL ===
  // 			"https://api-ola-uat.fynity.in/api"
  // 		) {
  // 			swal("Access Control Error. User login required.").then(() =>
  // 				reloadPage("http://uatoladashboard.fynity.in/")
  // 			);
  // 		} else if (process.env?.REACT_APP_BROKER === "ABIBL") {
  // 			swal("Access Control Error. User login required.").then(() =>
  // 				reloadPage("http://abibl-prod-dashboard.fynity.in/")
  // 			);
  // 		}
  // 	}
  // }, [token]);

  //Link-Click & Delivery
  useEffect(() => {
    keyTrigger && dispatch(LinkTrigger({ key: keyTrigger }));
  }, [keyTrigger]);

  // -------------------api call cancelling use effects--------------------
  useEffect(() => {
    dispatch(SetLoadingCancelled(false));
  }, []);

  useEffect(() => {
    if (loadingCancelled) {
      setTimeout(() => {
        dispatch(SetLoadingCancelled(false));
      }, 2000);
    }
  }, [loadingCancelled]);

  //-------------------Access-Control-----------------------
  useEffect(() => {
    if (!_.isEmpty(typeAccess)) {
      if (TypeReturn(type)) {
        const AccessControl = !_.isEmpty(typeAccess)
          ? _.compact(
              typeAccess.map((item) =>
                item?.product_sub_type_code
                  ? item?.product_sub_type_code.toLowerCase()
                  : null
              )
            )
          : [];
        let typeRt = TypeReturn(type) === "cv" ? "pcv" : TypeReturn(type);
        let typeRt1 = TypeReturn(type) === "cv" ? "gcv" : TypeReturn(type);
        if (
          !(AccessControl.includes(typeRt) || AccessControl.includes(typeRt1))
        ) {
          swal(
            "Error",
            "Access Control Error. Please contact the administrator for clearance.",
            "error",
            { closeOnClickOutside: false }
          ).then(() => history.replace("/"));
        }
      }
    }
  }, [typeAccess]);

  //------------------back button logic----------------------
  const back = () => {
    history.push(
      `/${type}/vehicle-details?enquiry_id=${enquiry_id}${
        token ? `&token=${token}` : ``
      }${typeId ? `&typeid=${typeId}` : ``}${
        journey_type ? `&journey_type=${journey_type}` : ``
      }`
    );
    dispatch(setQuotesList([]));
    dispatch(clear());
    dispatch(
      setTempData({
        policyType: false,
      })
    );
    dispatch(
      set_temp_data({
        newCar: false,
        breakIn: false,
        leadJourneyEnd: false,
      })
    );
  };

  //scroll to top
  useEffect(() => {
    process.env.REACT_APP_BROKER !== "ABIBL" && scrollToTop();
  }, []);

  //---------------------Prefill Api-----------------------

  //Addon-config
  useEffect(() => {
    if (enquiry_id && process.env.REACT_APP_BROKER === "ACE") {
      dispatch(AddonConfig({ enquiryId: enquiry_id }));
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enquiry_id]);

  useEffect(() => {
    if (enquiry_id && process.env.REACT_APP_BROKER === "ACE" && addonConfig) {
      dispatch(Prefill({ enquiryId: enquiry_id }));
    }
    //clear config data
    return () => {
      dispatch(clearAddonConfig(null));
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enquiry_id, addonConfig]);

  //without preselected addons
  useEffect(() => {
    if (enquiry_id && process.env.REACT_APP_BROKER !== "ACE") {
      dispatch(Prefill({ enquiryId: enquiry_id }));
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enquiry_id]);

  //set zdAvailablity
  useEffect(() => {
    dispatch(setzdAvailablity([]));
  }, []);

  const [zdlp, setZdlp] = useState(true);

  //--------------------journey url api--------------------
  const [limiter, setLimiter] = useState(0);

  useEffect(() => {
    if (
      enquiry_id &&
      temp_data?.journeyStage?.stage &&
      temp_data?.userProposal?.isBreakinCase !== "Y" &&
      limiter < 1
    ) {
      ![
        "Payment Initiated",
        "pg_redirection",
        "Policy Issued",
        "Policy Issued, but pdf not generated",
        "Policy Issued And PDF Generated",
        "payment success",
        "Payment Failed",
        "Inspection Accept",
      ].includes(temp_data?.journeyStage?.stage) &&
        dispatch(
          Url({
            quoteUrl: window.location.href,
            stage: "Quote - Buy Now",
            userProductJourneyId: enquiry_id,
            lsq_stage: "Quote Seen",
          })
        );

      setLimiter(1);
    }
    if (
      temp_data?.journeyStage?.stage === "Payment Initiated" ||
      temp_data?.journeyStage?.stage === "Payment Failed"
    ) {
      dispatch(DuplicateEnquiryId({ enquiryId: enquiry_id }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temp_data?.journeyStage?.stage]);

  //generate new enquiry id.
  useEffect(() => {
    if (duplicateEnquiry?.enquiryId) {
      swal(
        "Please Note",
        "Payment status is Incomplete. Proposal update required.",
        "info"
      ).then(() => {
        reloadPage(
          `${window.location.protocol}//${window.location.host}${
            process.env.REACT_APP_BASENAME !== "NA"
              ? `/${process.env.REACT_APP_BASENAME}`
              : ``
          }/${type}/quotes?enquiry_id=${duplicateEnquiry?.enquiryId}${
            token
              ? `&token=${token}${
                  journey_type ? `&journey_type=${journey_type}` : ``
                }`
              : ""
          }`
        );
      });
    }
    return () => {
      dispatch(clrDuplicateEnquiry());
    };
  }, [duplicateEnquiry]);

  //----------------Journey already submitted----------------------
  const PaymentSuccessfulStages = [
    "Policy Issued And PDF Generated",
    "Policy Issued",
    "Policy Issued, but pdf not generated",
    "payment success",
  ];

  useEffect(() => {
    if (PaymentSuccessfulStages.includes(temp_data?.journeyStage?.stage)) {
      swal("Info", "This Proposal has already been submitted", "info").then(
        () =>
          temp_data?.journeyStage?.stage !== "Payment Failed"
            ? reloadPage(
                `${window.location.protocol}//${window.location.host}${
                  process.env.REACT_APP_BASENAME !== "NA"
                    ? `/${process.env.REACT_APP_BASENAME}`
                    : ``
                }/payment-success${
                  enquiry_id ? `?enquiry_id=${enquiry_id}` : ``
                }`
              )
            : reloadPage(
                `${window.location.protocol}//${window.location.host}${
                  process.env.REACT_APP_BASENAME !== "NA"
                    ? `/${process.env.REACT_APP_BASENAME}`
                    : ``
                }/payment-failure${
                  enquiry_id ? `?enquiry_id=${enquiry_id}` : ``
                }`
              )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temp_data?.journeyStage?.stage]);

  //---------------------getLogoList-----------------------------

  useEffect(() => {
    if (masterLogos?.length === 0 && location.pathname === `/${type}/quotes`) {
      dispatch(MasterLogoList());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterLogos]);

  //----------------------check enquiry---------------------

  useEffect(() => {
    if (location.pathname === `/${type}/quotes`) {
      if (temp_data?.enquiry_id || (enquiry_id && enquiry_id !== "null")) {
      } else {
        swal("Info", "Enquiry id not found, redirecting to homepage", "info", {
          closeOnClickOutside: false,
        }).then(() =>
          history.replace(
            `/${type}/lead-page?enquiry_id=${enquiry_id}${
              token ? `&token=${token}` : ``
            }${typeId ? `&typeid=${typeId}` : ``}${
              journey_type ? `&journey_type=${journey_type}` : ``
            }`
          )
        );
      }
    }

    //-------------Redirection after breakin submission--------------
    if (
      temp_data?.userProposal?.isBreakinCase === "Y" ||
      temp_data?.userProposal?.isBreakinCase === "y"
    ) {
      swal("Info", "Breakin policy already generated.", "info", {
        closeOnClickOutside: false,
      }).then(() =>
        token
          ? reloadPage(RedirectFn())
          : history.replace(
              `/${type}/lead-page?enquiry_id=${enquiry_id}${
                token ? `&token=${token}` : ``
              }${typeId ? `&typeid=${typeId}` : ``}${
                journey_type ? `&journey_type=${journey_type}` : ``
              }`
            )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temp_data]);

  const { handleSubmit, register, errors, watch, control, setValue } = useForm({
    // resolver: yupResolver(yupValidate),
    // mode: "all",
    // reValidateMode: "onBlur",
  });

  const [sendQuotes, setSendQuotes] = useState(false);
  const [sendPdf, setSendPdf] = useState(false);

  //------------Compare Fn-------------------

  const [compare, setCompare] = useState(false);
  const [compareData, setComapreData] = useState(false);

  const [sortIcon, setSortIcon] = useState("2");

  //-------------------sort Function-------------------------

  const sortOptions = [
    {
      name: "Premium",
      label: "Premium",
      value: "2",
      id: "2",
      icon: `${extPath}/assets/images/arrow-down.png`,
    },
    {
      name: "Premium",
      label: "Premium",
      value: "3",
      id: "3",
      icon: `${extPath}/assets/images/arrow-up.png`,
    },
    {
      name: "IDV",
      label: "IDV",
      value: "4",
      id: "4",
      icon: `${extPath}/assets/images/arrow-down.png`,
    },
    {
      name: "IDV",
      label: "IDV",
      value: "5",
      id: "5",
      icon: `${extPath}/assets/images/arrow-up.png`,
    },
  ];

  const [sortBy, setSortBy] = useState(2);

  const sortByData = watch("sort-by");

  useEffect(() => {
    setSortBy(sortByData?.id);
  }, [sortByData]);

  const [quoteComprehesiveSortedFinal, setQuoteComprehesiveSortedFinal] =
    useState(quoteComprehesive);
  const [quoteThirdPartySortedFinal, setQuoteThirdPartySortedFinal] =
    useState(quotetThirdParty);

  //--------------quote loading initiated-------------------

  useEffect(() => {
    if (quotesLoaded > 0) {
      setQuotesLoadingInitiated(false);
    }
  }, [quotesLoaded]);

  //auto selection of Third party if previously selected.
  useEffect(() => {
    if (
      tab === "tab1" &&
      temp_data?.quoteLog &&
      temp_data?.quoteLog?.premiumJson?.policyType === "Third Party"
    ) {
      document.getElementById("tab2") &&
        document.getElementById("tab2").click();
    }
  }, [temp_data?.quoteLog]);

  //-----------------calling the basic getProductApi--------------------------

  //incase of renewal
  const [showClaimModal, setClaimModal] = useState(false);
  //Toaster
  const [toasterShownClaim, setToasterShownClaim] = useState(true);
  const [callToasterClaim, setCallToasterClaim] = useState(false);

  //Toaster- prev popup
  const [toasterShownPreIc, setToasterShownPreIc] = useState(true);
  const [callToasterPreIc, setCallToasterPreIc] = useState(false);

  useEffect(() => {
    if (
      temp_data?.expiry &&
      temp_data?.expiry !== "New" &&
      !_.isEmpty(temp_data) &&
      temp_data?.isToastShown !== "Y" &&
      temp_data?.journeyCategory === "PCV" &&
      process.env.REACT_APP_BROKER === "ACE" &&
      !temp_data?.newCar
    ) {
      setCallToasterPreIc(true);
      // setClaimModal(true);
    } else {
    }
  }, [temp_data]);

  useEffect(() => {
    if (
      !_.isEmpty(temp_data) &&
      !temp_data?.isClaimVerified &&
      temp_data?.corporateVehiclesQuoteRequest?.isRenewal === "Y"
    ) {
      setCallToasterClaim(true);
      // setClaimModal(true);
    } else {
    }
  }, [temp_data]);

  useEffect(() => {
    if (saveQuote && quotesList?.length === 0 && !buyNowSingleQuoteUpdate) {
      setQuotesLoadingInitiated(true);
      console.log("BreakinCheck", temp_data?.breakIn);
      const data = {
        productSubTypeId: temp_data?.productSubTypeId
          ? temp_data?.productSubTypeId
          : TypeReturn(type) === "bike"
          ? 2
          : 1,
        businessType: temp_data?.newCar
          ? "newbusiness"
          : temp_data?.breakIn
          ? "breakin"
          : "rollover",

        policyType: temp_data?.odOnly ? "own_damage" : "comprehensive",

        selectedPreviousPolicyType: temp_data?.newCar
          ? "N/A"
          : (tempData?.policyType && tempData?.policyType !== 0) ||
            temp_data?.policyType
          ? tempData?.policyType || temp_data?.policyType
          : "Comprehensive",

        premiumType:
          temp_data?.breakIn && !temp_data?.newCar
            ? temp_data?.odOnly
              ? "own_damage_breakin"
              : "breakin"
            : temp_data?.odOnly
            ? "own_damage"
            : "rollover",

        previousInsurer: temp_data?.prevIc ? temp_data?.prevIc : "",
        enquiryId: enquiry_id,
        ...(temp_data?.corporateVehiclesQuoteRequest?.isRenewal === "Y" && {
          isRenewal: "Y",
        }),
      };
      dispatch(clear());
      dispatch(getQuotesData(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveQuote, quotesList?.length, buyNowSingleQuoteUpdate]);

  //--------calling comprehensive policy quotes based on product list--------------

  useEffect(() => {
    if (
      quotesList?.comprehensive &&
      quotesList?.comprehensive?.length > 0 &&
      quoteComprehesive?.length === 0 &&
      !buyNowSingleQuoteUpdate
    ) {
      const comprehensive = quotesList?.comprehensive;

      let renewalQuotes = comprehensive.filter((x) => x.isRenewal === "Y");
      let nonRenewalQuotes = comprehensive.filter((x) => x.isRenewal !== "Y");

      console.log(renewalQuotes, "renewalQuotes");
      console.log(nonRenewalQuotes, "nonRenewalQuotes");

      let quotes = [...renewalQuotes, ...nonRenewalQuotes];

      quotes.forEach((el) => {
        const data = {
          enquiryId: temp_data?.enquiry_id || enquiry_id,
          policyId: el.policyId,
          ...(el?.isRenewal === "Y" && {
            is_renewal: "Y",
          }),
        };
        const ic = el.companyAlias;
        const icId = el.companyId;
        const typePolicy = "comprehensive";
        const typeUrl = TypeReturn(type);
        setTimeout(() => {
          dispatch(
            getPremData(ic, icId, data, typePolicy, quoteComprehesive, typeUrl)
          );
        }, 100);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quotesList?.comprehensive, quoteComprehesive?.length]);

  //------------------calling tp policy quotes based on product list

  useEffect(() => {
    if (
      quotesList?.third_party &&
      quotesList?.third_party?.length > 0 &&
      quotetThirdParty?.length === 0 &&
      !buyNowSingleQuoteUpdate
    ) {
      const third_party = quotesList?.third_party;

      console.log(third_party, "third_party");

      let renewalQuotes = third_party.filter((x) => x.isRenewal === "Y");
      let nonRenewalQuotes = third_party.filter((x) => x.isRenewal !== "Y");
      console.log(renewalQuotes, "renewalQuotes");
      console.log(nonRenewalQuotes, "nonRenewalQuotes");

      let quotes = [...renewalQuotes, ...nonRenewalQuotes];
      console.log(quotes, "quotes123");

      quotes.forEach((el) => {
        const data = {
          enquiryId: temp_data?.enquiry_id || enquiry_id,
          policyId: el.policyId,
          ...(el?.isRenewal === "Y" && {
            is_renewal: "Y",
          }),
        };
        const ic = el.companyAlias;
        const icId = el.companyId;
        const typePolicy = "third_party";
        const typeUrl = TypeReturn(type);
        setTimeout(() => {
          dispatch(
            getPremData(ic, icId, data, typePolicy, quotetThirdParty, typeUrl)
          );
        }, 100);
      });
    }
  }, [quotesList?.third_party, quotetThirdParty?.length]);

  //-----------------calling shortTermQuotes--------------------
  console.log("Ql", quotesList);
  useEffect(() => {
    if (
      quotesList?.short_term &&
      quotesList?.short_term?.length > 0 &&
      quoteShortTerm?.length === 0 &&
      !buyNowSingleQuoteUpdate
    ) {
      const quoteShortTerm = quotesList?.short_term;

      let renewalQuotes = quoteShortTerm.filter((x) => x.isRenewal === "Y");
      let nonRenewalQuotes = quoteShortTerm.filter((x) => x.isRenewal !== "Y");

      console.log(renewalQuotes, "renewalQuotes");
      console.log(nonRenewalQuotes, "nonRenewalQuotes");

      let quotes = [...renewalQuotes, ...nonRenewalQuotes];

      quotes.forEach((el) => {
        const data = {
          enquiryId: temp_data?.enquiry_id || enquiry_id,
          policyId: el.policyId,
          ...(el?.isRenewal === "Y" && {
            is_renewal: "Y",
          }),
        };
        const ic = el.companyAlias;
        const icId = el.companyId;
        const typePolicy = "shortTerm";
        const typeUrl = TypeReturn(type);
        setTimeout(() => {
          dispatch(
            getPremData(ic, icId, data, typePolicy, quoteShortTerm, typeUrl)
          );
        }, 100);
      });
    }
  }, [quotesList?.short_term, quoteShortTerm?.length]);

  //-------------------filter compare data--------------

  const [shortTerm3, setShortTerm3] = useState(false);
  const [shortTerm6, setShortTerm6] = useState(false);

  let SelectedPlans = watch("checkmark");
  SelectedPlans = !_.isEmpty(SelectedPlans)
    ? SelectedPlans?.map((item) => Number(item))
    : [];
  const CompareData = (
    tab === "tab1"
      ? shortTerm3 || shortTerm6
        ? !_.isEmpty(quoteShortTerm) && quoteShortTerm[0]
          ? quoteShortTerm
          : []
        : !_.isEmpty(quoteComprehesive) && quoteComprehesive[0]
        ? quoteComprehesive
        : []
      : !_.isEmpty(quotetThirdParty) && quotetThirdParty[0]
      ? quotetThirdParty
      : []
  )?.filter(
    (elem) =>
      !_.isEmpty(SelectedPlans) &&
      SelectedPlans?.includes(Number(elem?.policyId))
  );

  useEffect(() => {
    if (!_.isEmpty(CompareData)) {
      setCompare(true);
    } else {
      setCompare(false);
    }
  }, [CompareData]);

  // ---------------adjust scroll---------------------

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //prevInsPopup
  const [prevPopup2, setPrevPopup2] = useState(false);

  const [selectedId, setSelectedId] = useState(false);

  const [selectedCompanyName, setSelectedCompanyName] = useState(false);

  const [selectedCompanyAlias, setSelectedCompanyAlias] = useState(false);

  const [selectedIcId, setSelectedIcId] = useState(false);

  const [applicableAddonsLits, setApplicableAddonsLits] = useState(false);

  const [prevPopup3, setPrevPopup3] = useState(false);

  const [quoteData, setQuoteData] = useState(false);

  //const [onSubmit, setOnSubmit] = useState(false);

  //--------------------progressPercent------------------------

  const [progressPercent, setProgressPercent] = useState(false);
  const [quotesLoadingComplted, setQuotesLoadingCompleted] = useState(false);
  const [quotesLoadingInitiated, setQuotesLoadingInitiated] = useState(false);

  useEffect(() => {
    let totalLength =
      (quotesList?.third_party ? quotesList?.third_party?.length : 0) +
      (quotesList?.comprehensive ? quotesList?.comprehensive?.length : 0) +
      (quotesList?.short_term ? quotesList?.short_term?.length : 0);

    console.log(
      progressPercent,
      "progressPercent",
      quotesLoaded,
      "quotesLoaded",
      totalLength,
      "totalLength",
      quotesLoadingComplted,
      "quotesLoadingComplted",
      quotesLoadingInitiated,
      "quotesLoadingInitiated",
      "ALL LOADING CONDITIONSS"
    );

    if ((quotesLoaded && quotesLoaded < totalLength) || loading) {
      setQuotesLoadingCompleted(false);
      if (quotesLoaded >= totalLength) setProgressPercent(100);
      else if (quotesLoaded >= totalLength / 1.5) setProgressPercent(75);
      else if (quotesLoaded >= totalLength / 2) setProgressPercent(50);
      else if (quotesLoaded >= totalLength / 3) setProgressPercent(40);
      else if (quotesLoaded >= totalLength / 4) setProgressPercent(25);
      else setProgressPercent(15);
    } else if (!updateQuoteLoader) {
      setProgressPercent(100);
      setTimeout(() => {
        setQuotesLoadingCompleted(true);
      }, 1000);
      if (quotesLoaded >= totalLength) {
        dispatch(setQuotesLoaded(0));
      }
    }
  }, [
    quotesLoaded,
    quotesList?.third_party?.length,
    quotesList?.comprehensive?.length,
    quotesList?.short_term?.length,
    quotesList,
    loading,
    updateQuoteLoader,
  ]);

  //closing prevPopuptwo on specific errors.
  useEffect(() => {
    if (saveQuoteError) {
      (saveQuoteError === "Payment Initiated" ||
        saveQuoteError === "Payment Link Already Generated..!") &&
        dispatch(DuplicateEnquiryId({ enquiryId: enquiry_id }));
      saveQuoteError === "Transaction Already Completed" &&
        history.replace(`/payment-success?enquiry_id=${enquiry_id}`);
    }
    return () => {
      dispatch(clearSaveQuoteError());
    };
  }, [saveQuoteError]);

  ///--------------------- grouping addon based for private car----------------

  const [quoteComprehesiveGrouped, setQuoteComprehesiveGrouped] =
    useState(quoteComprehesive);

  const [quoteComprehesiveGrouped1, setQuoteComprehesiveGrouped1] = useState(
    []
  );

  const [quoteTpGrouped1, setQuoteTpGrouped1] = useState([]);

  const GetValidAdditionalKeys = (additional) => {
    var y = Object.entries(additional)
      .filter(([, v]) => Number(v) > 0)
      .map(([k]) => k);
    return y;
  };

  useEffect(() => {
    let selectedAddons = !_.isEmpty(addOnsAndOthers?.selectedAddons)
      ? addOnsAndOthers?.selectedAddons
      : [];
    var groupedQuotesByIC = _.groupBy(
      quoteComprehesive,
      (i) => i.company_alias
    );

    var quoteComprehesiveGroupedUnique = [];
    let icList = Object.keys(groupedQuotesByIC);
    let newList = !_.isEmpty(groupedQuotesByIC) ? _.map(groupedQuotesByIC) : [];

    newList.forEach((el) => {
      let BestMatch = [];
      let match = {};
      let quoteComprehesiveSortedFinalGrouped1 = el.map((i) => {
        if (_.isEmpty(match)) {
          match = i;
        } else {
          //get addon keys of last best
          let x1 =
            match?.addOnsData?.inBuilt &&
            Object.keys(match?.addOnsData?.inBuilt);
          let additional1 = match?.addOnsData?.additional;
          var y1 = GetValidAdditionalKeys(additional1);
          let z1 = [...x1, ...y1];
          let commonLast = selectedAddons
            ? selectedAddons.filter((m) => !_.isEmpty(z1) && z1?.includes(m))
            : 0;
          // get addon keys for current
          let x = i?.addOnsData?.inBuilt && Object.keys(i?.addOnsData?.inBuilt);
          let additional = i?.addOnsData?.additional;
          var y = GetValidAdditionalKeys(additional);
          let z = [...x, ...y];
          let commonCurrent = selectedAddons
            ? selectedAddons.filter((m) => !_.isEmpty(z) && z?.includes(m))
            : 0;

          // if current the elemenet has more addons common with selectedAddons than last then swap it with match.
          if (commonCurrent?.length > commonLast?.length) {
            match = i;
          }
          if (commonCurrent?.length === commonLast?.length) {
            //find premium of match & current elem when the addons matched are equal in number
            let matchAddonPremium = 0;
            let currentAddonPremium = 0;

            Object.entries(
              match?.addOnsData?.additional ? match?.addOnsData?.additional : {}
            ).forEach(([key, value]) => {
              matchAddonPremium =
                Number(matchAddonPremium) +
                (selectedAddons?.includes(key) && Number(value)
                  ? Number(value)
                  : 0);
            });

            //calculation matched - other addons
            Object.entries(
              match?.addOnsData?.other ? match?.addOnsData?.other : {}
            ).forEach(([key, value]) => {
              matchAddonPremium =
                Number(matchAddonPremium) + (Number(value) ? Number(value) : 0);
            });

            Object.entries(
              i?.addOnsData?.additional ? i?.addOnsData?.additional : {}
            ).forEach(([key, value]) => {
              currentAddonPremium =
                Number(currentAddonPremium) +
                (selectedAddons?.includes(key) && Number(value)
                  ? Number(value)
                  : 0);
            });

            //calculation matched - other addons
            Object.entries(
              i?.addOnsData?.other ? i?.addOnsData?.other : {}
            ).forEach(([key, value]) => {
              currentAddonPremium =
                Number(currentAddonPremium) +
                (Number(value) ? Number(value) : 0);
            });

            //calculation matched - other addons

            Object.entries(
              match?.addOnsData?.inBuilt ? match?.addOnsData?.inBuilt : {}
            ).forEach(([key, value]) => {
              matchAddonPremium =
                Number(matchAddonPremium) + (Number(value) ? Number(value) : 0);
            });

            Object.entries(
              i?.addOnsData?.inBuilt ? i?.addOnsData?.inBuilt : {}
            ).forEach(([key, value]) => {
              currentAddonPremium =
                Number(currentAddonPremium) +
                (Number(value) ? Number(value) : 0);
            });
            if (currentAddonPremium < matchAddonPremium) {
              match = i;
            }
          }
        }
      });
      !_.isEmpty(match) && BestMatch.push(match);

      quoteComprehesiveGroupedUnique.push(BestMatch[0]);
    });

    if (
      TypeReturn(type) === "car" ||
      TypeReturn(type) === "bike" ||
      TypeReturn(type) === "cv"
    ) {
      let uniquedGroup = quoteComprehesiveGroupedUnique.filter(Boolean);
      let uniquedGroup1 = _.uniqBy(uniquedGroup, "company_alias");

      setQuoteComprehesiveGrouped(uniquedGroup1);
    } else {
      let sortedGroupedcomp = _.sortBy(quoteComprehesive, [
        "finalPayableAmount",
      ]);

      setQuoteComprehesiveGrouped(sortedGroupedcomp);
    }
  }, [
    addOnsAndOthers?.selectedAddons,
    sortBy,
    quoteComprehesiveSortedFinal,
    quoteComprehesive,
  ]);

  //*******handling short term */

  const [ungroupedQuoteShortTerm3, setUngroupedQuoteShortTerm3] =
    useState(quoteShortTerm);
  const [ungroupedQuoteShortTerm6, setUngroupedQuoteShortTerm6] =
    useState(quoteShortTerm);

  const [quoteShortTerm3, setQuoteShortTerm3] = useState([]);
  const [quoteShortTerm6, setQuoteShortTerm6] = useState([]);

  //storing flag in redux for send quote.
  useEffect(() => {
    dispatch(shortTerm(shortTerm3 ? 3 : shortTerm6 ? 6 : ""));
    dispatch(selectedTab(tab));
  }, [shortTerm3, shortTerm6, tab]);

  //separating short term policies by tenure of 3/6
  useEffect(() => {
    if (quoteShortTerm) {
      let quoteShortTerm3months = quoteShortTerm.filter(
        (quote) =>
          quote.premiumTypeCode === "short_term_3" ||
          quote.premiumTypeCode === "short_term_3_breakin"
      );
      let quoteShortTerm6months = quoteShortTerm.filter(
        (quote) =>
          quote.premiumTypeCode === "short_term_6" ||
          quote.premiumTypeCode === "short_term_6_breakin"
      );
      setUngroupedQuoteShortTerm6(quoteShortTerm6months);
      setUngroupedQuoteShortTerm3(quoteShortTerm3months);
    }
  }, [quoteShortTerm]);

  //short term 3/6 best match
  useEffect(() => {
    if (
      (shortTerm3 && !_.isEmpty(ungroupedQuoteShortTerm3)) ||
      (shortTerm6 && !_.isEmpty(ungroupedQuoteShortTerm6))
    ) {
      let selectedAddons = !_.isEmpty(addOnsAndOthers?.selectedAddons)
        ? addOnsAndOthers?.selectedAddons
        : [];
      var groupedQuotesByIC = _.groupBy(
        shortTerm3 ? ungroupedQuoteShortTerm3 : ungroupedQuoteShortTerm6,
        (i) => i.company_alias
      );

      var quoteComprehesiveGroupedUnique = [];
      let icList = Object.keys(groupedQuotesByIC);
      let newList = !_.isEmpty(groupedQuotesByIC)
        ? _.map(groupedQuotesByIC)
        : [];

      newList.forEach((el) => {
        let BestMatch = [];
        let match = {};
        let quoteComprehesiveSortedFinalGrouped1 = el.map((i) => {
          if (_.isEmpty(match)) {
            match = i;
          } else {
            //get addon keys of last best
            let x1 =
              match?.addOnsData?.inBuilt &&
              Object.keys(match?.addOnsData?.inBuilt);
            let additional1 = match?.addOnsData?.additional;
            var y1 = GetValidAdditionalKeys(additional1);
            let z1 = [...x1, ...y1];
            let commonLast = selectedAddons
              ? selectedAddons.filter((m) => !_.isEmpty(z1) && z1?.includes(m))
              : 0;
            // get addon keys for current
            let x =
              i?.addOnsData?.inBuilt && Object.keys(i?.addOnsData?.inBuilt);
            let additional = i?.addOnsData?.additional;
            var y = GetValidAdditionalKeys(additional);
            let z = [...x, ...y];
            let commonCurrent = selectedAddons
              ? selectedAddons.filter((m) => !_.isEmpty(z) && z?.includes(m))
              : 0;

            // if current the elemenet has more addons common with selectedAddons than last then swap it with match.
            if (commonCurrent?.length > commonLast?.length) {
              match = i;
            }
            if (commonCurrent?.length === commonLast?.length) {
              //find premium of match & current elem when the addons matched are equal in number
              let matchAddonPremium = 0;
              let currentAddonPremium = 0;

              Object.entries(
                match?.addOnsData?.additional
                  ? match?.addOnsData?.additional
                  : {}
              ).forEach(([key, value]) => {
                matchAddonPremium =
                  Number(matchAddonPremium) +
                  (selectedAddons?.includes(key) && Number(value)
                    ? Number(value)
                    : 0);
              });

              //calculation matched - other addons
              Object.entries(
                match?.addOnsData?.other ? match?.addOnsData?.other : {}
              ).forEach(([key, value]) => {
                matchAddonPremium =
                  Number(matchAddonPremium) +
                  (Number(value) ? Number(value) : 0);
              });

              Object.entries(
                i?.addOnsData?.additional ? i?.addOnsData?.additional : {}
              ).forEach(([key, value]) => {
                currentAddonPremium =
                  Number(currentAddonPremium) +
                  (selectedAddons?.includes(key) && Number(value)
                    ? Number(value)
                    : 0);
              });

              //calculation matched - other addons
              Object.entries(
                i?.addOnsData?.other ? i?.addOnsData?.other : {}
              ).forEach(([key, value]) => {
                currentAddonPremium =
                  Number(currentAddonPremium) +
                  (Number(value) ? Number(value) : 0);
              });

              //calculation matched - other addons

              Object.entries(
                match?.addOnsData?.inBuilt ? match?.addOnsData?.inBuilt : {}
              ).forEach(([key, value]) => {
                matchAddonPremium =
                  Number(matchAddonPremium) +
                  (Number(value) ? Number(value) : 0);
              });

              Object.entries(
                i?.addOnsData?.inBuilt ? i?.addOnsData?.inBuilt : {}
              ).forEach(([key, value]) => {
                currentAddonPremium =
                  Number(currentAddonPremium) +
                  (Number(value) ? Number(value) : 0);
              });
              if (currentAddonPremium < matchAddonPremium) {
                match = i;
              }
            }
          }
        });
        !_.isEmpty(match) && BestMatch.push(match);

        quoteComprehesiveGroupedUnique.push(BestMatch[0]);
      });

      if (TypeReturn(type) === "cv") {
        let uniquedGroup = quoteComprehesiveGroupedUnique.filter(Boolean);
        let uniquedGroup1 = _.uniqBy(uniquedGroup, "company_alias");

        shortTerm3
          ? setQuoteShortTerm3(uniquedGroup1)
          : setQuoteShortTerm6(uniquedGroup1);
      }
      if (sortBy * 1 === 1) {
        setSortBy(1);
      }

      if (sortBy * 1 === 2) {
        setSortBy(2);
      }

      if (sortBy * 1 === 3) {
        setSortBy(3);
      }

      if (sortBy * 1 === 4) {
        setSortBy(4);
      }
    }
  }, [
    addOnsAndOthers?.selectedAddons,
    shortTerm3,
    shortTerm6,
    ungroupedQuoteShortTerm3,
    ungroupedQuoteShortTerm6,
  ]);

  //dispatching shortTerm6 or short term3 state
  useEffect(() => {
    dispatch(
      shortTermType(
        (shortTerm3 && !_.isEmpty(quoteShortTerm3)) ||
          (shortTerm6 && !_.isEmpty(quoteShortTerm6))
          ? shortTerm3
            ? quoteShortTerm3
            : quoteShortTerm6
          : false
      )
    );
  }, [shortTerm3, shortTerm6, quoteShortTerm3, quoteShortTerm6]);

  let ElectricalValue = (quote) => {
    let val = 0;
    if (addOnsAndOthers?.selectedAddons?.includes("zeroDepreciation")) {
      val =
        val +
        (quote?.accessoriesAddons?.electrical?.elecZDPremium
          ? quote?.accessoriesAddons?.electrical?.elecZDPremium * 1
          : 0);
    }
    if (addOnsAndOthers?.selectedAddons?.includes("engineProtector")) {
      val =
        val +
        (quote?.accessoriesAddons?.electrical?.elecENGPremium
          ? quote?.accessoriesAddons?.electrical?.elecENGPremium * 1
          : 0);
    }
    if (addOnsAndOthers?.selectedAddons?.includes("returnToInvoice")) {
      val =
        val +
        (quote?.accessoriesAddons?.electrical?.elecRTIPremium
          ? quote?.accessoriesAddons?.electrical?.elecRTIPremium * 1
          : 0);
    }
    if (addOnsAndOthers?.selectedAddons?.includes("ncbProtection")) {
      val =
        val +
        (quote?.accessoriesAddons?.electrical?.elecNCBPremium
          ? quote?.accessoriesAddons?.electrical?.elecNCBPremium * 1
          : 0);
    }
    if (addOnsAndOthers?.selectedAddons?.includes("consumables")) {
      val =
        val +
        (quote?.accessoriesAddons?.electrical?.elecCOCPremium
          ? quote?.accessoriesAddons?.electrical?.elecCOCPremium * 1
          : 0);
    }
    return val;
  };

  let NonElectricalValue = (quote) => {
    let val = 0;
    if (addOnsAndOthers?.selectedAddons?.includes("zeroDepreciation")) {
      val =
        val +
        (quote?.accessoriesAddons?.nonElectrical?.nonElecZDPremium
          ? quote?.accessoriesAddons?.nonElectrical?.nonElecZDPremium * 1
          : 0);
    }
    if (addOnsAndOthers?.selectedAddons?.includes("engineProtector")) {
      val =
        val +
        (quote?.accessoriesAddons?.nonElectrical?.nonElecENGPremium
          ? quote?.accessoriesAddons?.nonElectrical?.nonElecENGPremium * 1
          : 0);
    }
    if (addOnsAndOthers?.selectedAddons?.includes("returnToInvoice")) {
      val =
        val +
        (quote?.accessoriesAddons?.nonElectrical?.elecRTIPremium
          ? quote?.accessoriesAddons?.nonElectrical?.elecRTIPremium * 1
          : 0);
    }
    if (addOnsAndOthers?.selectedAddons?.includes("ncbProtection")) {
      val =
        val +
        (quote?.accessoriesAddons?.nonElectrical?.nonElecNCBPremium
          ? quote?.accessoriesAddons?.nonElectrical?.nonElecNCBPremium * 1
          : 0);
    }
    if (addOnsAndOthers?.selectedAddons?.includes("consumables")) {
      val =
        val +
        (quote?.accessoriesAddons?.nonElectrical?.nonElecCOCPremium
          ? quote?.accessoriesAddons?.nonElectrical?.nonElecCOCPremium * 1
          : 0);
    }
    return val;
  };

  let BiFuelValue = (quote) => {
    let val = 0;
    if (addOnsAndOthers?.selectedAddons?.includes("zeroDepreciation")) {
      val =
        val +
        (quote?.accessoriesAddons?.lpgCngKit?.bifuelZDPremium
          ? quote?.accessoriesAddons?.lpgCngKit?.bifuelZDPremium * 1
          : 0);
    }
    if (addOnsAndOthers?.selectedAddons?.includes("engineProtector")) {
      val =
        val +
        (quote?.accessoriesAddons?.lpgCngKit?.bifuelENGPremium
          ? quote?.accessoriesAddons?.lpgCngKit?.bifuelENGPremium * 1
          : 0);
    }
    if (addOnsAndOthers?.selectedAddons?.includes("returnToInvoice")) {
      val =
        val +
        (quote?.accessoriesAddons?.lpgCngKit?.bifuelRTIPremium
          ? quote?.accessoriesAddons?.lpgCngKit?.bifuelRTIPremium * 1
          : 0);
    }
    if (addOnsAndOthers?.selectedAddons?.includes("ncbProtection")) {
      val =
        val +
        (quote?.accessoriesAddons?.lpgCngKit?.bifuelNCBPremium
          ? quote?.accessoriesAddons?.lpgCngKit?.bifuelNCBPremium * 1
          : 0);
    }
    if (addOnsAndOthers?.selectedAddons?.includes("consumables")) {
      val =
        val +
        (quote?.accessoriesAddons?.lpgCngKit?.bifuelCOCPremium
          ? quote?.accessoriesAddons?.lpgCngKit?.bifuelCOCPremium * 1
          : 0);
    }
    return val;
  };

  useEffect(() => {
    if (quoteComprehesiveGrouped && quotesLoadingComplted && !quotesLoaded) {
      let sortedAndGrouped = quoteComprehesiveGrouped.map((quote) => {
        let additional = quote?.addOnsData?.additional
          ? Object.keys(quote?.addOnsData?.additional)
          : [];

        let additionalList = quote?.addOnsData?.additional;

        let selectedAddons = addOnsAndOthers?.selectedAddons || [];

        let totalAdditional = 0;

        let totalPayableAmountAddonNoGst = 0;

        let totalPayableAmountWithAddon = 0;

        //////////////////////////

        let totalPremiumA =
          quote?.finalOdPremium * 1 +
          ElectricalValue(quote) +
          NonElectricalValue(quote) +
          BiFuelValue(quote);

        //ncb calculation / discount part
        let totalPremiumc = quote?.finalTotalDiscount;
        let revisedNcb = quote?.deductionOfNcb;
        let otherDiscounts = quote?.icVehicleDiscount || 0;
        let addonDiscountPercentage = 0;
        let addedNcb = 0;

        //addon calculation

        selectedAddons.forEach((el) => {
          if (
            !_.isEmpty(additional) &&
            additional?.includes(el) &&
            typeof additionalList[el] === "number"
          ) {
            totalAdditional =
              totalAdditional +
              (quote?.company_alias === "royal_sundaram"
                ? el === "ncbProtection" ||
                  el === "engineProtector" ||
                  el === "tyreSecure"
                  ? Number(additionalList[el]) -
                      (Number(additionalList[el]) * addonDiscountPercentage) /
                        100 <
                      200 && Number(additionalList[el]) > 0
                    ? 200
                    : Number(additionalList[el]) -
                      (Number(additionalList[el]) * addonDiscountPercentage) /
                        100
                  : Number(additionalList[el]) -
                      (Number(additionalList[el]) * addonDiscountPercentage) /
                        100 <
                      100 && Number(additionalList[el]) > 0
                  ? 100
                  : Number(additionalList[el]) -
                    (Number(additionalList[el]) * addonDiscountPercentage) / 100
                : Number(additionalList[el]));
          }
        });

        let inbuilt = quote?.addOnsData?.inBuilt
          ? Object.keys(quote?.addOnsData?.inBuilt)
          : [];
        let allAddons = [
          "zeroDepreciation",
          "roadSideAssistance",
          "imt23",
          "keyReplace",
          "engineProtector",
          "ncbProtection",
          "consumables",
          "tyreSecure",
          "returnToInvoice",
          "lopb",
        ];
        let inbuiltList = quote?.addOnsData?.inBuilt;
        let totalInbuilt = 0;
        allAddons.forEach((el) => {
          if (
            !_.isEmpty(inbuilt) &&
            inbuilt?.includes(el) &&
            typeof inbuiltList[el] === "number"
          ) {
            totalInbuilt =
              totalInbuilt +
              (quote?.company_alias === "royal_sundaram"
                ? el === "ncbProtection" ||
                  el === "engineProtector" ||
                  el === "tyreSecure"
                  ? Number(inbuiltList[el]) -
                      (Number(inbuiltList[el]) * addonDiscountPercentage) /
                        100 <
                      200 && Number(inbuiltList[el]) > 0
                    ? 200
                    : Number(inbuiltList[el]) -
                      (Number(inbuiltList[el]) * addonDiscountPercentage) / 100
                  : Number(inbuiltList[el]) -
                      (Number(inbuiltList[el]) * addonDiscountPercentage) /
                        100 <
                      100 && Number(inbuiltList[el]) > 0
                  ? 100
                  : Number(inbuiltList[el]) -
                    (Number(inbuiltList[el]) * addonDiscountPercentage) / 100
                : Number(inbuiltList[el]));
          }
        });

        let others =
          (quote?.addOnsData?.other && Object.keys(quote?.addOnsData?.other)) ||
          [];

        let othersList = quote?.addOnsData?.other;

        let totalOther = 0;
        others.forEach((el) => {
          if (typeof othersList[el] === "number") {
            totalOther = totalOther + Number(othersList[el]);
          }
        });
        let totalAddon =
          Number(totalAdditional) + Number(totalInbuilt) + Number(totalOther);

        if (quote?.company_alias === "oriental" && TypeReturn(type) === "cv") {
          // For Oriental CV, you need to use following formula:NCB premium = (Total OD premium + Addons - discounts(anti theft)) * applicable NCB
          let extraOtherDiscounts = 0;
          let discountPercentageOriental = 0.7;

          extraOtherDiscounts = totalAddon * discountPercentageOriental;
          //for extradiscounts we don't need ZeroDep hence recalc total (addon * discount %) without zd ------
          //additional & selected
          let totalAdditional = 0;
          selectedAddons.forEach((el) => {
            if (
              additional?.includes(
                el === "zeroDepreciation" ? "nomatch" : el
              ) &&
              typeof additionalList[el] === "number"
            ) {
              totalAdditional = totalAdditional + Number(additionalList[el]);
            }
          });
          //Inbuilt
          let inbuilt = Object.keys(quote?.addOnsData?.inBuilt);
          let allAddons = [
            "roadSideAssistance",
            "imt23",
            "keyReplace",
            "engineProtector",
            "ncbProtection",
            "consumables",
            "tyreSecure",
            "returnToInvoice",
            "lopb",
          ];

          let inbuiltList = quote?.addOnsData?.inBuilt;
          let totalInbuilt = 0;
          allAddons.forEach((el) => {
            if (inbuilt?.includes(el) && typeof inbuiltList[el] === "number") {
              totalInbuilt = totalInbuilt + Number(inbuiltList[el]);
            }
          });
          //other
          let others =
            (quote?.addOnsData?.other &&
              Object.keys(quote?.addOnsData?.other)) ||
            [];
          let othersList = quote?.addOnsData?.other;
          let totalOther = 0;
          others.forEach((el) => {
            if (typeof othersList[el] === "number") {
              totalOther = totalOther + Number(othersList[el]);
            }
          });

          let extraOtherDiscounts2 =
            (Number(totalAdditional) +
              Number(totalInbuilt) +
              Number(totalOther)) *
            discountPercentageOriental;
          addedNcb =
            ((totalAddon - extraOtherDiscounts2) * Number(quote?.ncbDiscount)) /
            100;

          revisedNcb = Number(quote?.deductionOfNcb) + Number(addedNcb);
          otherDiscounts =
            (quote?.icVehicleDiscount || 0) + Number(extraOtherDiscounts2);

          totalPremiumc =
            Number(quote?.finalTotalDiscount) +
            Number(addedNcb) +
            Number(extraOtherDiscounts2);
        } else if (
          selectedAddons?.includes("imt23") &&
          (quote?.company_alias === "hdfc_ergo" ||
            quote?.company_alias === "godigit" ||
            quote?.company_alias === "shriram" ||
            quote?.company_alias === "reliance" ||
            quote?.company_alias === "sbi")
        ) {
          if (
            additional?.includes("imt23") &&
            typeof additionalList["imt23"] === "number"
          ) {
            addedNcb =
              (Number(additionalList["imt23"]) * Number(quote?.ncbDiscount)) /
              100;
          } else if (
            inbuilt?.includes("imt23") &&
            typeof inbuiltList["imt23"] === "number"
          ) {
            addedNcb = Number(
              (inbuiltList["imt23"] * Number(quote?.ncbDiscount)) / 100
            );
          }
          revisedNcb = Number(quote?.deductionOfNcb) + Number(addedNcb);
          totalPremiumc = Number(quote?.finalTotalDiscount) + Number(addedNcb);
        } else if (
          quote?.company_alias === "royal_sundaram" &&
          TypeReturn(type) === "car" &&
          addOnsAndOthers?.selectedAddons?.includes("zeroDepreciation")
        ) {
          const g1 = [
            "zeroDepreciation",
            "returnToInvoice",
            "ncbProtection",
            "engineProtector",
          ]; // 10 % on final addons
          const g2 = [
            "zeroDepreciation",
            "returnToInvoice",
            "ncbProtection",
            "lopb",
            "engineProtector",
          ]; //15% on finaladdons
          const g3 = [
            "zeroDepreciation",
            "returnToInvoice",
            "ncbProtection",
            "lopb",
            "tyreSecure",
            "keyReplace",
            "engineProtector",
          ]; // 20 % on final addons
          let addonsSelected = addOnsAndOthers?.selectedAddons;

          if (_.intersection(g3, addonsSelected)?.length >= 4) {
            addonDiscountPercentage = 20;
            revisedNcb = Number(quote?.deductionOfNcb);
          } else if (_.intersection(g2, addonsSelected)?.length === 3) {
            addonDiscountPercentage = 15;

            revisedNcb = Number(quote?.deductionOfNcb);
          } else if (_.intersection(g1, addonsSelected)?.length === 2) {
            addonDiscountPercentage = 10;

            revisedNcb = Number(quote?.deductionOfNcb);
          } else {
            addonDiscountPercentage = 0;
            revisedNcb = Number(quote?.deductionOfNcb);
            totalPremiumc = Number(quote?.finalTotalDiscount);
          }
        }
        // else if (
        //   quote?.company_alias === "hdfc_ergo" &&
        //   temp_data?.journeyCategory !== "GCV"
        // ) {
        //   revisedNcb = Number(
        //     (totalPremiumA * 1 * Number(quote?.ncbDiscount)) / 100
        //   );
        //   totalPremiumc =
        //     Number(quote?.finalTotalDiscount) +
        //     Number((totalPremiumA * 1 * Number(quote?.ncbDiscount)) / 100) -
        //     Number(quote?.deductionOfNcb);
        // }
        else if (
          quote?.company_alias === "oriental" &&
          TypeReturn(type) === "car"
        ) {
          // re-eval required addons with others
          //additional & selected
          let totalAdditional = 0;
          selectedAddons.forEach((el) => {
            if (
              additional?.includes(
                el === "zeroDepreciation" ? "nomatch" : el
              ) &&
              typeof additionalList[el] === "number"
            ) {
              totalAdditional = totalAdditional + Number(additionalList[el]);
              console.log(
                additionalList[el],
                "additionalList[el]",
                totalAdditional
              );
              // console.log(totalAdditional, "totalAdditional")
            }
          });
          //Inbuilt
          let inbuilt = Object.keys(quote?.addOnsData?.inBuilt);
          let allAddons = [
            "roadSideAssistance",
            "imt23",
            "keyReplace",
            "engineProtector",
            "ncbProtection",
            "consumables",
            "tyreSecure",
            "returnToInvoice",
            "lopb",
          ];

          let inbuiltList = quote?.addOnsData?.inBuilt;
          let totalInbuilt = 0;
          allAddons.forEach((el) => {
            if (inbuilt?.includes(el) && typeof inbuiltList[el] === "number") {
              totalInbuilt = totalInbuilt + Number(inbuiltList[el]);
            }
          });
          //other
          let others =
            (quote?.addOnsData?.other &&
              Object.keys(quote?.addOnsData?.other)) ||
            [];
          let othersList = quote?.addOnsData?.other;
          let totalOther = 0;
          others.forEach((el) => {
            if (typeof othersList[el] === "number") {
              totalOther = totalOther + Number(othersList[el]);
            }
          });
          let NcbTotalAddon =
            Number(totalAdditional) + Number(totalInbuilt) + Number(totalOther);
          revisedNcb = Math.round(
            ((totalPremiumA * 1 +
              NcbTotalAddon * 1 -
              (Number(quote?.finalTotalDiscount) -
                Number(quote?.deductionOfNcb) -
                (Number(quote.tppdDiscount)
                  ? Number(quote.tppdDiscount)
                  : 0))) *
              Number(quote?.ncbDiscount)) /
              100
          );
          totalPremiumc =
            Number(quote?.finalTotalDiscount ? quote?.finalTotalDiscount : 0) -
            //deducting the ncb sent by backend
            Number(quote?.deductionOfNcb ? quote?.deductionOfNcb : 0) +
            //calculating ncb and adding it to total discount
            Math.round(
              ((totalPremiumA * 1 +
                NcbTotalAddon * 1 -
                (Number(
                  quote?.finalTotalDiscount ? quote?.finalTotalDiscount : 0
                ) -
                  Number(quote?.deductionOfNcb ? quote?.deductionOfNcb : 0) -
                  Number(quote?.tppdDiscount ? quote?.tppdDiscount : 0))) *
                Number(quote?.ncbDiscount ? quote?.ncbDiscount : 0)) /
                100
            );
        } else {
          revisedNcb = Number(quote?.deductionOfNcb);
          totalPremiumc = Number(quote?.finalTotalDiscount);
        }

        //////cpa part
        let totalPremiumB = quote?.finalTpPremium;

        let selectedCpa = addOnsAndOthers?.selectedCpa;

        let cpa = 0;

        if (selectedCpa?.includes("Compulsory Personal Accident")) {
          if (!_.isEmpty(addOnsAndOthers?.isTenure)) {
            cpa = quote?.multiYearCpa ? quote?.multiYearCpa : 0;
          } else {
            cpa = quote?.compulsoryPaOwnDriver;
          }
        } else {
          cpa = 0;
        }

        totalPremiumB =
          Number(quote?.finalTpPremium) +
          Number(cpa) +
          (quote?.companyAlias === "sbi" &&
          selectedCpa?.includes("Compulsory Personal Accident") &&
          !_.isEmpty(addOnsAndOthers?.isTenure) &&
          quote?.coverUnnamedPassengerValue * 1
            ? quote?.coverUnnamedPassengerValue *
              (TypeReturn(type) === "bike" ? 4 : 2)
            : 0) +
          //adding additional paid driver cover in multi year cpa sbi.
          (quote?.companyAlias === "sbi" &&
          selectedCpa?.includes("Compulsory Personal Accident") &&
          !_.isEmpty(addOnsAndOthers?.isTenure) &&
          quote?.motorAdditionalPaidDriver * 1
            ? quote?.motorAdditionalPaidDriver *
              (TypeReturn(type) === "bike" ? 4 : 2)
            : 0);

        let applicableAddons = [];
        if (temp_data?.tab !== "tab2") {
          var addonsSelectedList = [];
          if (!_.isEmpty(selectedAddons) || !_.isEmpty(inbuilt)) {
            selectedAddons.forEach((el) => {
              if (
                additional?.includes(el) &&
                Number(additionalList[el]) !== 0
              ) {
                var newList = {
                  name: getAddonName(el),
                  premium: Number(additionalList[el]),
                };
                addonsSelectedList.push(newList);
              }
            });

            inbuilt.forEach((el) => {
              var newList = {
                name: getAddonName(el),
                premium: Number(inbuiltList[el]),
              };
              addonsSelectedList.push(newList);
            });

            applicableAddons = addonsSelectedList;
          } else {
            applicableAddons = [];
          }
        }

        //uv loading
        let uwLoading = 0;
        if (_.isEmpty(applicableAddons)) {
          if (
            Number(totalPremiumA) - Number(totalPremiumc) < 100 &&
            TypeReturn(type) === "bike" &&
            quote?.company_alias === "shriram" &&
            process.env?.REACT_APP_API_BASE_URL ===
              "https://apimotor.comparepolicy.com/api" &&
            (quote?.policyType === "Comprehensive" ||
              quote?.policyType === "Own Damage") &&
            false
          ) {
            uwLoading = 17;
          } else if (
            Number(totalPremiumA) - Number(totalPremiumc) < 100 &&
            TypeReturn(type) === "bike" &&
            quote?.company_alias === "shriram" &&
            process.env?.REACT_APP_API_BASE_URL !==
              "https://apimotor.comparepolicy.com/api" &&
            (quote?.policyType === "Comprehensive" ||
              quote?.policyType === "Own Damage") &&
            false
          ) {
            uwLoading = 50;
          } else {
            uwLoading = 0;
          }
        } else {
          uwLoading = 0;
        }

        let totalLoading = 0;

        if (true) {
          if (
            Number(quote?.totalLoadingAmount) > 0 ||
            Number(quote?.underwritingLoadingAmount)
          ) {
            totalLoading =
              Number(quote?.totalLoadingAmount) ||
              Number(quote?.underwritingLoadingAmount);
          } else {
            totalLoading = 0;
          }
        } else {
          totalLoading = 0;
        }

        let totalPremium =
          Number(totalAddon) +
          Number(totalPremiumA) +
          Number(totalPremiumB) -
          Number(totalPremiumc) +
          Number(uwLoading) +
          Number(totalLoading);

        let totalPremiumGst = parseInt((totalPremium * 18) / 100);

        if (temp_data?.journeyCategory === "GCV") {
          if (quote.company_alias === "oriental") {
            //In Oriental , TPPD discount is not added to third party liability for GST calc
            totalPremiumGst =
              parseInt(((totalPremium - quote?.tppdPremiumAmount) * 18) / 100) +
              (quote?.tppdPremiumAmount * 12) / 100;
          } else if (quote.company_alias === "sbi") {
            //In sbi , Basic tp - 12%, rest 18%
            totalPremiumGst =
              parseInt(((totalPremium - quote?.tppdPremiumAmount) * 18) / 100) +
              (quote?.tppdPremiumAmount * 12) / 100;
          } else if (quote.company_alias === "godigit") {
            // GST calc for other IC's in GCV
            totalPremiumGst =
              parseInt(
                Math.round(
                  ((totalPremium -
                    quote?.tppdPremiumAmount +
                    (Number(quote?.tppdDiscount)
                      ? Number(quote?.tppdDiscount)
                      : 0)) *
                    18) /
                    100 +
                    ((quote?.tppdPremiumAmount -
                      (Number(quote?.tppdDiscount)
                        ? Number(quote?.tppdDiscount)
                        : 0)) *
                      12) /
                      100
                )
              ) - (quote?.cngLpgTp * 1 ? quote?.cngLpgTp * 1 : 0);
          } else {
            // GST calc for other IC's in GCV
            totalPremiumGst =
              parseInt(
                ((totalPremium -
                  quote?.tppdPremiumAmount +
                  Number(quote?.tppdDiscount)) *
                  18) /
                  100
              ) +
              ((quote?.tppdPremiumAmount - Number(quote?.tppdDiscount)) * 12) /
                100;
          }
        }

        let FinalPremium = totalPremium + totalPremiumGst;

        console.log(FinalPremium, "quote123", quote?.company_alias);

        return {
          ...quote,
          totalAddon1: totalAddon,
          finalPremium1: FinalPremium,
          totalPremium1: totalPremium,
          totalPremiumB1: totalPremiumB,
          totalPremiumc1: totalPremiumc,
          addonDiscountPercentage1: addonDiscountPercentage,
          applicableAddons1: applicableAddons,
          gst1: totalPremiumGst,
          revisedNcb1: revisedNcb,
          totalPayableAmountWithAddon: FinalPremium,
        };

        // return {
        //   ...el,
        //   totalPayableAmountWithAddon: totalPayableAmountWithAddon + cpa,
        //   basePremium: basePrem,
        // };
      });

      if (Number(sortBy) === 3) {
        if (temp_data?.corporateVehiclesQuoteRequest?.isRenewal === "Y") {
          if (!_.isEmpty(sortedAndGrouped)) {
            let compQuotes = _.orderBy(
              sortedAndGrouped?.filter((el) => el?.isRenewal !== "Y"),
              ["totalPayableAmountWithAddon"],
              ["desc"]
            );
            let compRenewalQuote = sortedAndGrouped?.filter(
              (el) => el?.isRenewal === "Y"
            );
            let sortedcomp = [...compRenewalQuote, ...compQuotes];
            setQuoteComprehesiveGrouped1(sortedcomp);
          }
        } else {
          setQuoteComprehesiveGrouped1(
            _.orderBy(
              sortedAndGrouped,
              ["totalPayableAmountWithAddon"],
              ["desc"]
            )
          );
        }
      } else if (Number(sortBy) === 4) {
        if (temp_data?.corporateVehiclesQuoteRequest?.isRenewal === "Y") {
          if (!_.isEmpty(sortedAndGrouped)) {
            let compQuotes = _.orderBy(
              sortedAndGrouped?.filter((el) => el?.isRenewal !== "Y"),
              ["idv"],
              ["asc"]
            );
            let compRenewalQuote = sortedAndGrouped?.filter(
              (el) => el?.isRenewal === "Y"
            );
            let sortedcomp = [...compRenewalQuote, ...compQuotes];
            setQuoteComprehesiveGrouped1(sortedcomp);
          }
        } else {
          setQuoteComprehesiveGrouped1(
            _.orderBy(sortedAndGrouped, ["idv"], ["asc"])
          );
        }
      } else if (Number(sortBy) === 5) {
        if (temp_data?.corporateVehiclesQuoteRequest?.isRenewal === "Y") {
          if (!_.isEmpty(sortedAndGrouped)) {
            let compQuotes = _.orderBy(
              sortedAndGrouped?.filter((el) => el?.isRenewal !== "Y"),
              ["idv"],
              ["desc"]
            );
            let compRenewalQuote = sortedAndGrouped?.filter(
              (el) => el?.isRenewal === "Y"
            );
            let sortedcomp = [...compRenewalQuote, ...compQuotes];
            setQuoteComprehesiveGrouped1(sortedcomp);
          }
        } else {
          setQuoteComprehesiveGrouped1(
            _.orderBy(sortedAndGrouped, ["idv"], ["desc"])
          );
        }
      } else {
        if (temp_data?.corporateVehiclesQuoteRequest?.isRenewal === "Y") {
          if (!_.isEmpty(sortedAndGrouped)) {
            let compQuotes = _.orderBy(
              sortedAndGrouped?.filter((el) => el?.isRenewal !== "Y"),
              ["totalPayableAmountWithAddon"],
              ["asc"]
            );
            let compRenewalQuote = sortedAndGrouped?.filter(
              (el) => el?.isRenewal === "Y"
            );
            let sortedcomp = [...compRenewalQuote, ...compQuotes];
            setQuoteComprehesiveGrouped1(sortedcomp);
          }
        } else {
          setQuoteComprehesiveGrouped1(
            _.orderBy(
              sortedAndGrouped,
              ["totalPayableAmountWithAddon"],
              ["asc"]
            )
          );
        }
      }
    }
  }, [
    addOnsAndOthers?.selectedAddons,
    quoteComprehesiveGrouped,
    quotesLoadingComplted,
    quotesLoaded,
    sortBy,
  ]);

  useEffect(() => {
    if (quotetThirdParty && quotesLoadingComplted && !quotesLoaded) {
      //Tp Sorting
      let restructTp = !_.isEmpty(quotetThirdParty)
        ? quotetThirdParty?.map((el) => ({
            ...el,
            finalPremWithCpa:
              (el?.finalPayableAmount * 1 ? el?.finalPayableAmount * 1 : 0) +
              (addOnsAndOthers?.selectedCpa?.includes(
                "Compulsory Personal Accident"
              )
                ? !_.isEmpty(addOnsAndOthers?.isTenure)
                  ? el?.multiYearCpa * 1
                    ? el?.multiYearCpa * 1
                    : 0
                  : el?.compulsoryPaOwnDriver * 1
                  ? el?.compulsoryPaOwnDriver * 1
                  : 0
                : 0),
          }))
        : [];
      // let sortedGroupedTp1 = _.sortBy(restructTp, ["finalPremWithCpa"]);

      //Short term 3 sorting
      let sortedShortTerm3 = (
        !_.isEmpty(quoteShortTerm3) ? quoteShortTerm3 : []
      ).map((quote) => {
        let additional = quote?.addOnsData?.additional
          ? Object.keys(quote?.addOnsData?.additional)
          : [];

        let additionalList = quote?.addOnsData?.additional;
        let selectedAddons = addOnsAndOthers?.selectedAddons || [];
        let totalAdditional = 0;
        let totalPayableAmountAddonNoGst = 0;
        let totalPayableAmountWithAddon = 0;

        let totalPremiumA =
          quote?.finalOdPremium * 1 +
          ElectricalValue(quote) +
          NonElectricalValue(quote) +
          BiFuelValue(quote);

        //ncb calculation / discount part
        let totalPremiumc = quote?.finalTotalDiscount;
        let revisedNcb = quote?.deductionOfNcb;
        let otherDiscounts = quote?.icVehicleDiscount || 0;
        let addonDiscountPercentage = 0;
        let addedNcb = 0;

        //addon calculation

        selectedAddons.forEach((el) => {
          if (
            !_.isEmpty(additional) &&
            additional?.includes(el) &&
            typeof additionalList[el] === "number"
          ) {
            totalAdditional =
              totalAdditional +
              (quote?.company_alias === "royal_sundaram"
                ? el === "ncbProtection" ||
                  el === "engineProtector" ||
                  el === "tyreSecure"
                  ? Number(additionalList[el]) -
                      (Number(additionalList[el]) * addonDiscountPercentage) /
                        100 <
                      200 && Number(additionalList[el]) > 0
                    ? 200
                    : Number(additionalList[el]) -
                      (Number(additionalList[el]) * addonDiscountPercentage) /
                        100
                  : Number(additionalList[el]) -
                      (Number(additionalList[el]) * addonDiscountPercentage) /
                        100 <
                      100 && Number(additionalList[el]) > 0
                  ? 100
                  : Number(additionalList[el]) -
                    (Number(additionalList[el]) * addonDiscountPercentage) / 100
                : Number(additionalList[el]));
          }
        });

        let inbuilt = quote?.addOnsData?.inBuilt
          ? Object.keys(quote?.addOnsData?.inBuilt)
          : [];
        let allAddons = [
          "zeroDepreciation",
          "roadSideAssistance",
          "imt23",
          "keyReplace",
          "engineProtector",
          "ncbProtection",
          "consumables",
          "tyreSecure",
          "returnToInvoice",
          "lopb",
        ];
        let inbuiltList = quote?.addOnsData?.inBuilt;
        let totalInbuilt = 0;
        allAddons.forEach((el) => {
          if (
            !_.isEmpty(inbuilt) &&
            inbuilt?.includes(el) &&
            typeof inbuiltList[el] === "number"
          ) {
            totalInbuilt =
              totalInbuilt +
              (quote?.company_alias === "royal_sundaram"
                ? el === "ncbProtection" ||
                  el === "engineProtector" ||
                  el === "tyreSecure"
                  ? Number(inbuiltList[el]) -
                      (Number(inbuiltList[el]) * addonDiscountPercentage) /
                        100 <
                      200 && Number(inbuiltList[el]) > 0
                    ? 200
                    : Number(inbuiltList[el]) -
                      (Number(inbuiltList[el]) * addonDiscountPercentage) / 100
                  : Number(inbuiltList[el]) -
                      (Number(inbuiltList[el]) * addonDiscountPercentage) /
                        100 <
                      100 && Number(inbuiltList[el]) > 0
                  ? 100
                  : Number(inbuiltList[el]) -
                    (Number(inbuiltList[el]) * addonDiscountPercentage) / 100
                : Number(inbuiltList[el]));
          }
        });

        let others =
          (quote?.addOnsData?.other && Object.keys(quote?.addOnsData?.other)) ||
          [];

        let othersList = quote?.addOnsData?.other;

        let totalOther = 0;
        others.forEach((el) => {
          if (typeof othersList[el] === "number") {
            totalOther = totalOther + Number(othersList[el]);
          }
        });
        let totalAddon =
          Number(totalAdditional) + Number(totalInbuilt) + Number(totalOther);

        if (quote?.company_alias === "oriental" && TypeReturn(type) === "cv") {
          // For Oriental CV, you need to use following formula:NCB premium = (Total OD premium + Addons - discounts(anti theft)) * applicable NCB
          let extraOtherDiscounts = 0;
          let discountPercentageOriental = 0.7;

          // if (quote?.mmvDetail?.manfName === "MARUTI") {
          //   discountPercentageOriental = 0.5;

          //   let c = temp_data?.regDate;
          //   let d = moment().format("DD-MM-YYYY");
          //   let vehicleAge = differenceInYears(toDate(d), toDate(c));
          //   console.log(vehicleAge, "vehicleAge");
          //   if (vehicleAge >= 11) {
          //     discountPercentageOriental = 0.7;
          //   } else if (vehicleAge >= 6 && vehicleAge < 11) {
          //     discountPercentageOriental = 0.75;
          //   } else if (vehicleAge <= 5) {
          //     discountPercentageOriental = 0.8;
          //   }
          // } else {
          //   discountPercentageOriental = 0.5;
          // }
          //for ncb zd is included.
          extraOtherDiscounts = totalAddon * discountPercentageOriental;
          //for extradiscounts we don't need ZeroDep hence recalc total (addon * discount %) without zd ------
          //additional & selected
          let totalAdditional = 0;
          selectedAddons.forEach((el) => {
            if (
              additional?.includes(
                el === "zeroDepreciation" ? "nomatch" : el
              ) &&
              typeof additionalList[el] === "number"
            ) {
              totalAdditional = totalAdditional + Number(additionalList[el]);
            }
          });
          //Inbuilt
          let inbuilt = Object.keys(quote?.addOnsData?.inBuilt);
          let allAddons = [
            "roadSideAssistance",
            "imt23",
            "keyReplace",
            "engineProtector",
            "ncbProtection",
            "consumables",
            "tyreSecure",
            "returnToInvoice",
            "lopb",
          ];

          let inbuiltList = quote?.addOnsData?.inBuilt;
          let totalInbuilt = 0;
          allAddons.forEach((el) => {
            if (inbuilt?.includes(el) && typeof inbuiltList[el] === "number") {
              totalInbuilt = totalInbuilt + Number(inbuiltList[el]);
            }
          });
          //other
          let others =
            (quote?.addOnsData?.other &&
              Object.keys(quote?.addOnsData?.other)) ||
            [];
          let othersList = quote?.addOnsData?.other;
          let totalOther = 0;
          others.forEach((el) => {
            if (typeof othersList[el] === "number") {
              totalOther = totalOther + Number(othersList[el]);
            }
          });

          let extraOtherDiscounts2 =
            (Number(totalAdditional) +
              Number(totalInbuilt) +
              Number(totalOther)) *
            discountPercentageOriental;
          addedNcb =
            ((totalAddon - extraOtherDiscounts2) * Number(quote?.ncbDiscount)) /
            100;

          revisedNcb = Number(quote?.deductionOfNcb) + Number(addedNcb);
          otherDiscounts =
            (quote?.icVehicleDiscount || 0) + Number(extraOtherDiscounts2);

          totalPremiumc =
            Number(quote?.finalTotalDiscount) +
            Number(addedNcb) +
            Number(extraOtherDiscounts2);
        } else if (
          selectedAddons?.includes("imt23") &&
          (quote?.company_alias === "hdfc_ergo" ||
            quote?.company_alias === "godigit" ||
            quote?.company_alias === "shriram" ||
            quote?.company_alias === "reliance" ||
            quote?.company_alias === "sbi")
        ) {
          if (
            additional?.includes("imt23") &&
            typeof additionalList["imt23"] === "number"
          ) {
            addedNcb =
              (Number(additionalList["imt23"]) * Number(quote?.ncbDiscount)) /
              100;
          } else if (
            inbuilt?.includes("imt23") &&
            typeof inbuiltList["imt23"] === "number"
          ) {
            addedNcb = Number(
              (inbuiltList["imt23"] * Number(quote?.ncbDiscount)) / 100
            );
          }
          revisedNcb = Number(quote?.deductionOfNcb) + Number(addedNcb);
          totalPremiumc = Number(quote?.finalTotalDiscount) + Number(addedNcb);
        } else if (
          quote?.company_alias === "royal_sundaram" &&
          TypeReturn(type) === "car" &&
          addOnsAndOthers?.selectedAddons?.includes("zeroDepreciation")
        ) {
          const g1 = [
            "zeroDepreciation",
            "returnToInvoice",
            "ncbProtection",
            "engineProtector",
          ]; // 10 % on final addons
          const g2 = [
            "zeroDepreciation",
            "returnToInvoice",
            "ncbProtection",
            "lopb",
            "engineProtector",
          ]; //15% on finaladdons
          const g3 = [
            "zeroDepreciation",
            "returnToInvoice",
            "ncbProtection",
            "lopb",
            "tyreSecure",
            "keyReplace",
            "engineProtector",
          ]; // 20 % on final addons
          let addonsSelected = addOnsAndOthers?.selectedAddons;

          if (_.intersection(g3, addonsSelected)?.length >= 4) {
            addonDiscountPercentage = 20;
            revisedNcb = Number(quote?.deductionOfNcb);
          } else if (_.intersection(g2, addonsSelected)?.length === 3) {
            addonDiscountPercentage = 15;

            revisedNcb = Number(quote?.deductionOfNcb);
          } else if (_.intersection(g1, addonsSelected)?.length === 2) {
            addonDiscountPercentage = 10;

            revisedNcb = Number(quote?.deductionOfNcb);
          } else {
            addonDiscountPercentage = 0;
            revisedNcb = Number(quote?.deductionOfNcb);
            totalPremiumc = Number(quote?.finalTotalDiscount);
          }
        }
        // else if (
        //   quote?.company_alias === "hdfc_ergo" &&
        //   temp_data?.journeyCategory !== "GCV"
        // ) {
        //   revisedNcb = Number(
        //     (totalPremiumA * 1 * Number(quote?.ncbDiscount)) / 100
        //   );
        //   totalPremiumc =
        //     Number(quote?.finalTotalDiscount) +
        //     Number((totalPremiumA * 1 * Number(quote?.ncbDiscount)) / 100) -
        //     Number(quote?.deductionOfNcb);
        // }
        else if (
          quote?.company_alias === "oriental" &&
          TypeReturn(type) === "car"
        ) {
          // re-eval required addons with others
          //additional & selected
          let totalAdditional = 0;
          selectedAddons.forEach((el) => {
            if (
              additional?.includes(
                el === "zeroDepreciation" ? "nomatch" : el
              ) &&
              typeof additionalList[el] === "number"
            ) {
              totalAdditional = totalAdditional + Number(additionalList[el]);
            }
          });
          //Inbuilt
          let inbuilt = Object.keys(quote?.addOnsData?.inBuilt);
          let allAddons = [
            "roadSideAssistance",
            "imt23",
            "keyReplace",
            "engineProtector",
            "ncbProtection",
            "consumables",
            "tyreSecure",
            "returnToInvoice",
            "lopb",
          ];

          let inbuiltList = quote?.addOnsData?.inBuilt;
          let totalInbuilt = 0;
          allAddons.forEach((el) => {
            if (inbuilt?.includes(el) && typeof inbuiltList[el] === "number") {
              totalInbuilt = totalInbuilt + Number(inbuiltList[el]);
            }
          });
          //other
          let others =
            (quote?.addOnsData?.other &&
              Object.keys(quote?.addOnsData?.other)) ||
            [];
          let othersList = quote?.addOnsData?.other;
          let totalOther = 0;
          others.forEach((el) => {
            if (typeof othersList[el] === "number") {
              totalOther = totalOther + Number(othersList[el]);
            }
          });
          let NcbTotalAddon =
            Number(totalAdditional) + Number(totalInbuilt) + Number(totalOther);
          revisedNcb = Math.round(
            ((totalPremiumA * 1 +
              NcbTotalAddon * 1 -
              (Number(quote?.finalTotalDiscount) -
                Number(quote?.deductionOfNcb) -
                (Number(quote.tppdDiscount)
                  ? Number(quote.tppdDiscount)
                  : 0))) *
              Number(quote?.ncbDiscount)) /
              100
          );
          totalPremiumc =
            Number(quote?.finalTotalDiscount) -
            //deducting the ncb sent by backend
            Number(quote?.deductionOfNcb) +
            //calculating ncb and adding it to total discount
            Math.round(
              ((totalPremiumA * 1 +
                NcbTotalAddon * 1 -
                (Number(
                  quote?.finalTotalDiscount ? quote?.finalTotalDiscount : 0
                ) -
                  Number(quote?.deductionOfNcb ? quote?.deductionOfNcb : 0) -
                  Number(quote?.tppdDiscount ? quote?.tppdDiscount : 0))) *
                Number(quote?.ncbDiscount ? quote?.ncbDiscount : 0)) /
                100
            );
        } else {
          revisedNcb = Number(quote?.deductionOfNcb);
          totalPremiumc = Number(quote?.finalTotalDiscount);
        }

        //////cpa part
        let totalPremiumB = quote?.finalTpPremium;

        let selectedCpa = addOnsAndOthers?.selectedCpa;

        let cpa = 0;

        if (selectedCpa?.includes("Compulsory Personal Accident")) {
          if (!_.isEmpty(addOnsAndOthers?.isTenure)) {
            cpa = quote?.multiYearCpa ? quote?.multiYearCpa : 0;
          } else {
            cpa = quote?.compulsoryPaOwnDriver;
          }
        } else {
          cpa = 0;
        }

        totalPremiumB =
          Number(quote?.finalTpPremium) +
          Number(cpa) +
          (quote?.companyAlias === "sbi" &&
          selectedCpa?.includes("Compulsory Personal Accident") &&
          !_.isEmpty(addOnsAndOthers?.isTenure) &&
          quote?.coverUnnamedPassengerValue * 1
            ? quote?.coverUnnamedPassengerValue *
              (TypeReturn(type) === "bike" ? 4 : 2)
            : 0) +
          //adding additional paid driver cover in multi year cpa sbi.
          (quote?.companyAlias === "sbi" &&
          selectedCpa?.includes("Compulsory Personal Accident") &&
          !_.isEmpty(addOnsAndOthers?.isTenure) &&
          quote?.motorAdditionalPaidDriver * 1
            ? quote?.motorAdditionalPaidDriver *
              (TypeReturn(type) === "bike" ? 4 : 2)
            : 0);

        let applicableAddons = [];
        if (temp_data?.tab !== "tab2") {
          var addonsSelectedList = [];
          if (!_.isEmpty(selectedAddons) || !_.isEmpty(inbuilt)) {
            selectedAddons.forEach((el) => {
              if (
                additional?.includes(el) &&
                Number(additionalList[el]) !== 0
              ) {
                var newList = {
                  name: getAddonName(el),
                  premium: Number(additionalList[el]),
                };
                addonsSelectedList.push(newList);
              }
            });

            inbuilt.forEach((el) => {
              var newList = {
                name: getAddonName(el),
                premium: Number(inbuiltList[el]),
              };
              addonsSelectedList.push(newList);
            });

            applicableAddons = addonsSelectedList;
          } else {
            applicableAddons = [];
          }
        }

        //uv loading
        let uwLoading = 0;
        if (_.isEmpty(applicableAddons)) {
          if (
            Number(totalPremiumA) - Number(totalPremiumc) < 100 &&
            TypeReturn(type) === "bike" &&
            quote?.company_alias === "shriram" &&
            process.env?.REACT_APP_API_BASE_URL ===
              "https://apimotor.comparepolicy.com/api" &&
            (quote?.policyType === "Comprehensive" ||
              quote?.policyType === "Own Damage") &&
            false
          ) {
            uwLoading = 17;
          } else if (
            Number(totalPremiumA) - Number(totalPremiumc) < 100 &&
            TypeReturn(type) === "bike" &&
            quote?.company_alias === "shriram" &&
            process.env?.REACT_APP_API_BASE_URL !==
              "https://apimotor.comparepolicy.com/api" &&
            (quote?.policyType === "Comprehensive" ||
              quote?.policyType === "Own Damage") &&
            false
          ) {
            uwLoading = 50;
          } else {
            uwLoading = 0;
          }
        } else {
          uwLoading = 0;
        }

        let totalLoading = 0;

        if (true) {
          if (
            Number(quote?.totalLoadingAmount) > 0 ||
            Number(quote?.underwritingLoadingAmount)
          ) {
            totalLoading =
              Number(quote?.totalLoadingAmount) ||
              Number(quote?.underwritingLoadingAmount);
          } else {
            totalLoading = 0;
          }
        } else {
          totalLoading = 0;
        }

        let totalPremium =
          Number(totalAddon) +
          Number(totalPremiumA) +
          Number(totalPremiumB) -
          Number(totalPremiumc) +
          Number(uwLoading) +
          Number(totalLoading);

        let totalPremiumGst = parseInt((totalPremium * 18) / 100);

        if (temp_data?.journeyCategory === "GCV") {
          if (quote.company_alias === "oriental") {
            //In Oriental , TPPD discount is not added to third party liability for GST calc
            totalPremiumGst =
              parseInt(((totalPremium - quote?.tppdPremiumAmount) * 18) / 100) +
              (quote?.tppdPremiumAmount * 12) / 100;
          } else if (quote.company_alias === "sbi") {
            //In sbi , Basic tp - 12%, rest 18%
            totalPremiumGst =
              parseInt(((totalPremium - quote?.tppdPremiumAmount) * 18) / 100) +
              (quote?.tppdPremiumAmount * 12) / 100;
          } else if (quote.company_alias === "godigit") {
            // GST calc for other IC's in GCV
            totalPremiumGst =
              parseInt(
                Math.round(
                  ((totalPremium -
                    quote?.tppdPremiumAmount +
                    (Number(quote?.tppdDiscount)
                      ? Number(quote?.tppdDiscount)
                      : 0)) *
                    18) /
                    100 +
                    ((quote?.tppdPremiumAmount -
                      (Number(quote?.tppdDiscount)
                        ? Number(quote?.tppdDiscount)
                        : 0)) *
                      12) /
                      100
                )
              ) - (quote?.cngLpgTp * 1 ? quote?.cngLpgTp * 1 : 0);
          } else {
            // GST calc for other IC's in GCV
            totalPremiumGst =
              parseInt(
                ((totalPremium -
                  quote?.tppdPremiumAmount +
                  Number(quote?.tppdDiscount)) *
                  18) /
                  100
              ) +
              ((quote?.tppdPremiumAmount - Number(quote?.tppdDiscount)) * 12) /
                100;
          }
        }

        let FinalPremium = totalPremium + totalPremiumGst;

        console.log(FinalPremium, "quote123", quote?.company_alias);

        return {
          ...quote,
          totalAddon1: totalAddon,
          finalPremium1: FinalPremium,
          totalPremium1: totalPremium,
          totalPremiumB1: totalPremiumB,
          totalPremiumc1: totalPremiumc,
          addonDiscountPercentage1: addonDiscountPercentage,
          applicableAddons1: applicableAddons,
          gst1: totalPremiumGst,
          revisedNcb1: revisedNcb,
          totalPayableAmountWithAddon: FinalPremium,
        };
      });

      //Short Term 6 Sorting
      let sortedShortTerm6 = (
        !_.isEmpty(quoteShortTerm6) ? quoteShortTerm6 : []
      ).map((quote) => {
        let additional = quote?.addOnsData?.additional
          ? Object.keys(quote?.addOnsData?.additional)
          : [];

        let additionalList = quote?.addOnsData?.additional;
        let selectedAddons = addOnsAndOthers?.selectedAddons || [];
        let totalAdditional = 0;
        let totalPayableAmountAddonNoGst = 0;
        let totalPayableAmountWithAddon = 0;

        //////////////////////////

        let totalPremiumA =
          quote?.finalOdPremium * 1 +
          ElectricalValue(quote) +
          NonElectricalValue(quote) +
          BiFuelValue(quote);

        //ncb calculation / discount part
        let totalPremiumc = quote?.finalTotalDiscount;
        let revisedNcb = quote?.deductionOfNcb;
        let otherDiscounts = quote?.icVehicleDiscount || 0;
        let addonDiscountPercentage = 0;
        let addedNcb = 0;

        //addon calculation

        selectedAddons.forEach((el) => {
          if (
            !_.isEmpty(additional) &&
            additional?.includes(el) &&
            typeof additionalList[el] === "number"
          ) {
            totalAdditional =
              totalAdditional +
              (quote?.company_alias === "royal_sundaram"
                ? el === "ncbProtection" ||
                  el === "engineProtector" ||
                  el === "tyreSecure"
                  ? Number(additionalList[el]) -
                      (Number(additionalList[el]) * addonDiscountPercentage) /
                        100 <
                      200 && Number(additionalList[el]) > 0
                    ? 200
                    : Number(additionalList[el]) -
                      (Number(additionalList[el]) * addonDiscountPercentage) /
                        100
                  : Number(additionalList[el]) -
                      (Number(additionalList[el]) * addonDiscountPercentage) /
                        100 <
                      100 && Number(additionalList[el]) > 0
                  ? 100
                  : Number(additionalList[el]) -
                    (Number(additionalList[el]) * addonDiscountPercentage) / 100
                : Number(additionalList[el]));
          }
        });

        let inbuilt = quote?.addOnsData?.inBuilt
          ? Object.keys(quote?.addOnsData?.inBuilt)
          : [];
        let allAddons = [
          "zeroDepreciation",
          "roadSideAssistance",
          "imt23",
          "keyReplace",
          "engineProtector",
          "ncbProtection",
          "consumables",
          "tyreSecure",
          "returnToInvoice",
          "lopb",
        ];
        let inbuiltList = quote?.addOnsData?.inBuilt;
        let totalInbuilt = 0;
        allAddons.forEach((el) => {
          if (
            !_.isEmpty(inbuilt) &&
            inbuilt?.includes(el) &&
            typeof inbuiltList[el] === "number"
          ) {
            totalInbuilt =
              totalInbuilt +
              (quote?.company_alias === "royal_sundaram"
                ? el === "ncbProtection" ||
                  el === "engineProtector" ||
                  el === "tyreSecure"
                  ? Number(inbuiltList[el]) -
                      (Number(inbuiltList[el]) * addonDiscountPercentage) /
                        100 <
                      200 && Number(inbuiltList[el]) > 0
                    ? 200
                    : Number(inbuiltList[el]) -
                      (Number(inbuiltList[el]) * addonDiscountPercentage) / 100
                  : Number(inbuiltList[el]) -
                      (Number(inbuiltList[el]) * addonDiscountPercentage) /
                        100 <
                      100 && Number(inbuiltList[el]) > 0
                  ? 100
                  : Number(inbuiltList[el]) -
                    (Number(inbuiltList[el]) * addonDiscountPercentage) / 100
                : Number(inbuiltList[el]));
          }
        });

        let others =
          (quote?.addOnsData?.other && Object.keys(quote?.addOnsData?.other)) ||
          [];

        let othersList = quote?.addOnsData?.other;

        let totalOther = 0;
        others.forEach((el) => {
          if (typeof othersList[el] === "number") {
            totalOther = totalOther + Number(othersList[el]);
          }
        });
        let totalAddon =
          Number(totalAdditional) + Number(totalInbuilt) + Number(totalOther);

        if (quote?.company_alias === "oriental" && TypeReturn(type) === "cv") {
          // For Oriental CV, you need to use following formula:NCB premium = (Total OD premium + Addons - discounts(anti theft)) * applicable NCB
          let extraOtherDiscounts = 0;
          let discountPercentageOriental = 0.7;

          // if (quote?.mmvDetail?.manfName === "MARUTI") {
          //   discountPercentageOriental = 0.5;

          //   let c = temp_data?.regDate;
          //   let d = moment().format("DD-MM-YYYY");
          //   let vehicleAge = differenceInYears(toDate(d), toDate(c));
          //   console.log(vehicleAge, "vehicleAge");
          //   if (vehicleAge >= 11) {
          //     discountPercentageOriental = 0.7;
          //   } else if (vehicleAge >= 6 && vehicleAge < 11) {
          //     discountPercentageOriental = 0.75;
          //   } else if (vehicleAge <= 5) {
          //     discountPercentageOriental = 0.8;
          //   }
          // } else {
          //   discountPercentageOriental = 0.5;
          // }
          //for ncb zd is included.
          extraOtherDiscounts = totalAddon * discountPercentageOriental;
          //for extradiscounts we don't need ZeroDep hence recalc total (addon * discount %) without zd ------
          //additional & selected
          let totalAdditional = 0;
          selectedAddons.forEach((el) => {
            if (
              additional?.includes(
                el === "zeroDepreciation" ? "nomatch" : el
              ) &&
              typeof additionalList[el] === "number"
            ) {
              totalAdditional = totalAdditional + Number(additionalList[el]);
            }
          });
          //Inbuilt
          let inbuilt = Object.keys(quote?.addOnsData?.inBuilt);
          let allAddons = [
            "roadSideAssistance",
            "imt23",
            "keyReplace",
            "engineProtector",
            "ncbProtection",
            "consumables",
            "tyreSecure",
            "returnToInvoice",
            "lopb",
          ];

          let inbuiltList = quote?.addOnsData?.inBuilt;
          let totalInbuilt = 0;
          allAddons.forEach((el) => {
            if (inbuilt?.includes(el) && typeof inbuiltList[el] === "number") {
              totalInbuilt = totalInbuilt + Number(inbuiltList[el]);
            }
          });
          //other
          let others =
            (quote?.addOnsData?.other &&
              Object.keys(quote?.addOnsData?.other)) ||
            [];
          let othersList = quote?.addOnsData?.other;
          let totalOther = 0;
          others.forEach((el) => {
            if (typeof othersList[el] === "number") {
              totalOther = totalOther + Number(othersList[el]);
            }
          });

          let extraOtherDiscounts2 =
            (Number(totalAdditional) +
              Number(totalInbuilt) +
              Number(totalOther)) *
            discountPercentageOriental;
          addedNcb =
            ((totalAddon - extraOtherDiscounts2) * Number(quote?.ncbDiscount)) /
            100;

          revisedNcb = Number(quote?.deductionOfNcb) + Number(addedNcb);
          otherDiscounts =
            (quote?.icVehicleDiscount || 0) + Number(extraOtherDiscounts2);

          totalPremiumc =
            Number(quote?.finalTotalDiscount) +
            Number(addedNcb) +
            Number(extraOtherDiscounts2);
        } else if (
          selectedAddons?.includes("imt23") &&
          (quote?.company_alias === "hdfc_ergo" ||
            quote?.company_alias === "godigit" ||
            quote?.company_alias === "shriram" ||
            quote?.company_alias === "reliance" ||
            quote?.company_alias === "sbi")
        ) {
          if (
            additional?.includes("imt23") &&
            typeof additionalList["imt23"] === "number"
          ) {
            addedNcb =
              (Number(additionalList["imt23"]) * Number(quote?.ncbDiscount)) /
              100;
          } else if (
            inbuilt?.includes("imt23") &&
            typeof inbuiltList["imt23"] === "number"
          ) {
            addedNcb = Number(
              (inbuiltList["imt23"] * Number(quote?.ncbDiscount)) / 100
            );
          }
          revisedNcb = Number(quote?.deductionOfNcb) + Number(addedNcb);
          totalPremiumc = Number(quote?.finalTotalDiscount) + Number(addedNcb);
        } else if (
          quote?.company_alias === "royal_sundaram" &&
          TypeReturn(type) === "car" &&
          addOnsAndOthers?.selectedAddons?.includes("zeroDepreciation")
        ) {
          const g1 = [
            "zeroDepreciation",
            "returnToInvoice",
            "ncbProtection",
            "engineProtector",
          ]; // 10 % on final addons
          const g2 = [
            "zeroDepreciation",
            "returnToInvoice",
            "ncbProtection",
            "lopb",
            "engineProtector",
          ]; //15% on finaladdons
          const g3 = [
            "zeroDepreciation",
            "returnToInvoice",
            "ncbProtection",
            "lopb",
            "tyreSecure",
            "keyReplace",
            "engineProtector",
          ]; // 20 % on final addons
          let addonsSelected = addOnsAndOthers?.selectedAddons;

          if (_.intersection(g3, addonsSelected)?.length >= 4) {
            addonDiscountPercentage = 20;
            revisedNcb = Number(quote?.deductionOfNcb);
          } else if (_.intersection(g2, addonsSelected)?.length === 3) {
            addonDiscountPercentage = 15;

            revisedNcb = Number(quote?.deductionOfNcb);
          } else if (_.intersection(g1, addonsSelected)?.length === 2) {
            addonDiscountPercentage = 10;

            revisedNcb = Number(quote?.deductionOfNcb);
          } else {
            addonDiscountPercentage = 0;
            revisedNcb = Number(quote?.deductionOfNcb);
            totalPremiumc = Number(quote?.finalTotalDiscount);
          }
        }
        // else if (
        //   quote?.company_alias === "hdfc_ergo" &&
        //   temp_data?.journeyCategory !== "GCV"
        // ) {
        //   revisedNcb = Number(
        //     (totalPremiumA * 1 * Number(quote?.ncbDiscount)) / 100
        //   );
        //   totalPremiumc =
        //     Number(quote?.finalTotalDiscount) +
        //     Number((totalPremiumA * 1 * Number(quote?.ncbDiscount)) / 100) -
        //     Number(quote?.deductionOfNcb);
        // }
        else if (
          quote?.company_alias === "oriental" &&
          TypeReturn(type) === "car"
        ) {
          // re-eval required addons with others
          //additional & selected
          let totalAdditional = 0;
          selectedAddons.forEach((el) => {
            if (
              additional?.includes(
                el === "zeroDepreciation" ? "nomatch" : el
              ) &&
              typeof additionalList[el] === "number"
            ) {
              totalAdditional = totalAdditional + Number(additionalList[el]);
            }
          });
          //Inbuilt
          let inbuilt = Object.keys(quote?.addOnsData?.inBuilt);
          let allAddons = [
            "roadSideAssistance",
            "imt23",
            "keyReplace",
            "engineProtector",
            "ncbProtection",
            "consumables",
            "tyreSecure",
            "returnToInvoice",
            "lopb",
          ];

          let inbuiltList = quote?.addOnsData?.inBuilt;
          let totalInbuilt = 0;
          allAddons.forEach((el) => {
            if (inbuilt?.includes(el) && typeof inbuiltList[el] === "number") {
              totalInbuilt = totalInbuilt + Number(inbuiltList[el]);
            }
          });
          //other
          let others =
            (quote?.addOnsData?.other &&
              Object.keys(quote?.addOnsData?.other)) ||
            [];
          let othersList = quote?.addOnsData?.other;
          let totalOther = 0;
          others.forEach((el) => {
            if (typeof othersList[el] === "number") {
              totalOther = totalOther + Number(othersList[el]);
            }
          });
          let NcbTotalAddon =
            Number(totalAdditional) + Number(totalInbuilt) + Number(totalOther);
          revisedNcb = Math.round(
            ((totalPremiumA * 1 +
              NcbTotalAddon * 1 -
              (Number(quote?.finalTotalDiscount) -
                Number(quote?.deductionOfNcb) -
                (Number(quote.tppdDiscount)
                  ? Number(quote.tppdDiscount)
                  : 0))) *
              Number(quote?.ncbDiscount)) /
              100
          );
          totalPremiumc =
            Number(quote?.finalTotalDiscount) -
            //deducting the ncb sent by backend
            Number(quote?.deductionOfNcb) +
            //calculating ncb and adding it to total discount
            Math.round(
              ((totalPremiumA * 1 +
                NcbTotalAddon * 1 -
                (Number(
                  quote?.finalTotalDiscount ? quote?.finalTotalDiscount : 0
                ) -
                  Number(quote?.deductionOfNcb ? quote?.deductionOfNcb : 0) -
                  Number(quote?.tppdDiscount ? quote?.tppdDiscount : 0))) *
                Number(quote?.ncbDiscount ? quote?.ncbDiscount : 0)) /
                100
            );
        } else {
          revisedNcb = Number(quote?.deductionOfNcb);
          totalPremiumc = Number(quote?.finalTotalDiscount);
        }

        //////cpa part
        let totalPremiumB = quote?.finalTpPremium;

        let selectedCpa = addOnsAndOthers?.selectedCpa;

        let cpa = 0;

        if (selectedCpa?.includes("Compulsory Personal Accident")) {
          if (!_.isEmpty(addOnsAndOthers?.isTenure)) {
            cpa = quote?.multiYearCpa ? quote?.multiYearCpa : 0;
          } else {
            cpa = quote?.compulsoryPaOwnDriver;
          }
        } else {
          cpa = 0;
        }

        totalPremiumB =
          Number(quote?.finalTpPremium) +
          Number(cpa) +
          (quote?.companyAlias === "sbi" &&
          selectedCpa?.includes("Compulsory Personal Accident") &&
          !_.isEmpty(addOnsAndOthers?.isTenure) &&
          quote?.coverUnnamedPassengerValue * 1
            ? quote?.coverUnnamedPassengerValue *
              (TypeReturn(type) === "bike" ? 4 : 2)
            : 0) +
          //adding additional paid driver cover in multi year cpa sbi.
          (quote?.companyAlias === "sbi" &&
          selectedCpa?.includes("Compulsory Personal Accident") &&
          !_.isEmpty(addOnsAndOthers?.isTenure) &&
          quote?.motorAdditionalPaidDriver * 1
            ? quote?.motorAdditionalPaidDriver *
              (TypeReturn(type) === "bike" ? 4 : 2)
            : 0);

        let applicableAddons = [];
        if (temp_data?.tab !== "tab2") {
          var addonsSelectedList = [];
          if (!_.isEmpty(selectedAddons) || !_.isEmpty(inbuilt)) {
            selectedAddons.forEach((el) => {
              if (
                additional?.includes(el) &&
                Number(additionalList[el]) !== 0
              ) {
                var newList = {
                  name: getAddonName(el),
                  premium: Number(additionalList[el]),
                };
                addonsSelectedList.push(newList);
              }
            });

            inbuilt.forEach((el) => {
              var newList = {
                name: getAddonName(el),
                premium: Number(inbuiltList[el]),
              };
              addonsSelectedList.push(newList);
            });

            applicableAddons = addonsSelectedList;
          } else {
            applicableAddons = [];
          }
        }

        //uv loading
        let uwLoading = 0;
        if (_.isEmpty(applicableAddons)) {
          if (
            Number(totalPremiumA) - Number(totalPremiumc) < 100 &&
            TypeReturn(type) === "bike" &&
            quote?.company_alias === "shriram" &&
            process.env?.REACT_APP_API_BASE_URL ===
              "https://apimotor.comparepolicy.com/api" &&
            (quote?.policyType === "Comprehensive" ||
              quote?.policyType === "Own Damage") &&
            false
          ) {
            uwLoading = 17;
          } else if (
            Number(totalPremiumA) - Number(totalPremiumc) < 100 &&
            TypeReturn(type) === "bike" &&
            quote?.company_alias === "shriram" &&
            process.env?.REACT_APP_API_BASE_URL !==
              "https://apimotor.comparepolicy.com/api" &&
            (quote?.policyType === "Comprehensive" ||
              quote?.policyType === "Own Damage") &&
            false
          ) {
            uwLoading = 50;
          } else {
            uwLoading = 0;
          }
        } else {
          uwLoading = 0;
        }

        let totalLoading = 0;

        if (true) {
          if (
            Number(quote?.totalLoadingAmount) > 0 ||
            Number(quote?.underwritingLoadingAmount)
          ) {
            totalLoading =
              Number(quote?.totalLoadingAmount) ||
              Number(quote?.underwritingLoadingAmount);
          } else {
            totalLoading = 0;
          }
        } else {
          totalLoading = 0;
        }

        let totalPremium =
          Number(totalAddon) +
          Number(totalPremiumA) +
          Number(totalPremiumB) -
          Number(totalPremiumc) +
          Number(uwLoading) +
          Number(totalLoading);

        let totalPremiumGst = parseInt((totalPremium * 18) / 100);

        if (temp_data?.journeyCategory === "GCV") {
          if (quote.company_alias === "oriental") {
            //In Oriental , TPPD discount is not added to third party liability for GST calc
            totalPremiumGst =
              parseInt(((totalPremium - quote?.tppdPremiumAmount) * 18) / 100) +
              (quote?.tppdPremiumAmount * 12) / 100;
          } else if (quote.company_alias === "sbi") {
            //In sbi , Basic tp - 12%, rest 18%
            totalPremiumGst =
              parseInt(((totalPremium - quote?.tppdPremiumAmount) * 18) / 100) +
              (quote?.tppdPremiumAmount * 12) / 100;
          } else if (quote.company_alias === "godigit") {
            // GST calc for other IC's in GCV
            totalPremiumGst =
              parseInt(
                Math.round(
                  ((totalPremium -
                    quote?.tppdPremiumAmount +
                    (Number(quote?.tppdDiscount)
                      ? Number(quote?.tppdDiscount)
                      : 0)) *
                    18) /
                    100 +
                    ((quote?.tppdPremiumAmount -
                      (Number(quote?.tppdDiscount)
                        ? Number(quote?.tppdDiscount)
                        : 0)) *
                      12) /
                      100
                )
              ) - (quote?.cngLpgTp * 1 ? quote?.cngLpgTp * 1 : 0);
          } else {
            // GST calc for other IC's in GCV
            totalPremiumGst =
              parseInt(
                ((totalPremium -
                  quote?.tppdPremiumAmount +
                  Number(quote?.tppdDiscount)) *
                  18) /
                  100
              ) +
              ((quote?.tppdPremiumAmount - Number(quote?.tppdDiscount)) * 12) /
                100;
          }
        }

        let FinalPremium = totalPremium + totalPremiumGst;

        console.log(FinalPremium, "quote123", quote?.company_alias);

        return {
          ...quote,
          totalAddon1: totalAddon,
          finalPremium1: FinalPremium,
          totalPremium1: totalPremium,
          totalPremiumB1: totalPremiumB,
          totalPremiumc1: totalPremiumc,
          addonDiscountPercentage1: addonDiscountPercentage,
          applicableAddons1: applicableAddons,
          gst1: totalPremiumGst,
          revisedNcb1: revisedNcb,
          totalPayableAmountWithAddon: FinalPremium,
        };
      });

      //Sorting Logic
      if (Number(sortBy) === 3) {
        if (temp_data?.corporateVehiclesQuoteRequest?.isRenewal === "Y") {
          if (!_.isEmpty(restructTp)) {
            let tpQuotes = _.orderBy(
              restructTp?.filter((el) => el?.isRenewal !== "Y"),
              ["finalPremWithCpa"],
              ["desc"]
            );
            let tpRenewalQuote = quotetThirdParty?.filter(
              (el) => el?.isRenewal === "Y"
            );
            let sortedTp = [...tpRenewalQuote, ...tpQuotes];
            setQuoteTpGrouped1(sortedTp);
          }
          if (!_.isEmpty(sortedShortTerm3)) {
            let sh3Quotes = _.orderBy(
              sortedShortTerm3?.filter((el) => el?.isRenewal !== "Y"),
              ["totalPayableAmountWithAddon"],
              ["desc"]
            );
            let sh3RenewalQuote = sortedShortTerm3?.filter(
              (el) => el?.isRenewal === "Y"
            );
            let sortedsh3 = [...sh3RenewalQuote, ...sh3Quotes];
            setQuoteShortTerm3(sortedsh3);
          }
          if (!_.isEmpty(sortedShortTerm6)) {
            let sh6Quotes = _.orderBy(
              sortedShortTerm6?.filter((el) => el?.isRenewal !== "Y"),
              ["totalPayableAmountWithAddon"],
              ["desc"]
            );
            let sh6RenewalQuote = sortedShortTerm6?.filter(
              (el) => el?.isRenewal === "Y"
            );
            let sortedsh6 = [...sh6RenewalQuote, ...sh6Quotes];
            setQuoteShortTerm6(sortedsh6);
          }
        } else {
          setQuoteTpGrouped1(
            _.orderBy(restructTp, ["finalPremWithCpa"], ["desc"])
          );
          setQuoteShortTerm3(
            _.orderBy(
              sortedShortTerm3,
              ["totalPayableAmountWithAddon"],
              ["desc"]
            )
          );
          setQuoteShortTerm6(
            _.orderBy(
              sortedShortTerm6,
              ["totalPayableAmountWithAddon"],
              ["desc"]
            )
          );
        }
      } else if (Number(sortBy) === 4) {
        if (temp_data?.corporateVehiclesQuoteRequest?.isRenewal === "Y") {
          if (!_.isEmpty(quoteShortTerm3)) {
            let sh3Quotes = _.orderBy(
              quoteShortTerm3?.filter((el) => el?.isRenewal !== "Y"),
              ["idv"],
              ["asc"]
            );
            let sh3RenewalQuote = quoteShortTerm3?.filter(
              (el) => el?.isRenewal === "Y"
            );
            let sortedsh3 = [...sh3RenewalQuote, ...sh3Quotes];
            setQuoteShortTerm3(sortedsh3);
          }
          if (!_.isEmpty(quoteShortTerm6)) {
            let sh6Quotes = _.orderBy(
              quoteShortTerm6?.filter((el) => el?.isRenewal !== "Y"),
              ["idv"],
              ["asc"]
            );
            let sh6RenewalQuote = quoteShortTerm6?.filter(
              (el) => el?.isRenewal === "Y"
            );
            let sortedsh6 = [...sh6RenewalQuote, ...sh6Quotes];
            setQuoteShortTerm6(sortedsh6);
          }
        } else {
          setQuoteShortTerm3(_.orderBy(quoteShortTerm3, ["idv"], ["asc"]));
          setQuoteShortTerm6(_.orderBy(quoteShortTerm6, ["idv"], ["asc"]));
        }
      } else if (Number(sortBy) === 5) {
        if (temp_data?.corporateVehiclesQuoteRequest?.isRenewal === "Y") {
          if (!_.isEmpty(quoteShortTerm3)) {
            let sh3Quotes = _.orderBy(
              quoteShortTerm3?.filter((el) => el?.isRenewal !== "Y"),
              ["idv"],
              ["desc"]
            );
            let sh3RenewalQuote = quoteShortTerm3?.filter(
              (el) => el?.isRenewal === "Y"
            );
            let sortedsh3 = [...sh3RenewalQuote, ...sh3Quotes];
            setQuoteShortTerm3(sortedsh3);
          }
          if (!_.isEmpty(quoteShortTerm6)) {
            let sh6Quotes = _.orderBy(
              quoteShortTerm6?.filter((el) => el?.isRenewal !== "Y"),
              ["idv"],
              ["desc"]
            );
            let sh6RenewalQuote = quoteShortTerm6?.filter(
              (el) => el?.isRenewal === "Y"
            );
            let sortedsh6 = [...sh6RenewalQuote, ...sh6Quotes];
            setQuoteShortTerm6(sortedsh6);
          }
        } else {
          setQuoteShortTerm3(_.orderBy(quoteShortTerm3, ["idv"], ["desc"]));
          setQuoteShortTerm6(_.orderBy(quoteShortTerm6, ["idv"], ["desc"]));
        }
      } else {
        if (temp_data?.corporateVehiclesQuoteRequest?.isRenewal === "Y") {
          if (!_.isEmpty(restructTp)) {
            let tpQuotes = _.orderBy(
              restructTp?.filter((el) => el?.isRenewal !== "Y"),
              ["finalPremWithCpa"],
              ["asc"]
            );
            let tpRenewalQuote = quotetThirdParty?.filter(
              (el) => el?.isRenewal === "Y"
            );
            let sortedTp = [...tpRenewalQuote, ...tpQuotes];
            setQuoteTpGrouped1(sortedTp);
          }
          if (!_.isEmpty(quoteShortTerm3)) {
            let sh3Quotes = _.orderBy(
              quoteShortTerm3?.filter((el) => el?.isRenewal !== "Y"),
              ["totalPayableAmountWithAddon"],
              ["asc"]
            );
            let sh3RenewalQuote = quoteShortTerm3?.filter(
              (el) => el?.isRenewal === "Y"
            );
            let sortedsh3 = [...sh3RenewalQuote, ...sh3Quotes];
            setQuoteShortTerm3(sortedsh3);
          }
          if (!_.isEmpty(quoteShortTerm6)) {
            let sh6Quotes = _.orderBy(
              quoteShortTerm6?.filter((el) => el?.isRenewal !== "Y"),
              ["totalPayableAmountWithAddon"],
              ["asc"]
            );
            let sh6RenewalQuote = quoteShortTerm6?.filter(
              (el) => el?.isRenewal === "Y"
            );
            let sortedsh6 = [...sh6RenewalQuote, ...sh6Quotes];
            setQuoteShortTerm6(sortedsh6);
          }
        } else {
          setQuoteTpGrouped1(
            _.orderBy(restructTp, ["finalPremWithCpa"], ["asc"])
          );
          setQuoteShortTerm3(
            _.orderBy(
              sortedShortTerm3,
              ["totalPayableAmountWithAddon"],
              ["asc"]
            )
          );
          setQuoteShortTerm6(
            _.orderBy(
              sortedShortTerm6,
              ["totalPayableAmountWithAddon"],
              ["asc"]
            )
          );
        }
      }
    }
  }, [
    quotetThirdParty,
    quotesLoadingComplted,
    quotesLoaded,
    addOnsAndOthers,
    sortBy,
  ]);

  useEffect(() => {
    dispatch(
      setTempData({
        quoteComprehesiveGrouped: quoteComprehesiveGrouped1,
        quoteThirdParty: quotetThirdParty,
      })
    );
  }, [quoteComprehesiveGrouped1, quotetThirdParty]);

  const BrokerList =
    process.env.REACT_APP_BROKER === "ABIBL" ||
    process.env.REACT_APP_BROKER === "ACE" ||
    process.env.REACT_APP_BROKER === "RB" ||
    process.env.REACT_APP_BROKER === "OLA" ||
    process.env.REACT_APP_BROKER === "GRAM";

  const [gstToggle, setGstToggle] = useState(BrokerList ? true : false);

  const [daysToExpiry, setDaysToExpiry] = useState(false);

  //------------single reload------------

  useEffect(() => {
    if (prevPopup2 === false) {
      dispatch(setBuyNowSingleQuoteUpdate(false));
    }
  }, [prevPopup2]);

  //---------------reload quotes on popup close-------------

  useEffect(() => {
    if (!prevPopup2) {
      //	dispatch(clear());
      dispatch(CancelAll(false));
      dispatch(
        set_temp_data({
          reloaded: temp_data?.reloaded ? temp_data?.reloaded + 1 : 1,
        })
      );
    }
  }, [prevPopup2]);

  useEffect(() => {
    if (!prevPopup3) {
      //	dispatch(clear());
      dispatch(CancelAll(false));
      dispatch(
        set_temp_data({
          reloaded: temp_data?.reloaded ? temp_data?.reloaded + 1 : 1,
        })
      );
    }
  }, [prevPopup3]);

  //-------------know more logic--------------------

  const [knowMore, setKnowMore] = useState(false);

  const [knowMoreObject, setKnowMoreObject] = useState({});

  const [selectedKnowMore, setSelectedKnowMore] = useState(false);

  const [knowMoreQuote, setKnowMoreQuote] = useState(false);

  useEffect(() => {
    setKnowMore(false);
  }, [tab]);

  useEffect(() => {
    if (knowMoreObject?.quote) {
      if (
        (TypeReturn(knowMoreObject?.type) === "car" ||
          TypeReturn(knowMoreObject?.type) === "bike" ||
          TypeReturn(knowMoreObject?.type) === "cv") &&
        knowMoreObject?.quote?.policyType === "Comprehensive"
      ) {
        let filteredKnowMoreQuote = quoteComprehesiveGrouped1.filter(
          (id) => id.company_alias === knowMoreObject?.quote?.company_alias
        );

        setKnowMoreQuote(filteredKnowMoreQuote[0]);
      } else {
        setKnowMoreQuote(knowMoreObject?.quote);
      }
    }
  }, [
    tab,
    knowMoreObject,
    addOnsAndOthers?.selectedAddons,
    quoteComprehesiveGrouped1,
  ]);

  useEffect(() => {
    if (!knowMore) {
      setKnowMoreObject(false);
      setKnowMoreQuote(false);
    }
  }, [knowMore]);

  //------------------finding max of inbuilt--------------------

  const [maxAddonsMotor, setMaxAddonsMotor] = useState(0);

  useEffect(() => {
    let max = 0;
    (shortTerm3
      ? !_.isEmpty(quoteShortTerm3)
        ? quoteShortTerm3
        : []
      : shortTerm6
      ? !_.isEmpty(quoteShortTerm6)
        ? quoteShortTerm6
        : []
      : !_.isEmpty(quoteComprehesiveGrouped1)
      ? quoteComprehesiveGrouped1
      : quoteComprehesiveGrouped
    ).forEach((i) => {
      let inbuilt = i?.addOnsData?.inBuilt
        ? Object.keys(i?.addOnsData?.inBuilt)
        : [];
      let a1 = addOnsAndOthers?.selectedAddons
        ? addOnsAndOthers?.selectedAddons
        : [];

      let totalLength = _.union(inbuilt, a1);

      let totalLengthFiltered = _.filter(
        totalLength,
        (v) => v !== "nonzeroDepreciation"
      );

      if (max < totalLengthFiltered?.length) {
        max = totalLengthFiltered?.length;
      } else {
      }
    });
    setMaxAddonsMotor(max);
  }, [
    quoteComprehesiveGrouped1,
    addOnsAndOthers?.selectedAddons,
    quoteComprehesiveGrouped,
    quoteShortTerm3,
    quoteShortTerm6,
    shortTerm3,
    shortTerm6,
  ]);

  //---------- toast ola conditions---------------

  const [toasterShown, setToasterShown] = useState(true);
  const [callToaster, setCallToaster] = useState(false);
  const [shareQuotesFromToaster, setShareQuotesFromToaster] = useState(false);
  let ut =
    //home state
    temp_data?.agentDetails &&
    !_.isEmpty(temp_data?.agentDetails) &&
    !_.isEmpty(temp_data?.agentDetails?.find((o) => o?.sellerType === "E"));

  useEffect(() => {
    if (
      (temp_data?.expiry || temp_data?.newCar) &&
      toasterShown &&
      process.env.REACT_APP_BROKER === "OLA" &&
      token &&
      ut
    ) {
      setTimeout(() => {
        setCallToaster(true);
      }, 3000);
    }
  }, [temp_data?.expiry, toasterShown, temp_data?.newCar]);

  //------------------- addon drawer for mobile ui------------------------

  const [addonDrawer, setAddonDrawer] = useState(false);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event?.type === "keydown" &&
      (event?.key === "Tab" || event?.key === "Shift")
    ) {
      return;
    }

    setAddonDrawer({ ...addonDrawer, [anchor]: open });
  };

  // ----------------Showing Errors---------------------

  const errorCondition =
    shortTerm3 || shortTerm6 ? "shortTerm" : "comprehensive";

  const [ErrorComprehensive, setErrorComprehensive] = useState(
    errorIcBased
      .filter((id) => id.type === errorCondition)
      .filter((id) => id.zeroDepError !== true)
  );
  //-----------filtering zero dep error when zero dep is selected-------------

  useEffect(() => {
    if (
      addOnsAndOthers?.selectedAddons?.includes("zeroDepreciation")
      //  &&
      // errorCondition === "comprehensive"
    ) {
      setErrorComprehensive(
        errorIcBased
          .filter((id) => id.type === errorCondition)
          .filter((id) => id.zeroDepError === true)
      );
    } else {
      setErrorComprehensive(
        errorIcBased
          .filter((id) => id.type === errorCondition)
          .filter((id) => id.zeroDepError !== true)
      );
    }
  }, [errorIcBased, errorCondition, addOnsAndOthers?.selectedAddons]);

  let ErrorTp = errorIcBased.filter((id) => id.type === "third_party");

  let finalErrorComp = ErrorComprehensive.map(function (obj) {
    return obj.ic;
  });

  let groupedQuoteList = quoteComprehesiveGrouped1?.map(
    (item) => item.company_alias
  );

  var filterErrorComp = _.difference(finalErrorComp, groupedQuoteList);

  let finalErrorTp = ErrorTp.map(function (obj) {
    return obj.ic;
  });

  const getIcLogoUrl = (ic) => {
    let Logo = _.filter(masterLogos, {
      companyAlias: ic,
    }).map((v) => v.logoUrl);
    return Logo;
  };

  const getErrorMsgComp = (item) => {
    let errorName = ErrorComprehensive.filter((x) => x.ic === item);
    let errorMessage = errorName[0]?.message;

    if (errorMessage && errorMessage?.length > 100) {
      errorMessage = errorMessage?.slice(0, 100) + "...";
    }

    return errorMessage;
  };

  console.log(getErrorMsgComp());

  const getErrorMsgTp = (item) => {
    let errorName = ErrorTp.filter((x) => x.ic === item);
    let errorMessage = errorName[0]?.message;

    if (errorMessage && errorMessage?.length > 100) {
      errorMessage = errorMessage?.slice(0, 100) + "...";
    }

    return errorMessage;
  };

  const showingErrors = () => {
    return (
      errorIcBased.length > 0 &&
      ((tab === "tab1" && filterErrorComp?.length > 0) ||
        (tab === "tab2" && finalErrorTp?.length > 0)) && (
        <div className="col-lg-12 ml-auto mt-4">
          <ErrorContainer1>
            <div
              class="is__getquote__title"
              style={lessthan767 ? { textAlign: "center" } : {}}
            >
              Insurance providers which didn't produce quotes for selected
              version {versionId}.
            </div>
            <div class="is__getquote__logos">
              {(tab === "tab1" ? filterErrorComp : finalErrorTp).map((item) => (
                <div>
                  <CustomTooltip
                    rider="true"
                    id="cpa__Tooltipvol"
                    place={"bottom"}
                    customClassName="mt-3"
                    allowClick={lessthan767}
                  >
                    <img
                      alt="sks"
                      src={getIcLogoUrl(item)}
                      className="img-responsive form-check-label"
                      height="80"
                      width="auto"
                      data-tip={`<h3>${
                        item === "liberty_videocon"
                          ? "Liberty GIC LTD"
                          : item?.replace(/_/g, " ")
                      }</h3> <div>${
                        tab === "tab1"
                          ? getErrorMsgComp(item)
                          : getErrorMsgTp(item)
                      }</div>`}
                      // data-tip="<h3 >Compulsory Personal Accident</h3> <div>Compulsory Personal Accident cover protects you against partial, total disability, or death caused due to an accident. As per the IRDAI notice. Personal Accident (PA) Cover is mandatory if the car is owned by an individual.</div>"
                      data-html={true}
                      data-for="cpa__Tooltipvol"
                      // className="form-check-label"
                      // htmlFor={"Compulsory Personal Accident"}
                    />
                  </CustomTooltip>
                </div>
              ))}
            </div>
            {
              <div class="is__getquote__info_label">
                Following are possible reasons
              </div>
            }

            {token ? (
              <ul className="error-list text-left p-0 mx-3">
                {(tab === "tab1" ? filterErrorComp : finalErrorTp).map(
                  (item) => (
                    <li key={item} className="my-2">
                      {" "}
                      <span className="error-name">
                        {" "}
                        {item?.replace(/_/g, " ")}:-{" "}
                      </span>{" "}
                      {tab === "tab1"
                        ? getErrorMsgComp(item)
                        : getErrorMsgTp(item)}{" "}
                    </li>
                  )
                )}
              </ul>
            ) : (
              <ul class="is__getquote__info">
                <li>Selected vehicle is not mapped with the Insurer. </li>
                <li>
                  Insurer plans are not available for selected combination.{" "}
                </li>
                <li>
                  Insurer is not reachable to provide live quotes currently.
                </li>
              </ul>
            )}
          </ErrorContainer1>
        </div>
      )
    );
  };

  // useEffect(() => {
  //   if (temp_data?.productSubTypeId && temp_data?.modelId)
  //     dispatch(
  //       getFuelType({
  //         modelId: temp_data?.modelId,
  //         productSubTypeId: temp_data?.productSubTypeId,
  //       })
  //     );
  // }, [temp_data?.modelId, temp_data?.productSubTypeId]);

  const [timerShow, setTimerShow] = useState(false);

  const handleOnIdle = () => {
    setTimerShow(true);
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 5 * 1000 * 60,
    onIdle: handleOnIdle,
    // onActive: handleOnActive,
    // onAction: handleOnAction,
    debounce: 500,
  });

  // checking any popup open or not

  const [popupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    if (prevPopup3 || prevPopup2 || sendQuotes || compare) {
      setPopupOpen(true);
    } else {
      setPopupOpen(false);
    }
  }, [prevPopup3, prevPopup2, sendQuotes, compare]);

  const [assistedMode, setAssistedMode] = useState(false);
  const [homeStateData, setHomeStateData] = useState(false);
  const [filterStateData, setFilterStateData] = useState(false);
  //NCB config
  const showPrevPopUp = (homeStateDataPar, filterStateDataPar) => {
    setHomeStateData(homeStateDataPar);
    setFilterStateData(filterStateDataPar);
    setAssistedMode(true);
    setPrevPopup2(true);
  };

  const onCloseAssisted = () => [
    setAssistedMode(false),
    setPrevPopup2(false),
    setHomeStateData(false),
    setFilterStateData(false),
  ];

  //renewal third party click
  useEffect(() => {
    if (tabClick) {
      dispatch(TabClick(false));
      document.getElementById("tab2") &&
        document.getElementById("tab2").click();
    }
  }, [tabClick]);

  return (
    <>
      <TimeoutPopup show={timerShow} onClose={() => setTimerShow(false)} />
      <MainContainer id={"mainContainerQuotes"}>
        {lessthan993 && (
          // <HideDuring >
          <BottomTabsContainer
            style={{
              display: popupOpen || addonDrawer || compare ? "none" : "flex",
            }}
          >
            <MobileFilterButtons onClick={toggleDrawer("left", true)}>
              <a class="TabBar__StyledLink-sc-cvgqr0-0 exoIhE">
                <div class="TabBar___StyledDiv-sc-cvgqr0-5 gPutWC">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 512 512"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="32"
                      d="M35.4 87.12l168.65 196.44A16.07 16.07 0 01208 294v119.32a7.93 7.93 0 005.39 7.59l80.15 26.67A7.94 7.94 0 00304 440V294a16.07 16.07 0 014-10.44L476.6 87.12A14 14 0 00466 64H46.05A14 14 0 0035.4 87.12z"
                    ></path>
                  </svg>
                </div>
                <div
                  class="TabBar___StyledDiv2-sc-cvgqr0-6 jeDtVb"
                  style={{ fontSize: "12px" }}
                >
                  Addons
                </div>
              </a>
            </MobileFilterButtons>

            <MobileFilterButtons
              onClick={() => {
                setSendQuotes(true);
              }}
            >
              <a class="TabBar__StyledLink-sc-cvgqr0-0 exoIhE">
                <div class="TabBar___StyledDiv-sc-cvgqr0-5 gPutWC">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 24 24"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle fill="none" cx="17.5" cy="18.5" r="1.5"></circle>
                    <circle fill="none" cx="5.5" cy="11.5" r="1.5"></circle>
                    <circle fill="none" cx="17.5" cy="5.5" r="1.5"></circle>
                    <path d="M5.5,15c0.91,0,1.733-0.358,2.357-0.93l6.26,3.577C14.048,17.922,14,18.204,14,18.5c0,1.93,1.57,3.5,3.5,3.5 s3.5-1.57,3.5-3.5S19.43,15,17.5,15c-0.91,0-1.733,0.358-2.357,0.93l-6.26-3.577c0.063-0.247,0.103-0.502,0.108-0.768l6.151-3.515 C15.767,8.642,16.59,9,17.5,9C19.43,9,21,7.43,21,5.5S19.43,2,17.5,2S14,3.57,14,5.5c0,0.296,0.048,0.578,0.117,0.853L8.433,9.602 C7.808,8.64,6.729,8,5.5,8C3.57,8,2,9.57,2,11.5S3.57,15,5.5,15z M17.5,17c0.827,0,1.5,0.673,1.5,1.5S18.327,20,17.5,20 S16,19.327,16,18.5S16.673,17,17.5,17z M17.5,4C18.327,4,19,4.673,19,5.5S18.327,7,17.5,7S16,6.327,16,5.5S16.673,4,17.5,4z M5.5,10C6.327,10,7,10.673,7,11.5S6.327,13,5.5,13S4,12.327,4,11.5S4.673,10,5.5,10z"></path>
                  </svg>
                </div>
                <div
                  class="TabBar___StyledDiv2-sc-cvgqr0-6 jeDtVb"
                  style={{ fontSize: "12px" }}
                >
                  Share
                </div>
              </a>
            </MobileFilterButtons>

            <MobileFilterButtons>
              <a
                class="TabBar__StyledLink-sc-cvgqr0-0 exoIhE"
                href={`tel:${ContactFn()}`}
              >
                <div class="TabBar___StyledDiv-sc-cvgqr0-5 gPutWC">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 24 24"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill="none"
                      d="M16.585,19.999l2.006-2.005l-2.586-2.586l-1.293,1.293c-0.238,0.239-0.579,0.342-0.912,0.271 c-0.115-0.024-2.842-0.611-4.502-2.271s-2.247-4.387-2.271-4.502c-0.069-0.33,0.032-0.674,0.271-0.912l1.293-1.293L6.005,5.408 L4,7.413c0.02,1.223,0.346,5.508,3.712,8.874C11.067,19.643,15.337,19.978,16.585,19.999z"
                    ></path>
                    <path d="M16.566 21.999c.005 0 .023 0 .028 0 .528 0 1.027-.208 1.405-.586l2.712-2.712c.391-.391.391-1.023 0-1.414l-4-4c-.391-.391-1.023-.391-1.414 0l-1.594 1.594c-.739-.22-2.118-.72-2.992-1.594s-1.374-2.253-1.594-2.992l1.594-1.594c.391-.391.391-1.023 0-1.414l-4-4c-.375-.375-1.039-.375-1.414 0L2.586 5.999C2.206 6.379 1.992 6.901 2 7.434c.023 1.424.4 6.37 4.298 10.268S15.142 21.976 16.566 21.999zM6.005 5.408l2.586 2.586L7.298 9.287c-.239.238-.341.582-.271.912.024.115.611 2.842 2.271 4.502s4.387 2.247 4.502 2.271c.333.07.674-.032.912-.271l1.293-1.293 2.586 2.586-2.006 2.005c-1.248-.021-5.518-.356-8.873-3.712C4.346 12.921 4.02 8.636 4 7.413L6.005 5.408zM19.999 10.999h2c0-5.13-3.873-8.999-9.01-8.999v2C17.051 4 19.999 6.943 19.999 10.999z"></path>
                    <path d="M12.999,8c2.103,0,3,0.897,3,3h2c0-3.225-1.775-5-5-5V8z"></path>
                  </svg>
                </div>
                <div
                  class="TabBar___StyledDiv2-sc-cvgqr0-6 jeDtVb"
                  style={{ fontSize: "12px" }}
                >
                  Call
                </div>
              </a>
            </MobileFilterButtons>
            <MobileFilterButtons
              onClick={() =>
                quoteComprehesiveGrouped1 &&
                quoteComprehesiveGrouped1?.length > 0 &&
                tab !== "tab2" &&
                // document.getElementById(
                //   `reviewAgree${quoteComprehesiveGrouped1[0]?.policyId}`
                // )
                //   ? setMobileComp(true)
                //   : {}
                setMobileComp(true)
              }
            >
              <a class="TabBar__StyledLink-sc-cvgqr0-0 exoIhE">
                <div class="TabBar___StyledDiv-sc-cvgqr0-5 gPutWC">
                  <img
                    src={`${extPath}/assets/images/balance.svg`}
                    height="20"
                  />
                </div>

                <div
                  class="TabBar___StyledDiv2-sc-cvgqr0-6 jeDtVb"
                  style={{ fontSize: "12px" }}
                >
                  Compare
                </div>
              </a>
            </MobileFilterButtons>
          </BottomTabsContainer>
          // </HideDuring>
        )}

        <ToasterOla
          callToaster={callToaster}
          setCall={setCallToaster}
          setToasterShown={setToasterShown}
          setShareQuotesFromToaster={setShareQuotesFromToaster}
          setEdit={setSendQuotes}
          type={type}
        />
        <Toaster
          Theme={{}}
          callToaster={callToasterClaim}
          setCall={setCallToasterClaim}
          content={
            "We have assumed that no claims were made in your previous policy."
          }
          buttonText={"Edit"}
          setToasterShown={setToasterShownClaim}
          setEdit={() => setClaimModal(true)}
          type={type}
        />
        <Toaster
          Theme={{}}
          callToaster={callToasterPreIc}
          setCall={setCallToasterPreIc}
          content={`We have assumed your previous policy as Comprehensive. Click on edit to change your previous policy type.`}
          buttonText={"Edit"}
          setToasterShown={setToasterShownPreIc}
          setEdit={() =>
            document.getElementById("policyPopupId") &&
            document.getElementById("policyPopupId")?.click()
          }
          type={type}
        />
        <Row>
          <FilterContainer
            type={type}
            typeId={typeId}
            quote={
              tab === "tab1"
                ? shortTerm3
                  ? quoteShortTerm3
                  : shortTerm6
                  ? quoteShortTerm6
                  : TypeReturn(type) !== "cv"
                  ? quoteComprehesiveGrouped1
                  : quoteComprehesive
                : quotetThirdParty
            }
            allQuoteloading={
              !quotesLoadingComplted &&
              quotesLoaded > 0 &&
              !quotesLoadingInitiated
            }
            setPopupOpen={setPopupOpen}
            isMobileIOS={isMobileIOS}
            assistedMode={assistedMode}
            showPrevPopUp={showPrevPopUp}
            ConfigNcb={ConfigNcb}
          />
        </Row>
        <NonStickyRows>
          <Row>
            <Col lg={12} md={12}>
              <Filters
                //	CompareFn={CompareFn}
                compare={compare}
                quote={
                  tab === "tab1"
                    ? shortTerm3
                      ? quoteShortTerm3
                      : shortTerm6
                      ? quoteShortTerm6
                      : TypeReturn(type) !== "cv"
                      ? quoteComprehesiveGrouped1
                      : quoteComprehesive
                    : quotetThirdParty
                }
                back={back}
                setSortBy={setSortBy}
                gstToggle={gstToggle}
                setGstToggle={setGstToggle}
                daysToExpiry={daysToExpiry}
                setDaysToExpiry={setDaysToExpiry}
                allQuoteloading={!quotesLoadingComplted && quotesLoaded > 0}
                setPopupOpen={setPopupOpen}
              />
            </Col>
          </Row>
          {lessthan993 && (
            <MobileAddonButtonsContainer>
              {!temp_data?.odOnly && temp_data?.ownerTypeId === 1 && (
                <MobileAddonButton
                  checked={addOnsAndOthers?.selectedCpa?.includes(
                    "Compulsory Personal Accident"
                  )}
                  onClick={() => {
                    document.getElementById(`Compulsory Personal Accident`) &&
                      document
                        .getElementById(`Compulsory Personal Accident`)
                        .click();
                  }}
                >
                  CPA{" "}
                  {addOnsAndOthers?.selectedCpa?.includes(
                    "Compulsory Personal Accident"
                  ) && (
                    <i className="fa fa-check" style={{ color: "green" }}></i>
                  )}
                </MobileAddonButton>
              )}
              {temp_data?.tab !== "tab2" && (
                <>
                  <MobileAddonButton
                    min={true}
                    lessthan360={lessthan360}
                    checked={addOnsAndOthers?.selectedAddons?.includes(
                      "zeroDepreciation"
                    )}
                    onClick={() => {
                      document.getElementById(`Zero Depreciation`) &&
                        document.getElementById(`Zero Depreciation`).click();
                    }}
                  >
                    Zero Dep{" "}
                    {addOnsAndOthers?.selectedAddons?.includes(
                      "zeroDepreciation"
                    ) && (
                      <i className="fa fa-check" style={{ color: "green" }}></i>
                    )}
                  </MobileAddonButton>

                  <MobileAddonButton
                    checked={addOnsAndOthers?.selectedAddons?.includes(
                      "roadSideAssistance"
                    )}
                    onClick={() => {
                      document.getElementById(`Road Side Assistance`) &&
                        document.getElementById(`Road Side Assistance`).click();
                    }}
                  >
                    RSA{" "}
                    {addOnsAndOthers?.selectedAddons?.includes(
                      "roadSideAssistance"
                    ) && (
                      <i className="fa fa-check" style={{ color: "green" }}></i>
                    )}
                  </MobileAddonButton>
                </>
              )}
              <>
                {["left"].map((anchor) => (
                  <React.Fragment key={anchor}>
                    <MobileMoreAddonButton
                      onClick={toggleDrawer(anchor, true)}
                      // style={{ display: "none" }}
                    >
                      <i
                        style={
                          process.env.REACT_APP_BROKER === "ABIBL"
                            ? { verticalAlign: "middle", marginTop: "-0.5px" }
                            : {}
                        }
                        className="fa fa-plus-circle"
                      ></i>
                    </MobileMoreAddonButton>
                    <SwipeableDrawer
                      anchor={anchor}
                      open={addonDrawer[anchor]}
                      onClose={
                        (toggleDrawer(anchor, false),
                        () => {
                          setAddonDrawer(false);
                        })
                      }
                      onOpen={toggleDrawer(anchor, true)}
                      ModalProps={{
                        keepMounted: true,
                      }}
                    >
                      <AddonDrawerContent>
                        <AddonDrawerHeader>
                          <div className="addonMobileTitle">Add-ons</div>

                          <CloseIcon
                            fontSize={"1.25rem"}
                            onClick={() => {
                              setAddonDrawer(false);
                            }}
                          />
                        </AddonDrawerHeader>
                        <AddOnsCard
                          tab={tab}
                          type={TypeReturn(type)}
                          setShortTerm3={setShortTerm3}
                          setShortTerm6={setShortTerm6}
                        />
                        <AddonDrawerFooter>
                          <div
                            className="addonDrawerFooterClear"
                            onClick={() => {
                              document.getElementById(`clearAllAddons`) &&
                                document
                                  .getElementById(`clearAllAddons`)
                                  .click();
                            }}
                          >
                            <u>Clear All</u>
                          </div>
                          <div
                            className="addonDrawerFooterApply"
                            onClick={() => {
                              document.getElementById(
                                `updateDiscountsButton`
                              ) &&
                                document
                                  .getElementById(`updateDiscountsButton`)
                                  .click();

                              document.getElementById(
                                `updateAccesoriesButton`
                              ) &&
                                document
                                  .getElementById(`updateAccesoriesButton`)
                                  .click();

                              document.getElementById(
                                `updateAdditionsButton`
                              ) &&
                                document
                                  .getElementById(`updateAdditionsButton`)
                                  .click();
                              setAddonDrawer(false);
                            }}
                          >
                            Apply
                          </div>
                        </AddonDrawerFooter>
                      </AddonDrawerContent>
                    </SwipeableDrawer>
                  </React.Fragment>
                ))}
              </>
            </MobileAddonButtonsContainer>
          )}
          <Row>
            <Col lg={3} md={6} sm={6} xs="6">
              {prefillLoading || updateQuoteLoader ? (
                <TabWrapper
                  width="290px"
                  className="tabWrappers"
                  style={{
                    position: "relative",
                    bottom: "57px",
                  }}
                >
                  <Skeleton
                    width={236}
                    height={30}
                    style={{ display: lessthan993 ? "none" : "inline-block" }}
                  ></Skeleton>
                </TabWrapper>
              ) : (
                <TabWrapper width="290px" className="tabWrappers">
                  <Tab
                    className="comprehensive_tab"
                    isActive={Boolean(tab === "tab1")}
                    onClick={() => setTab("tab1")}
                  >
                    {temp_data?.odOnly ? "Own Damage" : "Comprehensive"}
                  </Tab>
                  <Tab
                    className="tp_tab"
                    isActive={Boolean(tab === "tab2")}
                    onClick={() =>
                      process.env.REACT_APP_BROKER !== "SRIYAH"
                        ? setTab("tab2")
                        : swal(
                            "Please Note",
                            "Third party quotes have been blocked.",
                            "info"
                          )
                    }
                    id={"tab2"}
                    style={{ pointerEvents: temp_data?.odOnly ? "none" : "" }}
                  >
                    {lessthan359 || (isMobileIOS && lessthan400) || ""
                      ? "TP"
                      : "Third Party"}
                  </Tab>
                </TabWrapper>
              )}
            </Col>

            <Col xl={2} lg={2} md={6} sm={6} xs={6}>
              {!quotesLoaded && (
                <SortContainer>
                  <Controller
                    control={control}
                    name="sort-by"
                    defaultValue={sortOptions[0]}
                    render={({ onChange, onBlur, value, name }) => (
                      <>
                        <Dropdown style={{}}>
                          <Dropdown.Toggle
                            style={{
                              border: "none",
                              boxShadow: "none",
                              background: "#fff",
                              color:
                                process.env.REACT_APP_BROKER === "RB"
                                  ? "#1966FF"
                                  : process.env.REACT_APP_BROKER === "EPOCH"
                                  ? "#f47220"
                                  : "#000",
                              fontSize: "14px",
                            }}
                          >
                            Sort
                          </Dropdown.Toggle>
                          <Dropdown.Menu style={{ textAlign: "left" }}>
                            {sortOptions.map((x) => (
                              <div style={{ display: "flex" }}>
                                <img
                                  className="ml-2 mr-1"
                                  src={
                                    x.id === "2" || x.id === "4"
                                      ? `${extPath}/assets/images/sort-up.svg`
                                      : `${extPath}/assets/images/sort-down.svg`
                                  }
                                />
                                <Dropdown.Item
                                  name={name}
                                  value={value}
                                  onClick={() => {
                                    setSortIcon(x.value);
                                    setSortBy(x.value);
                                  }}
                                  style={{
                                    fontSize: "11px",
                                    lineHeight: "20px",
                                    padding: "0.25rempx !important",
                                    width: "auto",
                                  }}
                                >
                                  {" "}
                                  {x.label}{" "}
                                </Dropdown.Item>
                              </div>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                        {/* <MultiSelect
                          quotePage
                          knowMore
                          quotes
                          sort
                          name={name}
                          onChange={onChange}
                          ref={register}
                          value={value}
                          onBlur={onBlur}
                          isMulti={false}
                          options={sortOptions}
                          placeholder={"Sort-By"}
                          errors={errors.state}
                          Styled
                          closeOnSelect
                          isSearchable={false}
                          //   sortMulti={true}
                        /> */}
                      </>
                    )}
                  />
                </SortContainer>
              )}
            </Col>
          </Row>
          <Row
            style={{
              padding: lessthan993
                ? lessthan993
                  ? " 10px 35px"
                  : "10px 50px 10px 50px"
                : "10px 40px 10px 90px",
              ...(lessthan993 && { position: "relative", bottom: "100px" }),
            }}
          >
            {!lessthan993 && (
              <Col lg={3} md={12}>
                <div
                  style={{
                    pointerEvents:
                      prefillLoading || updateQuoteLoader ? "none" : "",
                  }}
                >
                  <AddOnsCard
                    tab={tab}
                    type={TypeReturn(type)}
                    setShortTerm3={setShortTerm3}
                    setShortTerm6={setShortTerm6}
                  />
                </div>
              </Col>
            )}
            <Col lg={9} md={12} className="quoteConatinerCards">
              {prefillLoading || updateQuoteLoader ? (
                <FilterTopBoxTitle
                  compare={compare}
                  align={"center"}
                  exp={true}
                >
                  <Skeleton
                    width={115}
                    height={30}
                    style={{
                      position: "relative",
                      top: "6px",
                      display: lessthan993 ? "none" : "inline-block",
                    }}
                  ></Skeleton>
                </FilterTopBoxTitle>
              ) : (
                <FilterTopBoxTitle
                  compare={compare}
                  align={"center"}
                  exp={true}
                  style={{ ...(lessthan993 && { display: "none" }) }}
                >
                  <span className="quoteLen">
                    {
                      (tab === "tab1"
                        ? shortTerm3
                          ? quoteShortTerm3
                          : shortTerm6
                          ? quoteShortTerm6
                          : quoteComprehesiveGrouped
                        : quotetThirdParty
                      )?.length
                    }
                  </span>{" "}
                  <span className="foundMessageQuote">Quotes Found </span>{" "}
                  {/* {daysToExpiry && (
									<span className="expiryText">
										| Your policy Expires in {daysToExpiry} days
									</span>
								)} */}
                </FilterTopBoxTitle>
              )}

              {(!quotesLoadingComplted ||
                quotesLoaded <
                  (quotesList?.third_party
                    ? quotesList?.third_party?.length
                    : 0) +
                    (quotesList?.comprehensive
                      ? quotesList?.comprehensive?.length
                      : 0) +
                    (quotesList?.short_term
                      ? quotesList?.short_term?.length
                      : 0)) &&
                quotesLoaded > 0 && (
                  <Row>
                    <ProgrssBarContainer>
                      <ProgressBar
                        striped
                        variant="info"
                        now={progressPercent}
                        animated
                      />
                    </ProgrssBarContainer>
                  </Row>
                )}
              <Row
                style={{
                  width: lessthan993 ? "unset" : lessthan1350 ? "99%" : "97%",
                  marginTop: "35px",
                }}
              >
                {tab === "tab1" && !shortTerm3 && !shortTerm6 && (
                  <>
                    {(!_.isEmpty(quoteComprehesiveGrouped1) &&
                    quoteComprehesiveGrouped1[0]
                      ? _.uniqBy(quoteComprehesiveGrouped1, "company_alias")
                      : _.uniqBy(quoteComprehesiveGrouped, "company_alias")
                    )?.map((item, index) => (
                      <QuoteCard
                        quote={
                          quoteComprehesiveGrouped1[index] ||
                          quoteComprehesiveGrouped[index]
                        }
                        register={register}
                        index={index}
                        compare={compare}
                        lessthan767={lessthan767}
                        length={CompareData?.length}
                        watch={watch}
                        onCompare={!_.isEmpty(CompareData)}
                        type={type}
                        setPrevPopup={setPrevPopup2}
                        setPrevPopupTp={setPrevPopup3}
                        setQuoteData={setQuoteData}
                        prevPopup={prevPopup2}
                        setSelectedId={setSelectedId}
                        popupCard={false}
                        setSelectedCompanyName={setSelectedCompanyName}
                        setSelectedCompanyAlias={setSelectedCompanyAlias}
                        setSelectedIcId={setSelectedIcId}
                        gstToggle={gstToggle}
                        maxAddonsMotor={maxAddonsMotor}
                        setKnowMoreObject={setKnowMoreObject}
                        setKnowMore={setKnowMore}
                        knowMore={knowMore}
                        setSelectedKnowMore={setSelectedKnowMore}
                        quoteComprehesiveGrouped={
                          quoteComprehesiveGrouped1
                            ? quoteComprehesiveGrouped1
                            : quoteComprehesiveGrouped
                        }
                        knowMoreCompAlias={knowMoreObject?.quote?.company_alias}
                        allQuoteloading={
                          !quotesLoadingComplted && quotesLoaded > 0
                        }
                        sendQuotes={sendQuotes}
                        setApplicableAddonsLits={setApplicableAddonsLits}
                        setSendQuotes={setSendQuotes}
                        typeId={typeId}
                        isMobileIOS={isMobileIOS}
                        journey_type={journey_type}
                        setZdlp={setZdlp}
                        zdlp={zdlp}
                        mobileComp={mobileComp}
                      />
                    ))}
                    {_.isEmpty(quoteComprehesive) &&
                      progressPercent === 100 &&
                      (!prefillLoading || !updateQuoteLoader) &&
                      !loading &&
                      quotesLoaded >=
                        (quotesList?.third_party
                          ? quotesList?.third_party?.length
                          : 0) +
                          (quotesList?.comprehensive
                            ? quotesList?.comprehensive?.length
                            : 0) && (
                        <NoQuote>
                          <img
                            src={`${extPath}/assets/images/nodata3.png`}
                            alt="nodata"
                            height="200"
                            width="200"
                            className="mx-auto"
                          />
                          <label
                            className="text-secondary text-center mt-1"
                            style={{ fontSize: "16px" }}
                          >
                            No Quote Found
                          </label>
                        </NoQuote>
                      )}
                  </>
                )}
                {tab === "tab1" && shortTerm3 && (
                  <>
                    {(!_.isEmpty(quoteShortTerm3) && quoteShortTerm3[0]
                      ? _.uniqBy(quoteShortTerm3, "company_alias")
                      : []
                    )?.map((item, index) => (
                      <QuoteCard
                        quote={quoteShortTerm3[index]}
                        register={register}
                        index={index}
                        compare={compare}
                        lessthan767={lessthan767}
                        length={CompareData?.length}
                        watch={watch}
                        onCompare={!_.isEmpty(CompareData)}
                        type={type}
                        setPrevPopup={setPrevPopup2}
                        setPrevPopupTp={setPrevPopup3}
                        setQuoteData={setQuoteData}
                        prevPopup={prevPopup2}
                        setSelectedId={setSelectedId}
                        popupCard={false}
                        setSelectedCompanyName={setSelectedCompanyName}
                        setSelectedCompanyAlias={setSelectedCompanyAlias}
                        setSelectedIcId={setSelectedIcId}
                        gstToggle={gstToggle}
                        maxAddonsMotor={maxAddonsMotor}
                        setKnowMoreObject={setKnowMoreObject}
                        setKnowMore={setKnowMore}
                        knowMore={knowMore}
                        setSelectedKnowMore={setSelectedKnowMore}
                        quoteComprehesiveGrouped={
                          quoteComprehesiveGrouped1
                            ? quoteComprehesiveGrouped1
                            : quoteComprehesiveGrouped
                        }
                        knowMoreCompAlias={knowMoreObject?.quote?.company_alias}
                        allQuoteloading={
                          !quotesLoadingComplted && quotesLoaded > 0
                        }
                        sendQuotes={sendQuotes}
                        setApplicableAddonsLits={setApplicableAddonsLits}
                        setSendQuotes={setSendQuotes}
                        typeId={typeId}
                        isMobileIOS={isMobileIOS}
                        journey_type={journey_type}
                        setZdlp={setZdlp}
                        zdlp={zdlp}
                        mobileComp={mobileComp}
                      />
                    ))}
                    {_.isEmpty(quoteShortTerm3) &&
                      progressPercent === 100 &&
                      (!prefillLoading || !updateQuoteLoader) &&
                      !loading &&
                      quotesLoaded >=
                        (quotesList?.third_party
                          ? quotesList?.third_party?.length
                          : 0) +
                          (quotesList?.comprehensive
                            ? quotesList?.comprehensive?.length
                            : 0) && (
                        <NoQuote>
                          <img
                            src={`${extPath}/assets/images/nodata3.png`}
                            alt="nodata"
                            height="200"
                            width="200"
                            className="mx-auto"
                          />
                          <label
                            className="text-secondary text-center mt-1"
                            style={{ fontSize: "16px" }}
                          >
                            No Quote Found
                          </label>
                        </NoQuote>
                      )}
                  </>
                )}
                {tab === "tab1" && shortTerm6 && (
                  <>
                    {(!_.isEmpty(quoteShortTerm6) && quoteShortTerm6[0]
                      ? _.uniqBy(quoteShortTerm6, "company_alias")
                      : []
                    )?.map((item, index) => (
                      <QuoteCard
                        quote={quoteShortTerm6[index]}
                        register={register}
                        index={index}
                        compare={compare}
                        lessthan767={lessthan767}
                        length={CompareData?.length}
                        watch={watch}
                        onCompare={!_.isEmpty(CompareData)}
                        type={type}
                        setPrevPopup={setPrevPopup2}
                        setPrevPopupTp={setPrevPopup3}
                        setQuoteData={setQuoteData}
                        prevPopup={prevPopup2}
                        setSelectedId={setSelectedId}
                        popupCard={false}
                        setSelectedCompanyName={setSelectedCompanyName}
                        setSelectedCompanyAlias={setSelectedCompanyAlias}
                        setSelectedIcId={setSelectedIcId}
                        gstToggle={gstToggle}
                        maxAddonsMotor={maxAddonsMotor}
                        setKnowMoreObject={setKnowMoreObject}
                        setKnowMore={setKnowMore}
                        knowMore={knowMore}
                        setSelectedKnowMore={setSelectedKnowMore}
                        quoteComprehesiveGrouped={
                          quoteComprehesiveGrouped1
                            ? quoteComprehesiveGrouped1
                            : quoteComprehesiveGrouped
                        }
                        knowMoreCompAlias={knowMoreObject?.quote?.company_alias}
                        allQuoteloading={
                          !quotesLoadingComplted && quotesLoaded > 0
                        }
                        sendQuotes={sendQuotes}
                        setApplicableAddonsLits={setApplicableAddonsLits}
                        setSendQuotes={setSendQuotes}
                        typeId={typeId}
                        isMobileIOS={isMobileIOS}
                        journey_type={journey_type}
                        setZdlp={setZdlp}
                        zdlp={zdlp}
                        mobileComp={mobileComp}
                      />
                    ))}
                    {_.isEmpty(quoteShortTerm6) &&
                      progressPercent === 100 &&
                      (!prefillLoading || !updateQuoteLoader) &&
                      !loading &&
                      quotesLoaded >=
                        (quotesList?.third_party
                          ? quotesList?.third_party?.length
                          : 0) +
                          (quotesList?.comprehensive
                            ? quotesList?.comprehensive?.length
                            : 0) && (
                        <NoQuote>
                          <img
                            src={`${extPath}/assets/images/nodata3.png`}
                            alt="nodata"
                            height="200"
                            width="200"
                            className="mx-auto"
                          />
                          <label
                            className="text-secondary text-center mt-1"
                            style={{ fontSize: "16px" }}
                          >
                            No Quote Found
                          </label>
                        </NoQuote>
                      )}
                  </>
                )}
                {tab === "tab2" && (
                  <>
                    {(!_.isEmpty(quoteTpGrouped1) && quoteTpGrouped1[0]
                      ? _.uniqBy(quoteTpGrouped1, "policyId")
                      : _.uniqBy(quotetThirdParty, "policyId")
                    )?.map((item, index) => (
                      <QuoteCard
                        quote={
                          quoteTpGrouped1[index] || quotetThirdParty[index]
                        }
                        register={register}
                        index={index}
                        compare={compare}
                        lessthan767={lessthan767}
                        length={CompareData?.length}
                        watch={watch}
                        onCompare={!_.isEmpty(CompareData)}
                        type={type}
                        setPrevPopup={setPrevPopup2}
                        setPrevPopupTp={setPrevPopup3}
                        setQuoteData={setQuoteData}
                        prevPopup={prevPopup2}
                        setSelectedId={setSelectedId}
                        popupCard={false}
                        setSelectedCompanyName={setSelectedCompanyName}
                        setSelectedCompanyAlias={setSelectedCompanyAlias}
                        setSelectedIcId={setSelectedIcId}
                        gstToggle={gstToggle}
                        maxAddonsMotor={maxAddonsMotor}
                        setKnowMoreObject={setKnowMoreObject}
                        setKnowMore={setKnowMore}
                        knowMore={knowMore}
                        setSelectedKnowMore={setSelectedKnowMore}
                        quoteComprehesiveGrouped={
                          quoteComprehesiveGrouped1
                            ? quoteComprehesiveGrouped1
                            : quoteComprehesiveGrouped
                        }
                        knowMoreCompAlias={knowMoreObject?.quote?.company_alias}
                        allQuoteloading={
                          !quotesLoadingComplted && quotesLoaded > 0
                        }
                        sendQuotes={sendQuotes}
                        setApplicableAddonsLits={setApplicableAddonsLits}
                        setSendQuotes={setSendQuotes}
                        typeId={typeId}
                        isMobileIOS={isMobileIOS}
                        journey_type={journey_type}
                        setZdlp={setZdlp}
                        zdlp={zdlp}
                        mobileComp={mobileComp}
                      />
                    ))}
                    {(_.isEmpty(quoteTpGrouped1) &&
                      progressPercent === 100 &&
                      !loading &&
                      quotesLoaded >=
                        (quotesList?.third_party
                          ? quotesList?.third_party?.length
                          : 0) +
                          (quotesList?.comprehensive
                            ? quotesList?.comprehensive?.length
                            : 0)) ||
                      (quotesList?.third_party?.length === 0 &&
                        tab === "tab2" && (
                          <NoQuote>
                            <img
                              src={`${extPath}/assets/images/nodata3.png`}
                              alt="nodata"
                              height="200"
                              width="200"
                              className="mx-auto"
                            />
                            <label
                              className="text-secondary text-center mt-1"
                              style={{ fontSize: "16px" }}
                            >
                              No Quote Found
                            </label>
                          </NoQuote>
                        ))}
                  </>
                )}
                {((quotesLoaded &&
                  quotesLoaded <
                    (quotesList?.third_party
                      ? quotesList?.third_party?.length
                      : 0) +
                      (quotesList?.comprehensive
                        ? quotesList?.comprehensive?.length
                        : 0) +
                      (quotesList?.short_term
                        ? quotesList?.short_term?.length
                        : 0)) ||
                  loading ||
                  (quotesLoadingInitiated && loading)) && (
                  <QuoteSkelton
                    popupCard={false}
                    type={type}
                    lessthan767={lessthan767}
                    maxAddonsMotor={maxAddonsMotor}
                  />
                )}
              </Row>

              <Row
                style={{ display: !quotesLoadingComplted ? "none" : "flex" }}
              >
                {" "}
                <ErrorContainer>{showingErrors()}</ErrorContainer>
              </Row>
            </Col>
          </Row>
        </NonStickyRows>
        {/* <ShareQuoteContainer scroll={scrollPosition > 78.4}>
					<ShareQuoteButton
						broker={
							process.env?.REACT_APP_API_BASE_URL ===
							"https://olaapi.fynity.in/api"
						}
						className="mx-auto"
						onClick={() => {
							process.env?.REACT_APP_API_BASE_URL !==
								"https://olaapi.fynity.in/api" && setSendQuotes(true);
							process.env?.REACT_APP_API_BASE_URL !==
								"https://olaapi.fynity.in/api" && setSendPdf(false);
						}}
					>
						<i style={{ fontSize: "20px" }} className="fa fa-share-alt" />
					</ShareQuoteButton>
				</ShareQuoteContainer> */}
        {!lessthan993 && <FloatButton />}
      </MainContainer>

      {compare && <CompareContainer CompareData={CompareData} type={type} />}
      {sendQuotes && (
        <SendQuotes
          show={sendQuotes}
          onClose={setSendQuotes}
          sendPdf={sendPdf}
          setSendPdf={setSendPdf}
          type={type}
          shareQuotesFromToaster={shareQuotesFromToaster}
          setShareQuotesFromToaster={setShareQuotesFromToaster}
          premiumBreakuppdf="premiumBreakuppdf"
          comparepdf="comparepdf"
        />
      )}
      {(saveQuoteLoader || prefillLoading || updateQuoteLoader) && <Loader />}
      {prevPopup2 && (
        <PrevInsurerPopup2
          show={prevPopup2}
          onClose={setPrevPopup2}
          selectedId={selectedId}
          type={type}
          selectedCompanyName={selectedCompanyName}
          selectedCompanyAlias={selectedCompanyAlias}
          selectedIcId={selectedIcId}
          applicableAddonsLits={applicableAddonsLits}
          lessthan767={lessthan767}
          lessthan993={lessthan993}
          typeId={typeId}
          journey_type={journey_type}
          homeStateData={homeStateData}
          setHomeStateData={setHomeStateData}
          filterStateData={filterStateData}
          setFilterStateData={setFilterStateData}
          assistedMode={assistedMode}
          setAssistedMode={setAssistedMode}
          onCloseAssisted={onCloseAssisted}
          shortTerm3={shortTerm3}
          shortTerm6={shortTerm6}
          isComprehensive={tab === "tab1"}
        />
      )}
      {prevPopup3 && (
        <PrevInsurerPopup3
          show={prevPopup3}
          onClose={setPrevPopup3}
          quoteData={quoteData}
          selectedId={selectedId}
          type={type}
          typeId={typeId}
          lessthan993={lessthan993}
          journey_type={journey_type}
        />
      )}

      {knowMore && knowMoreQuote && (
        <KnowMorePopup
          quote={knowMoreQuote}
          show={knowMore}
          onClose={setKnowMore}
          selectedKnow={selectedKnowMore}
          totalAddon={knowMoreObject?.totalAddon}
          totalPremium={knowMoreObject?.totalPremium}
          gst={knowMoreObject?.gst}
          finalPremium={knowMoreObject?.finalPremium}
          totalPremiumA={knowMoreObject?.totalPremiumA}
          totalPremiumB={knowMoreObject?.totalPremiumB}
          totalPremiumC={knowMoreObject?.totalPremiumC}
          applicableAddons={knowMoreObject?.applicableAddons}
          type={knowMoreObject?.type}
          prevInsName={knowMoreObject?.prevInsName}
          addonDiscount={knowMoreObject?.addonDiscount}
          addonDiscountPercentage={knowMoreObject?.addonDiscountPercentage}
          revisedNcb={knowMoreObject?.revisedNcb}
          otherDiscounts={knowMoreObject?.otherDiscounts}
          popupCard={knowMoreObject?.popupCard}
          setPrevPopup={knowMoreObject?.setPrevPopup}
          prevPopup={knowMoreObject?.prevPopup}
          setSelectedId={setSelectedId}
          setSelectedCompanyName={setSelectedCompanyName}
          setSelectedCompanyAlias={setSelectedCompanyAlias}
          totalOthersAddon={knowMoreObject?.totalOthersAddon}
          totalApplicableAddonsMotor={
            knowMoreObject?.totalApplicableAddonsMotor
          }
          uwLoading={knowMoreObject?.uwLoading}
          setSendQuotes={setSendQuotes}
          setSendPdf={setSendPdf}
          sendQuotes={sendQuotes}
          allQuoteloading={!quotesLoadingComplted && quotesLoaded > 0}
          setPrevPopupTp={setPrevPopup3}
          setQuoteData={setQuoteData}
          displayAddress={knowMoreObject?.icAddress}
        />
      )}
      {showClaimModal && (
        <ClaimModal
          set_temp_data={set_temp_data}
          dispatch={dispatch}
          show={showClaimModal}
          CancelAll={CancelAll}
          onHide={() => setClaimModal(false)}
        />
      )}

      <GlobalStyle />
    </>
  );
};

export const GlobalStyle = createGlobalStyle`
body {
  
}
`;

const MainContainer = styled.div`
  width: 100%;
  background: #fff;
  color: ${({ theme }) => theme.regularFont?.textColor || "#707070"};

  overflow: hidden;
  .quoteConatinerCards {
    bottom: 100px !important;
    @media (max-width: 990px) {
      bottom: 0px !important;
    }
  }
  @media (max-width: 767px) {
    .comprehensive_tab {
      font-size: 11px !important;
    }
    .tp_tab {
      font-size: 11px !important;
    }
  }
  @media (max-width: 450px) {
    .comprehensive_tab {
      font-size: 10px !important;
      letter-spacing: 0;
    }
    .tp_tab {
      font-size: 10px !important;
      letter-spacing: 0;
    }
  }
`;
const NoQuote = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
`;
const ErrorContainer = styled.div`
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
`;

const ErrorContainerDiv = styled.div`
  border-top: 4px solid rgb(173, 18, 18) !important;
`;

const TabWrapQuotes = styled.div`
  position: relative;
  //	top: 35%;
  left: 2%;
  bottom: 66px;
  z-index: 800;
  @media (max-width: 990px) {
    width: 100%;
    display: flex;
    justify-content: center;
    top: -7px;

    left: unset;
  }
`;

const ErrorContainer1 = styled.div`
  // box-shadow: rgba(0, 0, 0, 0.2) 6px 6px 28px;
  // background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23333' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 10px;
  border: 1px dashed black;
  font-size: 14px;
  max-width: fit-content;
  display: flex;
  flex-flow: column wrap;
  -webkit-box-pack: center;
  place-content: center;
  -webkit-box-align: center;
  align-items: center;
  margin: 5rem auto 0px;
  padding: 35px 35px;
  @media (max-width: 993px) {
    padding: 35px 10px;
  }
  .is__getquote__title {
    font-size: 18px;
    @media (max-width: 993px) {
      font-size: 12px;
    }
  }
  .is__getquote__logos {
    display: flex;
    flex-flow: row wrap;
    -webkit-box-pack: center;
    place-content: flex-start center;
    align-items: flex-start;
    margin-top: 24px;
    margin-bottom: 20px;
    width: 100%;
  }
  .is__getquote__info_label {
    font-size: 16px;
    margin-bottom: 10px;
    @media (max-width: 993px) {
      font-size: 14px;
    }
  }
  .is__getquote__info {
    width: 100%;
    text-align: justify;
    padding-right: 40px;
    color: rgba(49, 68, 81, 0.7);
    @media (max-width: 993px) {
      font-size: 11px;
    }
  }
  .is__getquote__logos .img-responsive {
    display: block;
    max-width: 100%;
  }
  .is__getquote__logos img {
    margin: 5px;
    max-height: 50px;
    max-width: 110px !important;
  }
`;
const NonStickyRows = styled.div`
  margin-top: 100px;
  width: 100%;
  @media (max-width: 993px) {
    position: relative !important;
    top: 0px;
    width: 100%;
    margin-top: 0px;
  }
  .tabWrappers {
    position: relative;
    bottom: 70px;
    left: 175%;
  }

  @media (max-width: 993px) {
    .tabWrappers {
      position: relative;
      top: -50px !important;
      left: 22px;
      z-index: 1000;
    }
  }
  @media (max-width: 767px) {
    .tabWrappers {
      top: -60px !important;
    }
  }
`;
const ShareQuoteContainer = styled.div`
  position: fixed;
  bottom: 85px;
  right: 20px;
  z-index: 100;
  display: ${(props) => (props.scroll ? "block" : "none")};
  @media (max-width: 993px) {
    display: none;
  }
`;
const ShareQuoteButton = styled.button`
  font-family: ${({ theme }) =>
    theme.QuoteBorderAndFont?.fontFamily || "Inter-Regular"};
  background-color: ${({ broker }) => (broker ? "#D0D0D0D0" : "#ffffff")};
  border: ${({ theme, broker }) =>
    broker ? "#A8A8A8" : theme.Header?.border || "1px solid #bdd400"};
  padding: 11px 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 14px;
  color: #000000;
  outline: none;
  cursor: pointer;
`;

const ProgrssBarContainer = styled.div`
  .progress {
    height: 5px;
    left: 15px;
    top: 40px;
    border-radius: 15px;
    background-color: #f7f7fa !important;
    width: 91%;
    @media (max-width: 993px) {
      width: 100%;
      top: 50px;
      left: 0px;
    }
    @media (max-width: 670px) {
      top: 30px;
    }
  }
  .bg-info {
    border-radius: 8px;
    background-color: ${({ theme }) =>
      `${
        theme.QuoteCard?.color
          ? `${theme.QuoteCard?.color} !important`
          : "#bdd400 !important"
      }`};
  }
`;
const FilterTopBoxTitle = styled.div`
  font-family: ${({ theme }) =>
    theme.QuoteBorderAndFont?.fontFamilyBold || "Inter-SemiBold"};
  position: absolute;

  top: -20px;

  z-index: 100;
  font-size: ${(props) => (props.exp ? "15px" : "14px")};
  line-height: 20px;
  margin-bottom: 6px;
  padding-top: 6px;
  float: initial;
  white-space: nowrap;
  text-align: "left";
  .quoteLen {
    color: black;
  }
  .foundMessageQuote {
    color: ${({ theme }) => theme.regularFont?.textColor || "#707070"};
  }
  .expiryText {
    color: ${({ theme }) => theme.regularFont?.textColor || "#707070"};
  }

  @media (max-width: 1200px) {
    font-size: 12px;
  }
  @media (max-width: 993px) {
    top: 13px;
    width: 95%;
    display: flex;
    justify-content: center;
  }
`;
const SortContainer = styled.div`
  width: 100%;
  z-index: 999;
  left: 350%;
  bottom: 12px;
  position: relative;
  text-align: right;
  @media only screen and (max-width: 993px) {
    width: 90%;
    margin: 10px 30px;
    position: relative;
    left: 0px;
    display: flex;
    justify-content: center;
  }
  @media only screen and (max-width: 993px) {
    margin: -32px 30px;
  }
  @media only screen and (max-width: 400px) {
    margin: -35px 30px;
  }
`;
const SortButton = styled.div`
  font-size: 14px;
  cursor: pointer;
  color: #707070;
  font-family: ${({ theme }) =>
    theme.QuoteBorderAndFont?.fontFamilyBold || "Inter-SemiBold"};
  display: flex;
  justify-content: end;
  .imgDiv {
    height: 20px;
    margin-left: 10px;
  }
  .sortimg {
    color: ${({ theme }) =>
      `${
        theme.QuoteCard?.color
          ? `${theme.QuoteCard?.color} !important`
          : "#bdd400 !important"
      }`};
  }
`;

const MobileFilterButtons = styled.button`
  background-color: white;
  height: 50px;
  width: 50px;
  z-index: 10000;
  border: none;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AddonDrawerContent = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: none;
  z-index: 10000;
  max-width: 285px;
  min-width: 280px;
  .addonMobileTitle {
    color: ${({ theme }) => theme.QuoteCard?.color2 || "#060 !important"};
  }
`;
const AddonDrawerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  font-size: 22px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
`;

const AddonDrawerFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  position: sticky;

  bottom: 0;
  width: 100%;
  background: white;
  width: 99%;

  .addonDrawerFooterClear {
    & u {
      border-bottom: 1px dotted #000;
      text-decoration: none;
    }
  }
  .addonDrawerFooterApply {
    height: 40px;
    width: 100px;
    border-radius: 20px;
    background-color: ${({ theme }) =>
      `${
        theme.QuoteCard?.color
          ? `${theme.QuoteCard?.color} !important`
          : "#bdd400 !important"
      }`};
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
const MobileAddonButtonsContainer = styled.div`
  display: flex;
  width: 70%;
  justify-content: space-between;
  align-items: center;
  position: relative;
  bottom: 55px;
  padding: 0px 10px 0px 20px;
`;
const MobileAddonButton = styled.button`
  padding: 5px 3px;
  border-radius: 20px;
  background: white;
  //border: ${({ theme }) => theme.CheckBox?.border || "1px solid #bdd400"};
  border: 1px solid white;
  font-size: ${({ min, lessthan360 }) =>
    min ? (lessthan360 ? "10px" : "10.5px") : "10.5px"};
  font-weight: 600;
  min-width: 60px;
  box-shadow: ${({ checked, theme }) =>
    checked
      ? `${
          theme.QuoteCard?.color ? `${theme.QuoteCard?.color}` : "#bdd400"
        } 0px 2px 8px`
      : "rgba(0, 0, 0, 0.15) 0px 2px 8px"};
  @media only screen and (max-width: 330px) {
    font-size: 8px;
    min-width: 60px;
  }
  @media only screen and (max-width: 400px) {
    min-width: ${({ min, lessthan360 }) => (min ? "66px" : "60px")};
  }
`;

const MobileMoreAddonButton = styled.button`
  padding: 0px 0px;
  border-radius: 20px;
  background: white;
  border: 1px solid white;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  font-size: 16.5px;
  font-weight: 600;
  min-width: 25px;
  @media only screen and (max-width: 400px) {
    min-width: 20px;
  }
  @media only screen and (max-width: 360px) {
    display: none;
  }
`;

const BottomTabsContainer = styled.div`
  display: none;
  @media only screen and (max-width: 992px) {
    display: flex;
    position: fixed;
    bottom: 0;
    background: white;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
      rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    justify-content: space-around;
    align-items: center;
    height: 50px;
    width: 100%;
    padding: 10px 10px;
  }
  z-index: 500;
`;
