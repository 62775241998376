import React, { useState, useEffect } from "react";
import styled, { createGlobalStyle } from "styled-components";
import * as yup from "yup";
import { currencyFormater, numOnly, Encrypt } from "utils";
import { yupResolver } from "@hookform/resolvers/yup";
import { Textbox, Button, Error } from "components";
import Popup from "./Popup";
import { Table } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { Col, Form, Row, Badge, Button as Btn, Spinner } from "react-bootstrap";
import "./sendQuote.scss";
import { ShareQuote, Prefill } from "../../modules/Home/home.slice";
import { SearchInput } from "../../../src/modules/proposal/typehead";
import swal from "sweetalert";
import MailOutlineOutlinedIcon from "@material-ui/icons/MailOutlineOutlined";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import MessageIcon from "@material-ui/icons/Message";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import _ from "lodash";
import {
  EmailPdf,
  setEmailPdf,
  Whatsapp,
  EmailComparePdf,
  setEmailComparePdf,
} from "modules/quotesPage/quote.slice";
import ThemeObj from "modules/theme-config/theme-config";
import SecureLS from "secure-ls";
import { useMediaPredicate } from "react-media-hook";
import Drawer from "@mui/material/Drawer";
import { MultiSelect } from "components";
import { Controller, useForm } from "react-hook-form";
import { ReactEmails } from "./ReactMultiEmails";
import { TypeReturn } from "modules/type";
import { MultiEmail } from "./MultiEmail";

const ls = new SecureLS();
const ThemeLS = ls.get("themeData");

const Theme = !_.isEmpty(ThemeLS) && ThemeLS ? ThemeLS : ThemeObj;
const yupValidate = yup.object({
  // emailId: yup
  //   .string()
  //   .email("Please enter valid email id")
  //   .min(2, "Minimum 2 chars required")
  //   .max(50, "Must be less than 50 chars"),
  // mobileNo: yup
  // 	.string()
  // 	.min(10, "Mobile No. should be 10 digits")
  // 	.max(10, "Mobile No. should be 10 digits")
  // 	.matches(/^[7-9][0-9]{9}$/, "Must be only digits"),
  // lastName: yup
  // 	.string()
  // 	.required("Last Name is required")
  // 	.min(2, "Minimum 2 chars required")
  // 	.max(50, "Must be less than 50 chars")
  // 	.matches(/^([A-Za-z\s])+$/, "Must contain only alphabets"),
  // firstName: yup
  // 	.string()
  // 	.required("First Name is required")
  // 	.matches(/^([A-Za-z\s])+$/, "Must contain only alphabets")
  // 	.min(2, "Minimum 2 chars required")
  // 	.max(50, "Must be less than 50 chars"),
});

const getLogoUrl = () => {
  switch (process.env?.REACT_APP_BROKER) {
    case "OLA":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/ola.png`;
    case "UNILIGHT":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/unilight.png`;
    case "CP":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/cp.png`;
    case "FYNTUNE":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/fyntune.png`;
    case "ABIBL":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/abiblPdf.jpeg`;
    case "GRAM":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/gc.png`;
    case "ACE":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/ace.png`;
    case "SRIYAH":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/sriyah.png`;
    case "PINC":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/pinc.png`;
    case "EPOCH":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/epochlogo.png`;
    case "RB":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/rb.png`;
    case "SPA":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/insuringall.jpeg`;
    case "BIMA":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/bima.png`;
    case "ANALAH":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/analah.jpeg`;
    case "BAJAJ":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/bajaj.png`;
    case "UIB":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/uib.png`;
    default:
      break;
  }
};
export const SendQuotes = ({
  show,
  onClose,
  sendPdf,
  setSendPdf,
  comparePdfData,
  type,
  shareQuotesFromToaster,
  setShareQuotesFromToaster,
  premiumBreakuppdf,
  comparepdf,
}) => {
  const dispatch = useDispatch();
  const [msg, setMsg] = useState(false);
  const [whatsappSucess, setWhatsappSucess] = useState(false);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const token = query.get("token") || localStorage?.SSO_user_motor;
  const enquiry_id = query.get("enquiry_id");
  const loc = location.pathname ? location.pathname.split("/") : "";

  const { policy } = useSelector((state) => state.payment);

  const {
    quotetThirdParty,
    quoteComprehesive,
    finalPremiumlist1,
    shortTerm,
    selectedTab,
  } = useSelector((state) => state.quotes);
  const { tempData } = useSelector((state) => state.quoteFilter);
  const { temp_data } = useSelector((state) => state.proposal);
  const { temp_data: userDataHome } = useSelector((state) => state.home);
  const { emailPdf, customLoad, emailComparePdf } = useSelector(
    (state) => state.quotes
  );

  let lessthan576 = useMediaPredicate("(max-width: 576px)");
  const lessthan767 = useMediaPredicate("(max-width: 767px)");
  const lessthan600 = useMediaPredicate("(max-height: 600px)");

  let combinedQuotes = _.union(quoteComprehesive, quotetThirdParty);
  const [step, setStep] = useState(2);

  const { handleSubmit, register, errors, watch, setValue, control } = useForm({
    resolver: yupResolver(yupValidate),
    mode: "all",
    reValidateMode: "onBlur",
  });

  const [disEmail, setDisEmail] = useState(true);

  const MobileNo = watch("mobileNo");
  const unformattedEmails = watch("multiEmails");
  const EmailsId = unformattedEmails && unformattedEmails.split(",");
  const val = watch("checkmarkSend");
  const firstName = watch("firstName");
  const selectedQuote = val?.filter(Boolean) || [];

  const [selectedList, setSelectedList] = useState([]);
  const [notificationType, setNotificationType] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [userData, setUserData] = useState(null);

  const customQuotes = watch("customQuotes");

  //Prefill Api
  useEffect(() => {
    if (enquiry_id && !_.isEmpty(loc) && loc[2] === "proposal-page")
      dispatch(Prefill({ enquiryId: enquiry_id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enquiry_id]);

  const getType = (name, journeyStage) => {
    switch (name) {
      case "quotes":
        return "shareQuotes";
      case "proposal-page":
        return temp_data?.journeyStage?.stage === "Proposal Accepted" ||
          temp_data?.journeyStage?.stage === "Payment Initiated" ||
          temp_data?.journeyStage?.stage === "Inspection Accept" ||
          temp_data?.journeyStage?.stage === "Payment Failed"
          ? "proposalCreated"
          : "shareProposal";
      case "payment-confirmation":
        return "sharePayment";
      case "payment-success":
        return "successPayment";
      case "compare-quote":
        return "comparepdf";
      default:
        return "";
    }
  };

  const onSubmit = (data, typeCheck, sendAll) => {
    let quoteList =
      active === "custom" && !_.isEmpty(customQuotes)
        ? customQuotes?.map(
            ({
              idv,
              name,
              logo,
              finalPremium,
              finalPremiumNoGst,
              gst,
              productName,
              policyId,
            }) => ({
              name: name,
              idv: idv,
              logo: logo,
              premium: finalPremiumNoGst,
              premiumWithGst: finalPremium,
              gst: gst,
              action: `${window.location.href}&productId=${Encrypt(policyId)}${
                selectedTab === "tab2"
                  ? `&selectedType=${Encrypt(selectedTab)}`
                  : ""
              }${
                shortTerm && selectedTab !== "tab2"
                  ? `&selectedTerm=${Encrypt(shortTerm)}`
                  : ""
              }`,
              productName: productName,
            })
          )
        : !_.isEmpty(finalPremiumlist1)
        ? finalPremiumlist1.map((x) => ({
            ...x,
            premium: x?.finalPremiumNoGst,
            premiumWithGst:
              x?.finalPremium * 1 ? Math.round(x?.finalPremium) : 0,
            action: `${window.location.href}&productId=${Encrypt(x?.policyId)}${
              selectedTab === "tab2"
                ? `&selectedType=${Encrypt(selectedTab)}`
                : ""
            }${
              shortTerm && selectedTab !== "tab2"
                ? `&selectedTerm=${Encrypt(shortTerm)}`
                : ""
            }`,
          }))
        : [];
    if (!sendPdf && loc[1] !== "payment-success") {
      setNotificationType(typeCheck);
      if (typeCheck !== 2) {
        var sendData = {
          userType: token ? "pos" : "employee",
          firstName:
            userDataHome?.firstName ||
            temp_data?.firstName ||
            userDataHome?.userProposal?.additonalData?.owner?.firstName ||
            "Customer",
          lastName:
            userDataHome?.lastName ||
            temp_data?.lastName ||
            userDataHome?.userProposal?.additonalData?.owner?.lastName ||
            "",
          emailId: EmailsId && !_.isEmpty(EmailsId) ? EmailsId : null,
          mobileNo: MobileNo,
          logo: getLogoUrl(),
          notificationType: sendAll ? "all" : typeCheck === 1 ? "sms" : "email",
          enquiryId: enquiry_id,
          to: `91${MobileNo}`,
          url: window.location.href,
          link:
            temp_data?.journeyStage?.stage === "Proposal Accepted" ||
            temp_data?.journeyStage?.stage === "Payment Initiated"
              ? `${window.location.href}&dropout=${Encrypt(true)}&icr=${Encrypt(
                  true
                )}`
              : window.location.href,
          domain: `http://${window.location.hostname}`,
          type: getType(loc[2]),
          ...(loc[2] === "quotes" && { quotes: quoteList }),
          ...(loc[2] === "quotes" && {
            productName: quoteList[0]?.productName,
          }),
          ...(loc[2] === "payment-confirmation" && {
            productName: temp_data?.selectedQuote?.productName,
          }),
          ...(loc[2] === "proposal-page" && {
            productName: temp_data?.selectedQuote?.productName,
          }),

          //	selectedQuoteList: selectedList?.filter(Boolean) || [],
        };

        setUserData(sendData);
        setMsg(true);
        dispatch(ShareQuote(sendData));
        setTimeout(() => {
          onClose(false);
          setMsg(false);
        }, 2500);
      } else {
        if (MobileNo) {
          if (MobileNo?.length < 10) {
            swal("Info", "Please enter Valid mobile number", "info");
            setValue("mobile", "");
          } else {
            if (
              process.env.REACT_APP_BROKER === "FYNTUNE" ||
              process.env.REACT_APP_BROKER === "GRAM" ||
              process.env.REACT_APP_BROKER === "ABIBL" ||
              process.env.REACT_APP_BROKER === "RB"
            ) {
              // abibl whatsapp pc opening in new window
              var content = `Hi i have shared you some quotes please check it out \n \n   ${
                temp_data?.journeyStage?.stage === "Proposal Accepted" ||
                temp_data?.journeyStage?.stage === "Payment Initiated"
                  ? `${window.location.href}&dropout=${Encrypt(
                      true
                    )}&icr=${Encrypt(true)}`
                  : window.location.href
              }`;
              if (loc[2] === "proposal-page") {
                content = `Hi i have shared you a proposal form  please check it out \n \n   ${
                  temp_data?.journeyStage?.stage === "Proposal Accepted" ||
                  temp_data?.journeyStage?.stage === "Payment Initiated"
                    ? `${window.location.href}&dropout=${Encrypt(
                        true
                      )}&icr=${Encrypt(true)}`
                    : window.location.href
                }`;
              }
              window.open(
                `https://api.whatsapp.com/send?phone=${`91${MobileNo}`}&text=${content}#`
              );
            } else {
              var data = {
                userType: token ? "pos" : "employee",
                firstName:
                  userDataHome?.firstName ||
                  temp_data?.firstName ||
                  userDataHome?.userProposal?.additonalData?.owner?.firstName ||
                  "Customer",
                lastName:
                  userDataHome?.lastName ||
                  temp_data?.lastName ||
                  userDataHome?.userProposal?.additonalData?.owner?.lastName ||
                  " ",
                to: `91${MobileNo}`,
                type: shareQuotesFromToaster
                  ? "driverDetails"
                  : getType(
                      loc[2],
                      loc[2] === "proposal-page"
                        ? temp_data?.journeyStage?.stage
                        : false
                    ),
                enquiryId: enquiry_id,
                url: window.location.href,

                link:
                  temp_data?.journeyStage?.stage === "Proposal Accepted" ||
                  temp_data?.journeyStage?.stage === "Payment Initiated"
                    ? `${window.location.href}&dropout=${Encrypt(
                        true
                      )}&icr=${Encrypt(true)}`
                    : window.location.href,

                ...(loc[2] === "proposal-page" && {
                  proposalData: temp_data?.userProposal,
                }),

                ...(loc[2] === "quotes" && { quotes: quoteList }),
              };
              dispatch(Whatsapp(data));
              setWhatsappSucess(true);
              setMsg(true);
              if (setShareQuotesFromToaster) {
                setShareQuotesFromToaster(false);
              }
            }
          }
        } else {
          swal("Info", "Please enter your mobile number", "info");
        }
      }
    } else if (loc[2] === "compare-quote") {
      if (EmailsId) {
        dispatch(
          EmailComparePdf({
            // firstName: userDataHome?.firstName || "Customer",
            // lastName: userDataHome?.lastName || " "+userDataHome?.lastName || " ",
            userType: token ? "pos" : "employee",
            name:
              (userDataHome?.firstName || temp_data?.firstName || "Customer") +
              " " +
              (userDataHome?.lastName || temp_data?.lastName || " "),
            data: JSON.stringify(comparePdfData),
            email: EmailsId,
            logo: getLogoUrl(),
            enquiryId: enquiry_id,
            subject: "Compare PDF",
            link: window.location.href,
            vehicle_model: userDataHome?.modelName,
            rto: userDataHome?.rtoNumber,
            regNumber: userDataHome?.regNo,
            previos_policy_expiry_date: userDataHome?.expiry || "NEW",
            fuel_type: userDataHome?.fuel,
            productName: comparePdfData?.policy_type,
            reg_date: userDataHome?.regDate,
          })
        );
        setMsg(true);
        setTimeout(() => {
          onClose(false);
          setMsg(false);
        }, 2500);
      } else if (MobileNo) {
        dispatch(
          Whatsapp({
            userType: token ? "pos" : "employee",
            firstName:
              userDataHome?.firstName ||
              temp_data?.firstName ||
              userDataHome?.userProposal?.additonalData?.owner?.firstName ||
              "Customer",
            lastName:
              userDataHome?.lastName ||
              temp_data?.lastName ||
              userDataHome?.userProposal?.additonalData?.owner?.lastName ||
              " ",
            to: `91${MobileNo}`,
            enquiryId: enquiry_id,
            url: window.location.href,
            data: JSON.stringify(comparePdfData),
            type: getType(loc[2]),
            link:
              temp_data?.journeyStage?.stage === "Proposal Accepted" ||
              temp_data?.journeyStage?.stage === "Payment Initiated"
                ? `${window.location.href}&dropout=${Encrypt(
                    true
                  )}&icr=${Encrypt(true)}`
                : window.location.href,
          })
        );
        setWhatsappSucess(true);
        setMsg(true);
        setTimeout(() => {
          onClose(false);
          setMsg(false);
        }, 2500);
      }
    } else if (loc[1] === "payment-success") {
      if (typeCheck !== 2) {
        dispatch(
          ShareQuote({
            userType: token ? "pos" : "employee",
            name:
              (userDataHome?.firstName || temp_data?.firstName || "Customer") +
              " " +
              (userDataHome?.lastName || temp_data?.lastName || " "),
            email: EmailsId,
            logo: getLogoUrl(),
            enquiryId: enquiry_id,
            subject: "Policy PDF",
            link: window.location.href,
            downloadUrl: policy?.pdfUrl,
            notificationType: sendAll
              ? "all"
              : typeCheck === 1
              ? "sms"
              : "email",
            domain: `http://${window.location.hostname}`,
            type: getType(loc[1]),
          })
        );
        setMsg(true);
        setTimeout(() => {
          onClose(false);
          setMsg(false);
        }, 2500);
      } else if (MobileNo && typeCheck === 2) {
        dispatch(
          Whatsapp({
            userType: token ? "pos" : "employee",
            name:
              (userDataHome?.firstName || temp_data?.firstName || "Customer") +
              " " +
              (userDataHome?.lastName || temp_data?.lastName || " "),
            email: EmailsId,
            logo: getLogoUrl(),
            enquiryId: enquiry_id,
            subject: "Policy PDF",
            link: window.location.href,
            downloadUrl: policy?.pdfUrl,
            domain: `http://${window.location.hostname}`,
            type: getType(loc[1]),
          })
        );
        setWhatsappSucess(true);
        setMsg(true);
        setTimeout(() => {
          onClose(false);
          setMsg(false);
        }, 2500);
      }
    } else {
      if (MobileNo && typeCheck === 2) {
        dispatch(
          Whatsapp({
            userType: token ? "pos" : "employee",
            firstName:
              userDataHome?.firstName ||
              temp_data?.firstName ||
              userDataHome?.userProposal?.additonalData?.owner?.firstName ||
              "Customer",
            lastName:
              userDataHome?.lastName ||
              temp_data?.lastName ||
              userDataHome?.userProposal?.additonalData?.owner?.lastName ||
              " ",
            to: `91${MobileNo}`,
            type: premiumBreakuppdf,
            enquiryId: enquiry_id,
            url: window.location.href,
            data: sendPdf,
            link:
              temp_data?.journeyStage?.stage === "Proposal Accepted" ||
              temp_data?.journeyStage?.stage === "Payment Initiated"
                ? `${window.location.href}&dropout=${Encrypt(
                    true
                  )}&icr=${Encrypt(true)}`
                : window.location.href,
          })
        );
        setWhatsappSucess(true);
        setMsg(true);
        setTimeout(() => {
          onClose(false);
          setMsg(false);
        }, 2500);
      } else if (EmailsId) {
        dispatch(
          EmailPdf({
            userType: token ? "pos" : "employee",
            name:
              (userDataHome?.firstName || temp_data?.firstName || "Customer") +
              " " +
              (userDataHome?.lastName || temp_data?.lastName || " "),
            data: sendPdf,
            email: EmailsId,
            logo: getLogoUrl(),
            enquiryId: enquiry_id,
            subject: "Premium Breakup",
            link:
              temp_data?.journeyStage?.stage === "Proposal Accepted" ||
              temp_data?.journeyStage?.stage === "Payment Initiated"
                ? `${window.location.href}&dropout=${Encrypt(
                    true
                  )}&icr=${Encrypt(true)}`
                : window.location.href,
            vehicle_model: userDataHome?.modelName,
            rto: userDataHome?.rtoNumber,
            regNumber: userDataHome?.regNo,
            previos_policy_expiry_date: userDataHome?.expiry || "NEW",
            fuel_type: userDataHome?.fuel,
            ...(loc[2] === "quotes" && { quotes: quoteList }),
            productName: finalPremiumlist1[0]?.productName,
            reg_date: userDataHome?.regDate,
          })
        );
        setMsg(true);
        setTimeout(() => {
          onClose(false);
          setMsg(false);
        }, 2500);
      }
    }
  };

  const [err, setErr] = useState("");
  let validateEmail;

  useEffect(() => {
    validateEmail =
      // eslint-disable-next-line react-hooks/exhaustive-deps
      !_.isEmpty(EmailsId) &&
      (typeof EmailsId === "string" ? [EmailsId] : EmailsId)?.map((email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
          ? 1
          : 0;
      });

    validateEmail &&
    (typeof EmailsId === "string" ? "" : validateEmail.includes(0))
      ? setErr("Invalid Input")
      : setErr("");
  }, [EmailsId]);

  //OnEmail Pdf success /Error
  useEffect(() => {
    if (emailPdf) {
      setMsg(true);
    }

    return () => {
      dispatch(setEmailPdf("emailPdf"));
    };
  }, [emailPdf]);

  //	onMailComparePdf
  useEffect(() => {
    if (
      emailComparePdf &&
      loc[2] === "compare-quote" &&
      emailComparePdf !== "emailPdf"
    ) {
      setMsg(true);
    }

    return () => {
      dispatch(setEmailComparePdf("emailPdf"));
    };
  }, [emailComparePdf]);

  //---drawer for mobile

  const [drawer, setDrawer] = useState(false);

  useEffect(() => {
    if (lessthan767 && show) {
      setTimeout(() => {
        setDrawer(true);
      }, 50);
    }
  }, [show]);

  const [active, setActive] = useState("all");

  const [selectedQuotes, setSelectedQuotes] = useState([]);

  //options for multiselect.
  const options = !_.isEmpty(finalPremiumlist1)
    ? _.map(finalPremiumlist1).map((x) => ({
        name: `${x?.name}, Premium: ${
          x?.finalPremium * 1 ? Math.round(x?.finalPremium) : 0
        }`,
        id: x?.name,
        label: `${x?.name}, Premium: ${
          x?.finalPremium * 1 ? Math.round(x?.finalPremium) : 0
        }`,
        value: x?.name,
        idv: x?.idv,
        logo: x?.logo,
        finalPremiumNoGst: x?.finalPremiumNoGst,
        finalPremium: x?.finalPremium,
        gst: x?.gst,
        action: window.location.href,
        productName: x?.productName,
        policyId: x?.policyId,
      }))
    : [];

  const options2 = !_.isEmpty(finalPremiumlist1) ? finalPremiumlist1 : [];

  //prefill
  useEffect(() => {
    // (userDataHome?.emailId ||
    //   userDataHome?.userProposal?.additonalData?.owner?.email ||
    //   temp_data?.userProposal?.additonalData?.owner?.email) &&
    //   setValue(
    //     "emailId",
    //     userDataHome?.emailId
    //       ? userDataHome?.emailId
    //       : userDataHome?.userProposal?.additonalData?.owner?.email
    //       ? userDataHome?.userProposal?.additonalData?.owner?.email
    //       : temp_data?.userProposal?.additonalData?.owner?.email
    //   );
    (userDataHome?.mobileNo ||
      userDataHome?.userProposal?.additonalData?.owner?.mobileNumber ||
      temp_data?.userProposal?.additonalData?.owner?.mobileNumber) &&
      setValue(
        "mobileNo",
        userDataHome?.mobileNo
          ? userDataHome?.mobileNo
          : userDataHome?.userProposal?.additonalData?.owner?.mobileNumber
          ? userDataHome?.userProposal?.additonalData?.owner?.mobileNumber
          : temp_data?.userProposal?.additonalData?.owner?.mobileNumber
      );
  }, [userDataHome]);

  const content2 = (
    <MessageContainer>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="#4fcc6e"
        width="48px"
        height="48px"
      >
        <path d="M0 0h24v24H0z" fill="none"></path>
        <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>
      </svg>
      <FlexDiv>
        <LaxmiWrapper>
          <Laxmi
            src={
              TypeReturn(type) !== "bike"
                ? `${
                    process.env.REACT_APP_BASENAME !== "NA"
                      ? `/${process.env.REACT_APP_BASENAME}`
                      : ""
                  }/assets/images/new-car.svg`
                : `${
                    process.env.REACT_APP_BASENAME !== "NA"
                      ? `/${process.env.REACT_APP_BASENAME}`
                      : ""
                  }/assets/images/vehicle/bike3.png`
            }
            alt="Laxmi"
          />
        </LaxmiWrapper>
        <Wrapper>
          <Text>
            Thank you! Your{" "}
            {loc[2] === "proposal-page"
              ? "proposal"
              : loc[2] === "payment-confirmation"
              ? "payment link"
              : !sendPdf
              ? "quotes"
              : "pdf"}{" "}
            has been sent through
            {whatsappSucess
              ? " whatsapp"
              : notificationType === 1
              ? " SMS"
              : " Email"}{" "}
            successfully
            {/* on{" "}
						{notificationType === 1 ? userData?.mobileNo : userData?.emailId} */}
          </Text>
        </Wrapper>
      </FlexDiv>
    </MessageContainer>
  );

  const [selectAll, setSelectAll] = useState(false);

  const checkItem = (item) => {
    let allItems = [...selectedItems, item];
    setSelectedItems(allItems);
  };

  const removeItem = (item) => {
    let allItems = selectedItems.filter((x) => x.policyId !== item.policyId);
    setSelectedItems(allItems);
  };

  const selectAllItems = () => {
    let allItems = options2;
    setSelectedItems(allItems);
    setSelectAll(true);
  };

  const removeAllItems = () => {
    let allItems = [];
    setSelectedItems(allItems);
    setSelectAll(false);
  };

  useEffect(() => {
    (options.length && selectedItems.length) !== 0 &&
    options.length === selectedItems.length
      ? setSelectAll(true)
      : setSelectAll(false);
  }, [selectedItems]);

  const content = (
    <>
      <MainWrapper>
        <div className="sendQuotes">
          <Wrapper>
            <div className="stepwizard" style={{ display: "none" }}>
              <div className="stepwizard-row setup-panel">
                <div className="stepwizard-step">
                  <a
                    type="button"
                    className={`btn btn-circle btn-default ${
                      step === 1 ? "btn-primary" : ""
                    }`}
                    onClick={() => {
                      setStep(1);
                    }}
                  >
                    1
                  </a>
                  <p>Select the plans</p>
                </div>
                <div className="stepwizard-step">
                  <a
                    type="button"
                    className={`btn btn-circle btn-default ${
                      step === 2 ? "btn-primary" : ""
                    }`}
                    //	onClick={() => setStep(2)}
                  >
                    2
                  </a>
                  <p>Share with</p>
                </div>
              </div>
            </div>

            {/* <CarWrapper>
							<Laxmi src="/assets/images/auto-car.jpg" alt="car" />
						</CarWrapper> */}
            {/* <ShareHeader>
							Share{" "}
							{loc[2] === "proposal-page"
								? "Proposal"
								: loc[2] === "payment-confirmation"
								? "payment"
								: "quotes"}
						</ShareHeader> */}

            <div
              className="contact__imgbox"
              style={{ display: lessthan600 && step === 1 && "none" }}
            >
              <ContactImg
                src={
                  TypeReturn(type) !== "bike"
                    ? `${
                        process.env.REACT_APP_BASENAME !== "NA"
                          ? `/${process.env.REACT_APP_BASENAME}`
                          : ""
                      }/assets/images/new-car.jpg`
                    : `${
                        process.env.REACT_APP_BASENAME !== "NA"
                          ? `/${process.env.REACT_APP_BASENAME}`
                          : ""
                      }/assets/images/vehicle/bike2.png`
                }
                alt="Lakshmi"
              ></ContactImg>
            </div>
            <ContactText
              style={{ display: lessthan600 && step === 1 && "none" }}
            >
              {!sendPdf && shareQuotesFromToaster !== true ? (
                <p
                  style={{
                    fontSize: lessthan576 ? "0.9rem" : "",
                  }}
                >
                  Hi, please choose the way you wish to share the{" "}
                  {loc[2] === "proposal-page"
                    ? "proposal"
                    : loc[2] === "payment-confirmation"
                    ? "payment"
                    : "quotes"}
                </p>
              ) : shareQuotesFromToaster ? (
                <p>Hi, Please share mobile number of customer. </p>
              ) : (
                <p>
                  Please enter your Email Ids{" "}
                  {(process.env.REACT_APP_BROKER === "ACE" ||
                    process.env.REACT_APP_BROKER === "RB") &&
                    "or Mobile No."}{" "}
                  to share PDF.
                </p>
              )}
            </ContactText>
            {loc[2] === "quotes" && !shareQuotesFromToaster && !sendPdf && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "30px",
                  width: "100%",
                }}
              >
                <ShareButton1
                  active={active}
                  onClick={() => setActive("all")}
                  className="share-all-quote"
                >
                  Share All Quotes
                </ShareButton1>
                <ShareButton2
                  active={active}
                  onClick={() => setActive("custom")}
                  className="share-custom-quote"
                >
                  Share Custom Quotes
                </ShareButton2>
              </div>
            )}
            {active === "custom" && (
              <Row style={{ textAlign: "left", marginBottom: "26px" }}>
                <Col lg="10" md="10" sm="10" xs="10">
                  <Controller
                    control={control}
                    name="customQuotes"
                    // defaultValue={temp_data?.fuel}
                    render={({ onChange, onBlur, value, name }) => (
                      <MultiSelect
                        // quotes
                        // knowMore
                        name={name}
                        onChange={onChange}
                        ref={register}
                        value={value}
                        onBlur={onBlur}
                        isMulti={true}
                        options={options}
                        isClearable={false}
                        placeholder={"Select IC"}
                        // errors={errors.brand}
                        Styled
                        closeOnSelect={false}
                        // }}
                        rto
                        // customSearch
                        // noBorder
                        // stepperSelect={true}
                        shareQuote
                      />
                    )}
                  />
                </Col>
                <Col lg="2" md="2" sm="2" xs="2" style={{ padding: "0" }}>
                  <p
                    style={{
                      fontSize: lessthan767 ? "12px" : "14px",
                      marginTop: "10px",
                    }}
                  >
                    {" "}
                    {customQuotes && customQuotes?.length > 0
                      ? customQuotes?.length
                      : "0"}{" "}
                    quotes{" "}
                  </p>
                </Col>
              </Row>
            )}

            {step === 1 ? (
              <>
                <div className="tableConatiner" style={{ height: "200px" }}>
                  <div
                    className="sendQuoteTable"
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr 1fr",
                      textAlign: "center",
                      background: "#cdced1",
                      padding: "5px 0px",
                    }}
                  >
                    <div className="insurer-sendquote">Insurer</div>
                    <div className="cover-sendquote">IDV</div>
                    {/* <div className="type-sendquote">Type</div> */}
                    {/* <div>
                      Select Insurer
                    </div> */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      All
                      {selectAll ? (
                        <i
                          style={{
                            background: "green",
                            color: "#fff",
                            // marginTop: "15px",
                            width: "15px",
                            height: "15px",
                            fontSize: "10px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                            marginLeft: "5px",
                          }}
                          onClick={removeAllItems}
                          className="fa fa-check"
                        ></i>
                      ) : (
                        <div
                          onClick={selectAllItems}
                          style={{
                            height: "15px",
                            width: "15px",
                            border: "1px solid",
                            cursor: "pointer",
                            marginLeft: "5px",
                          }}
                        ></div>
                      )}
                      <div></div>
                    </div>
                  </div>

                  <div>
                    {options?.map((item, index) => (
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr 1fr",
                          borderBottom: "1px solid black",
                          padding: "5px",
                          marginTop: "10px",
                          textAlign: "center",
                        }}
                      >
                        <div style={{ paddingLeft: "0px" }}>
                          <img
                            className="img_w_s"
                            src={item?.logo}
                            style={{ width: "50px" }}
                            alt="item logo"
                          />
                        </div>
                        <div>
                          {" "}
                          ₹
                          {item?.idv && Number(item?.idv) !== 0 ? (
                            // numberToDigitWord(Number(item?.idv), 1000, 2)
                            currencyFormater(Number(item?.idv))
                          ) : (
                            <Badge
                              variant="danger"
                              style={{
                                cursor: "pointer",
                                marginLeft: "10px",
                                marginBottom: "5px",
                              }}
                            >
                              Not Applicable
                            </Badge>
                          )}
                        </div>
                        {/* <div>{item?.policyType}</div> */}
                        {/* <div>Comprehensive</div> */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-evenly",
                          }}
                        >
                          {selectedItems
                            .map((x) => x.policyId)
                            .includes(item?.policyId) ? (
                            <i
                              style={{
                                background: "green",
                                color: "#fff",
                                // marginTop: "15px",
                                width: "15px",
                                height: "15px",
                                fontSize: "10px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                              onClick={() => removeItem(item)}
                              className="fa fa-check"
                            ></i>
                          ) : (
                            <div
                              onClick={() => checkItem(item)}
                              style={{
                                height: "15px",
                                width: "15px",
                                border: "1px solid",
                                cursor: "pointer",
                              }}
                            ></div>
                          )}
                          {/* <span className="group-check check-share-quote">
                              <input
                                type="checkbox"
                                className="round-check"
                                id={`sendQuote${index}`}
                                name={`checkmarkSend[${index}]`}
                                ref={register}
                                value={item?.policyId}
                                defaultValue={item?.policyId}
                                defaultChecked={
                                  selectedList?.includes(String(item?.policyId))
                                    ? true
                                    : false
                                }
                              />
                              <label
                                className="round-label"
                                htmlFor={`sendQuote${index}`}
                              ></label>
                            </span> */}
                        </div>
                        <div></div>
                      </div>
                    ))}
                    {/* </tbody> */}
                  </div>
                </div>
                <div>
                  <ConfirmButton
                    onClick={() => {
                      setStep(2);
                      setSelectedList(val);
                    }}
                  >
                    Send quotes
                  </ConfirmButton>
                </div>
              </>
            ) : (
              <Form>
                {/* <Col sm="12" md="6" lg="6" xl="6">
										<div className="w-100">
											<Textbox
												lg
												type="text"
												id="firstName"
												fieldName="First Name"
												name="firstName"
												placeholder=" "
												register={register}
												error={errors?.firstName}
											/>
											{!!errors.firstName && (
												<Error style={{ marginTop: "-20px" }}>
													{errors.firstName.message}
												</Error>
											)}
										</div>
									</Col>
									<Col sm="12" md="6" lg="6" xl="6">
										<div className="w-100">
											<Textbox
												lg
												type="text"
												id="lastName"
												fieldName="Last Name"
												name="lastName"
												placeholder=" "
												register={register}
												error={errors?.lastName}
											/>
											{!!errors.lastName && (
												<Error style={{ marginTop: "-20px" }}>
													{errors.lastName.message}
												</Error>
											)}
										</div>
									</Col> */}
                <Row>
                  {((!sendPdf && process.env.REACT_APP_BROKER !== "CP") ||
                    (sendPdf &&
                      (process.env.REACT_APP_BROKER === "ACE" ||
                        process.env.REACT_APP_BROKER === "RB"))) && (
                    <>
                      <Col
                        sm="10"
                        md="10"
                        lg="10"
                        xl="10"
                        xs={lessthan576 && !sendPdf ? 8 : 10}
                      >
                        <div className="w-100">
                          <Textbox
                            nonCircular
                            lg
                            type="tel"
                            id="mobileNo"
                            fieldName="Mobile No."
                            name="mobileNo"
                            placeholder=" "
                            register={register}
                            error={errors?.mobileNo}
                            maxLength="10"
                            fontWeight="bold"
                            // defaultValue={
                            //   userDataHome?.mobileNo
                            //     ? userDataHome?.mobileNo
                            //     : userDataHome?.userProposal?.additonalData
                            //         ?.owner?.mobileNumber
                            //     ? userDataHome?.userProposal?.additonalData
                            //         ?.owner?.mobileNumber
                            //     : temp_data?.userProposal?.additonalData?.owner
                            //         ?.mobileNumber
                            // }
                            //onKeyDown={numOnlyWithCopyPaste}
                            onInput={(e) =>
                              (e.target.value = e.target.value.replace(
                                /[^0-9-/]/g,
                                ""
                              ))
                            }
                            isEmail
                          />
                          {!!errors.mobileNo && (
                            <Error style={{ marginTop: "-20px" }}>
                              {errors.mobileNo.message}
                            </Error>
                          )}
                        </div>
                      </Col>

                      {lessthan576 ? (
                        <>
                          {/* // <Col xs={2} style={{ display: 'flex', justifyContent: 'center' }}> */}
                          {process.env.REACT_APP_BROKER !== "SRIYAH" &&
                          process.env.REACT_APP_BROKER !== "BAJAJ" ? (
                            <Col xs={2} style={{ paddingLeft: "2px" }}>
                              <Btn
                                type="button"
                                variant="success"
                                disabled={!MobileNo || MobileNo?.length < 10}
                                onClick={() => onSubmit(MobileNo, 2)}
                                style={{
                                  position: "relative",
                                  right: "0px",
                                  height: "50px",
                                  width: "50px",
                                  cursor:
                                    (!MobileNo || MobileNo?.length < 10) &&
                                    "not-allowed",
                                  ...(process.env.REACT_APP_BROKER === "RB" && {
                                    background: "#F27F21",
                                    borderColor: "#F27F21",
                                  }),
                                }}
                              >
                                <WhatsAppIcon style={{ color: "#000" }} />
                              </Btn>
                            </Col>
                          ) : (
                            <noscript />
                          )}
                          {shareQuotesFromToaster !== true &&
                          !sendPdf &&
                          (process.env.REACT_APP_BROKER !== "OLA" ||
                            loc[2] === "compare-quote") ? (
                            <Col xs={2}>
                              <Btn
                                type="submit"
                                variant="warning"
                                disabled={!MobileNo || MobileNo?.length < 10}
                                onClick={() => onSubmit(MobileNo, 1)}
                                style={{
                                  position: "relative",
                                  right:
                                    process.env.REACT_APP_BROKER !== "SRIYAH" &&
                                    process.env.REACT_APP_BROKER !== "BAJAJ"
                                      ? "0px"
                                      : "15px",
                                  height: "50px",
                                  width: "50px",
                                  cursor:
                                    (!MobileNo || MobileNo?.length < 10) &&
                                    "not-allowed",
                                  ...(process.env.REACT_APP_BROKER === "RB" && {
                                    background: "#F27F21",
                                    borderColor: "#F27F21",
                                  }),
                                }}
                              >
                                <MessageIcon style={{ color: "#000" }} />
                              </Btn>
                            </Col>
                          ) : (
                            <noscript />
                          )}
                        </>
                      ) : (
                        <>
                          {process.env.REACT_APP_BROKER !== "BAJAJ" &&
                          process.env.REACT_APP_BROKER !== "SRIYAH" ? (
                            <Col sm="1" md="1" lg="1" xl="1" xs={2}>
                              <Btn
                                type="button"
                                variant="success"
                                disabled={!MobileNo || MobileNo?.length < 10}
                                onClick={() => onSubmit(MobileNo, 2)}
                                style={{
                                  position: "relative",
                                  right: "15px",
                                  height: "50px",
                                  width: "50px",
                                  cursor:
                                    (!MobileNo || MobileNo?.length < 10) &&
                                    "not-allowed",
                                  ...(process.env.REACT_APP_BROKER === "RB" && {
                                    background: "#F27F21",
                                    borderColor: "#F27F21",
                                  }),
                                }}
                              >
                                <WhatsAppIcon style={{ color: "#000" }} />
                              </Btn>
                            </Col>
                          ) : (
                            <noscript />
                          )}
                          {shareQuotesFromToaster !== true &&
                          !sendPdf &&
                          (process.env.REACT_APP_BROKER !== "OLA" ||
                            loc[2] === "compare-quote") ? (
                            <Col sm="1" md="1" lg="1" xl="1" xs={2}>
                              <Btn
                                type="submit"
                                variant="warning"
                                disabled={!MobileNo || MobileNo?.length < 10}
                                onClick={() => onSubmit(MobileNo, 1)}
                                style={{
                                  position: "relative",
                                  right:
                                    process.env.REACT_APP_BROKER !== "SRIYAH" &&
                                    process.env.REACT_APP_BROKER !== "BAJAJ"
                                      ? "0px"
                                      : "15px",
                                  height: "50px",
                                  width: "50px",
                                  cursor:
                                    (!MobileNo || MobileNo?.length < 10) &&
                                    "not-allowed",
                                  ...(process.env.REACT_APP_BROKER === "RB" && {
                                    background: "#F27F21",
                                    borderColor: "#F27F21",
                                  }),
                                }}
                              >
                                <MessageIcon style={{ color: "#000" }} />
                              </Btn>
                            </Col>
                          ) : (
                            <noscript />
                          )}
                        </>
                      )}
                    </>
                  )}
                  <Col sm="1" md="1" lg="1" xl="1" xs={2}>
                    {loc[2] === "payment-confirmation" && (
                      <Btn
                        type="submit"
                        variant="warning"
                        disabled={!MobileNo || MobileNo?.length < 10}
                        onClick={() => onSubmit(MobileNo, 1)}
                        style={{
                          height: "50px",
                          width: "50px",
                          cursor:
                            (!MobileNo || MobileNo?.length < 10) &&
                            "not-allowed",
                          ...(process.env.REACT_APP_BROKER === "RB" && {
                            background: "#F27F21",
                            borderColor: "#F27F21",
                          }),
                        }}
                      >
                        <MessageIcon style={{ color: "#000" }} />
                      </Btn>
                    )}
                  </Col>
                </Row>
                {shareQuotesFromToaster !== true &&
                  (process.env.REACT_APP_BROKER !== "OLA" ||
                    loc[2] === "compare-quote") && (
                    <Row>
                      <Col
                        sm="10"
                        md="10"
                        lg="10"
                        xl="10"
                        xs={lessthan576 && !sendPdf ? 8 : 10}
                        style={{}}
                      >
                        <div className="w-100">
                          {/* <SearchInput
                            options={[]}
                            allowNew={true}
                            newSelectionPrefix={"Add new Email: "}
                            Controller={Controller}
                            control={control}
                            register={register}
                            name="emailId"
                            fieldName="Email"
                            placeholder="Email"
                            multiple={"multiple"}
                            isEmail
                            selected={
                              EmailsId ||
                              userDataHome?.emailId ||
                              temp_data?.emailId
                            }
                          />
                          { (typeof EmailsId === "string" || validateEmail?.length === 0) ? (
                            <Error style={{ marginTop: "10px" }}>Add this email from the dropdown</Error> 
                            
                          ) : (<text></text>) }
                          {err && (
                            <Error style={{ marginTop: "10px" }}>{err}</Error>
                          )} */}

                          <MultiEmail
                            register={register}
                            setValue={setValue}
                            setDisEmail={setDisEmail}
                            prefill={
                              userDataHome?.emailId ||
                              userDataHome?.userProposal?.additonalData?.owner
                                ?.email ||
                              temp_data?.userProposal?.additonalData?.owner
                                ?.email
                            }
                          />

                          {/* <ReactEmails
                            register={register}
                            setValue={setValue}
                            prefill={
                              userDataHome?.emailId ||
                              userDataHome?.userProposal?.additonalData?.owner
                                ?.email ||
                              temp_data?.userProposal?.additonalData?.owner
                                ?.email
                            }
                          /> */}
                        </div>
                      </Col>
                      <Col
                        sm="1"
                        md="1"
                        lg="1"
                        xl="1"
                        xs={lessthan576 ? 2 : 2}
                        style={{ paddingLeft: lessthan576 ? "0" : "" }}
                      >
                        <Btn
                          type="submit"
                          variant="primary"
                          disabled={disEmail}
                          onClick={handleSubmit(onSubmit)}
                          style={{
                            height: "50px",
                            width: "50px",
                            position: "relative",
                            right: lessthan576 ? "0" : "15px",
                            cursor: disEmail && "not-allowed",
                            ...(process.env.REACT_APP_BROKER === "RB" && {
                              background: "#F27F21",
                              borderColor: "#F27F21",
                            }),
                          }}
                        >
                          {!customLoad ? (
                            <MailOutlineOutlinedIcon
                              style={{ color: "#000" }}
                            />
                          ) : (
                            <Spinner
                              animation="border"
                              variant="light"
                              size="sm"
                            />
                            // <CheckCircleOutlineIcon
                            // style={{color: "#00FF00"}}
                            // />
                          )}
                        </Btn>
                      </Col>
                      {/* send all */}
                      {!sendPdf && process.env.REACT_APP_BROKER !== "CP" ? (
                        <Col
                          sm="1"
                          md="1"
                          lg="1"
                          xl="1"
                          xs={lessthan576 ? 2 : 2}
                          style={{ paddingLeft: lessthan576 ? "0" : "" }}
                        >
                          <Btn
                            type="submit"
                            variant="secondary"
                            disabled={disEmail || !MobileNo}
                            onClick={() => {
                              onSubmit(MobileNo, 1, true);
                            }}
                            style={{
                              height: "50px",
                              width: "50px",
                              left: lessthan576 ? "15px" : "0",
                              position: "relative",
                              cursor: (disEmail || !MobileNo) && "not-allowed",
                              ...(process.env.REACT_APP_BROKER === "RB" && {
                                background: "#F27F21",
                                borderColor: "#F27F21",
                                color: "#000",
                                fontWeight: "bold",
                              }),
                            }}
                          >
                            {!customLoad && "ALL"}
                          </Btn>
                        </Col>
                      ) : (
                        <noscript />
                      )}
                    </Row>
                  )}
                {/* <Row>
										<Col sm="10"
											md="10"
											lg="10"
											xl="10"
											xs="10">
												<QuoteList />
										</Col>
									</Row> */}
                {/* <Col
										sm="12"
										md="12"
										lg="12"
										xl="12"
										className="d-flex justify-content-center mt-3 flex-wrap"
									>
										<Button
											className="m-1 d-flex"
											buttonStyle="outline-solid"
											hex1={Theme ? Theme?.Header?.hex1 : "#bdd400"}
											hex2={Theme ? Theme?.Header?.hex1 : "#bdd400"}
											borderRadius={"20px"}
											shadow={"none"}
											type="button"
											disabled={!MobileNo}
											onClick={() => onSubmit(MobileNo, 2)}
											style={{ padding: "10px 15px", width: "46%" }}
										>
											<img
												className="m-1"
												src={"/assets/images/whatsapp.svg"}
												alt="whtsapp"
												height="20"
											/>
											<p className="m-1">Share Via Whatsapp</p>
										</Button>
									</Col> */}
              </Form>
            )}
          </Wrapper>
        </div>
      </MainWrapper>
      <Content2>
        <p>
          <span>*</span>
          {shareQuotesFromToaster
            ? "Customer will receive message on shared WhatsApp number."
            : "Please note that the premium may vary in future."}
        </p>
      </Content2>
    </>
  );

  return !lessthan767 ? (
    <div>
      <Popup
        height={msg ? "240px" : "auto"}
        width="640px"
        show={show}
        onClose={onClose}
        content={msg ? content2 : content}
        position="middle"
        zIndexPopup={true}
        outside={sendPdf ? true : false}
        hiddenClose={customLoad ? true : false}
      ></Popup>
    </div>
  ) : (
    <>
      <React.Fragment key={"bottom"} style={{ borderRadius: "5% 5% 0% 0%" }}>
        <Drawer
          anchor={"bottom"}
          open={drawer}
          style={{ zIndex: "1000000000" }}
          onClose={() => {
            setDrawer(false);
            onClose(false);
            setSendPdf(false);
          }}
          onOpen={() => {
            setDrawer(true);
          }}
          ModalProps={{
            keepMounted: true,
            disableEscapeKeyDown: true,
          }}
          onBackdropClick={false}
        >
          <MobileDrawerBody>
            <CloseButton
              onClick={() => {
                setDrawer(false);
                onClose(false);
                setSendPdf(false);
              }}
            >
              <svg
                version="1.1"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                style={{ height: " 25px" }}
              >
                <path
                  fill={"#000"}
                  d="M12,2c-5.53,0 -10,4.47 -10,10c0,5.53 4.47,10 10,10c5.53,0 10,-4.47 10,-10c0,-5.53 -4.47,-10 -10,-10Zm5,13.59l-1.41,1.41l-3.59,-3.59l-3.59,3.59l-1.41,-1.41l3.59,-3.59l-3.59,-3.59l1.41,-1.41l3.59,3.59l3.59,-3.59l1.41,1.41l-3.59,3.59l3.59,3.59Z"
                ></path>
                <path fill="none" d="M0,0h24v24h-24Z"></path>
              </svg>
            </CloseButton>

            {msg ? content2 : content}
          </MobileDrawerBody>
        </Drawer>
      </React.Fragment>

      <GlobalStyle disabledBackdrop={false} />
    </>
  );
};

export default SendQuotes;

const GlobalStyle = createGlobalStyle`
body {
	.MuiDrawer-paperAnchorBottom {
		border-radius: 3% 3% 0px 0px;
		z-index: 999999999 !important;
	}
	.css-1u2w381-MuiModal-root-MuiDrawer-root {
    z-index: 1000000 !important;
  }
	.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
    pointer-events: ${({ disabledBackdrop }) =>
      disabledBackdrop ? "none !important" : ""};
  }
}
`;

const LaxmiWrapper = styled.div`
  float: left;
  margin-right: 28px;
  // @media (max-width: 500px) {
  // 	margin-right: 0;
  // }
`;

const CarWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Laxmi = styled.img`
  height: 100px;
  width: 100px;
  border-radius: 20px;
  box-shadow: 0px 4px 13px rgba(41, 41, 41, 0.35);
  border: ${({ theme }) => theme.Header?.borderLogo || "2.5px solid #bdd400"};
  background: ${({ theme }) => theme.links?.color || "#bdd400"};
`;

const MainWrapper = styled.div`
  margin: 40px 0 20px;
  padding: 0px 20px;
  display: flex;
  justify-content: center;

  flex-wrap: wrap;

  .tableConatiner {
    max-height: 600px;
    overflow: auto;
    margin-top: 10px;
    // margin-bottom: 40px;
    padding: 10px;
    & td {
      border: none;
    }
    & th {
      border: none;
    }
    & tr {
      border-bottom: 0.3px solid #bdd400;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
  }

  // @media (max-width: 500px) {
  // 	margin: 50px 0;
  // 	padding: 0 2px;
  // }
`;

const ShareButton1 = styled.button`
  border: ${({ theme }) =>
    theme.floatButton?.floatBorder || "1px solid #bdd400"};
  background: ${({ active, theme }) =>
    active === "all" ? theme.floatButton?.floatColor : "#fff"};
  color: ${({ active, theme }) => (active === "all" ? "#fff" : "#000")};
  font-size: 13px;
  padding: 6px;
  border-radius: 4px;
`;
const ShareButton2 = styled.button`
  border: ${({ theme }) =>
    theme.floatButton?.floatBorder || "1px solid #bdd400"};
  background: ${({ active, theme }) =>
    active === "custom" ? theme.floatButton?.floatColor : "#fff"};
  color: ${({ active, theme }) => (active === "custom" ? "#fff" : "#000")};
  font-size: 13px;
  padding: 6px;
  border-radius: 4px;
`;

const Wrapper = styled.div`
  width: 100%;
  & > div {
    // text-align: center;
    margin-top: 10px;
    position: relative;
    // top: 20px;
  }
  // @media (max-width: 500px) {
  // 	margin-top: 20px;
  // }
`;

const Text = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: #666666;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  & strong {
    color: #000000;
    font-weight: 700;
  }
`;

const ConfirmButton = styled.button`
  height: 47px;

  width: 220px;
  margin-top: 6px;
  background-color: ${({ theme }) => theme.Header?.color || "#bdd400"};
  border: ${({ theme }) => theme.Header?.border || "1px solid #bdd400"};
  color: #000 !important;

  font-family: ${({ theme }) =>
    theme.QuoteBorderAndFont?.fontFamilyBold || "Inter-SemiBold"};
  font-size: 12px;
  line-height: 40px;
  border-radius: 50px;
  margin-left: 0;
  outline: none;
  &:focus {
    outline: none;
  }
  // @media (max-width: 500px) {
  // 	margin: auto;
  // }
`;

const Content2 = styled.div`
  & p {
    color: #000000;
    font-family: "basier_squaremedium";
    font-size: 14px;
    font-weight: 500;
    margin: 0;
  }
  height: 20%;
  padding: 23px 0;
  background-color: #f1f2f6;
  text-align: center;
`;

const MessageContainer = styled.div`
  padding: 10px;
  & svg {
    margin: 0 auto;
    width: 100%;
  }
`;

const FlexDiv = styled.div`
  padding-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 60px;
  @media only screen and (max-width: 600) {
    margin: 0px 0px;
  }
`;

const TableWrap = styled.div`
  padding-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const TableLogo = styled.div`
  width: 50px;
`;

const ShareHeader = styled.div`
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${({ theme }) =>
    theme.QuoteBorderAndFont?.fontFamilyBold || "Inter-SemiBold"};
  margin-bottom: 20px;
`;
const ContactImg = styled.img`
  float: left;
  margin-right: 10px;
  height: 70px;
`;

const ContactText = styled.div`
  padding: 1rem;
  font-weight: 400;
  font-family: basier_squareregular;
  font-size: 16px;
  margin-bottom: 20px;
  color: #111;
`;

const MobileDrawerBody = styled.div`
  width: 100%;
  border-radius: 3px 3px 0px 0px;
`;
const CloseButton = styled.div`
  display: ${({ hiddenClose }) => (hiddenClose ? "none" : "block")};
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 1111;
  &:hover {
    text-decoration: none;
    color: #363636;
  }
`;
