import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Button, Loader } from "components";
import { useHistory, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { reloadPage } from "utils";
import _ from "lodash";
import ThemeObj from "modules/theme-config/theme-config";
import SecureLS from "secure-ls";
import styled from "styled-components";
import { Url, Prefill, clear } from "modules/proposal/proposal.slice";
import swal from "sweetalert";

const ls = new SecureLS();
const ThemeLS = ls.get("themeData");
const Theme = !_.isEmpty(ThemeLS) && ThemeLS ? ThemeLS : ThemeObj;

const JourneyFailure = () => {
  const dispatch = useDispatch();
  const { temp_data, error } = useSelector((state) => state.proposal);
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const enquiry_id = query.get("enquiry_id");
  const errMsg = query.get("msg");

  const UrlFn = (ut) => {
    switch (process.env?.REACT_APP_BROKER) {
      case "OLA":
        return `https://ola-dashboard.fynity.in/${
          ut ? "employee" : "pos"
        }/login`;
      case "UNILIGHT":
        return `https://www.policyongo.com/${ut ? "employee" : "pos"}/login`;
      case "CP":
        return `https://www.comparepolicy.com/`;
      case "FYNTUNE":
        return "";
      case "ABIBL":
        if (
          process.env?.REACT_APP_API_BASE_URL ===
          "https://apiabibl-preprod-carbike.fynity.in/api"
        ) {
          return `http://preprod-dasbhoard-abibl.fynity.in/${
            ut ? "employee" : "pos"
          }/login`;
        } else if (
          process.env?.REACT_APP_API_BASE_URL ===
          "https://apiabibl-carbike.fynity.in/api"
        ) {
          return `http://uat-dasbhoard-abibl.fynity.in/${
            ut ? "employee" : "pos"
          }/login`;
        } else {
          return `http://abibl-prod-dashboard.fynity.in/${
            ut ? "employee" : "pos"
          }/login`;
        }
      case "GRAM":
        if (
          process.env?.REACT_APP_API_BASE_URL ===
          "https://apigramcover-carbike.fynity.in/api"
        ) {
          return `http://uat-dasbhoard-gramcover.fynity.in/${
            ut ? "employee" : "pos"
          }/login`;
        } else {
          return `https://dashboard.gramcover.com/${
            ut ? "employee" : "pos"
          }/login`;
        }
      case "ACE":
        return "https://crm.aceinsurance.com:5555/";
      case "SRIYAH":
        return process.env.REACT_APP_PROD === "YES"
          ? "https://nammacover.com/"
          : "https://uat.nammacover.com/";
      case "PINC":
        return process.env.REACT_APP_PROD === "YES"
          ? "https://live.pincnews.co.in/"
          : "https://dev.pincnews.co.in/";
      case "EPOCH":
        return `http://online.epochbrokers.com:8092/${
          ut ? "employee" : "customer"
        }/login`;
      case "RB":
        return "https://www.renewbuy.com/";
      case "SPA":
        return `https://uatdashboard.insuringall.com/${
          ut ? "employee" : "pos"
        }/login`;
      case "BIMA":
        return ``;
      case "BAJAJ":
        return `https://www.bajajcapitalinsurance.com/`;
      case "UIB":
        return ``;
      default:
        return `https://ola-dashboard.fynity.in/${
          ut ? "employee" : "pos"
        }/login`;
    }
  };

  window.Android && window.Android.SendToPaymentFailPage("Payment failed");

  const ContentFn = () => {
    switch (process.env?.REACT_APP_BROKER) {
      case "OLA":
        return (
          <>
            In case of any challenges, please contact us at
            <b> insurance@olacabs.com</b> or call us at our number
            <b> 7829-41-1222</b>
          </>
        );
      case "UNILIGHT":
        return (
          <>
            In case of any challenges, please contact us at
            <b> Support@policyongo.com</b> or call us at our number
            <b> 1800-210-1225</b>
          </>
        );
      case "CP":
        return (
          <>
            In case of any challenges, please contact us at
            <b> help@comparepolicy.com</b> or call us at our number
            <b> 1800-12000-0055</b>
          </>
        );
      case "FYNTUNE":
        return (
          <>
            In case of any challenges, please contact us at
            <b> help@fyntune.com</b> or call us at our number
            <b> 1800-12000-0065</b>
          </>
        );
      case "ABIBL":
        return (
          <>
            In case of any challenges, please contact us at
            <b> Support@abibl.com</b> or call us at our number
            <b> 1800 270 7000</b>
          </>
        );
      case "GRAM":
        return (
          <>
            In case of any challenges, please contact us at
            <b> info@gramcover.com</b> or call us at our number
            <b> +91 9311672463</b>
          </>
        );
      case "ACE":
        return (
          <>
            In case of any challenges, please contact us at
            <b> operations@aceinsurance.com</b> or call us at our number
            <b> +918750881122</b>
          </>
        );
      case "SRIYAH":
        return (
          <>
            In case of any challenges, please contact us at
            <b> care@nammacover.com</b> or call us at our number
            <b> 1800 203 0504</b>
          </>
        );
      case "PINC":
        return (
          <>
            In case of any further requirements, please contact us at
            <b> info@pincinsure.com</b> or call us at our number
            <b> 18002672670 </b>
          </>
        );
      case "EPOCH":
        return (
          <>
            In case of any further requirements, please contact us at
            <b> help@fyntune.com</b> or call us at our number
            <b> 1800-12000-0065</b>
          </>
        );
      case "RB":
        return (
          <>
            In case of any further requirements, please contact us at
            <b> customersupport@renewbuy.com</b> or call us at our number
            <b> 18004197852</b>
          </>
        );
      case "SPA":
        return (
          <>
            In case of any further requirements, please contact us at
            <b> care@insuringall.com</b> or call us at our number
            <b> +91-11-45675555</b>
          </>
        );
      case "BIMA":
        return (
          <>
            In case of any further requirements, please contact us at
            <b> contact@bimaplanner.com</b> or call us at our number
            <b> 9999366494</b>
          </>
        );
      case "ANALAH":
        return (
          <>
            In case of any further requirements, please contact us at
            <b> contactus@analahinsurance.com</b> or call us at our number
            <b> +91 9920878181</b>
          </>
        );
      case "BAJAJ":
        return (
          <>
            In case of any further requirements, please contact us at
            <b> info@bajajcapitalinsurance.com</b> or call us at our number
            <b> 1800 212 123123</b>
          </>
        );
      case "UIB":
        return (
          <>
            In case of any further requirements, please contact us at
            <b> services@uibindia.com</b> or call us at our number
            <b>+91 79820 39210</b>
          </>
        );
      default:
        return (
          <>
            In case of any challenges, please contact us at
            <b> insurance@olacabs.com</b> or call us at our number
            <b> 7829-41-1222</b>
          </>
        );
    }
  };

  useEffect(() => {
    if (error) {
      swal(
        "Error",
        `${`Trace ID:- ${enquiry_id}.\n Error Message:- ${error}`}`,
        "error"
      );
    }
    return () => {
      dispatch(clear());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  //load prefill data
  useEffect(() => {
    if (enquiry_id) dispatch(Prefill({ enquiryId: enquiry_id }, true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enquiry_id]);

  return !_.isEmpty(temp_data) ? (
    <Row className="text-center w-100 mx-auto" style={{}}>
      <Top className="mx-auto" style={{ width: "50%" }}>
        <div className="mt-4 d-flex justify-content-center w-100">
          <img
            src={`${
              process.env.REACT_APP_BASENAME !== "NA"
                ? `/${process.env.REACT_APP_BASENAME}`
                : ""
            }/assets/images/remove.png`}
            alt="errImg"
            height="100"
            width="100"
            className="failure_image"
          />
        </div>
        <div className="mt-4 d-flex flex-column justify-content-center w-100">
          <h4
            className="text-center w-100 text-danger font-weight-bold oops_text"
            style={{ fontSize: "2.3rem" }}
          >
            Oops!
          </h4>
          <h4 className="text-center w-100 text-danger font-weight-bold transaction_text">
            {errMsg ? errMsg : "Your transaction was unsuccessful!"}
          </h4>
        </div>
        <div className="mt-4 d-flex flex-column justify-content-center w-100">
          <p
            className="text-center w-100 error_text"
            style={{ fontSize: "1.1rem", color: "red" }}
          >
            Process could not be completed, please ensure the information you
            provided is Correct.
          </p>
          <p
            className="text-center w-100 content_text"
            style={{ fontSize: "1.1rem" }}
          >
            {ContentFn()}
          </p>
        </div>
        {((process.env.REACT_APP_BROKER === "OLA" &&
          temp_data?.corporateVehiclesQuoteRequest?.journeyType ===
            "driver-app") ||
          process.env.REACT_APP_BROKER !== "OLA") && (
          <div className="mt-2 d-flex justify-content-center w-100">
            <Button
              buttonStyle="outline-solid"
              hex1={
                Theme?.proposalProceedBtn?.hex1
                  ? Theme?.proposalProceedBtn?.hex1
                  : "#4ca729"
              }
              hex2={
                Theme?.proposalProceedBtn?.hex2
                  ? Theme?.proposalProceedBtn?.hex2
                  : "#4ca729"
              }
              borderRadius={
                Theme?.QuoteBorderAndFont?.borderRadius
                  ? Theme?.QuoteBorderAndFont?.borderRadius
                  : "25px"
              }
              type="submit"
              shadow={"none"}
              onClick={() => [
                process.env.REACT_APP_BROKER === "OLA" &&
                temp_data?.corporateVehiclesQuoteRequest?.journeyType ===
                  "driver-app"
                  ? reloadPage(
                      process.env.REACT_APP_API_BASE_URL ===
                        "https://api-ola-uat.fynity.in/api"
                        ? "https://auth-repose-azure.stg.corp.olacabs.com/olamoney/kyc-web/wallet/driver/crosssell-dashboard"
                        : "https://supply-api.olacabs.com/crosssell-dashboard"
                    )
                  : _.isEmpty(temp_data?.agentDetails) ||
                    process.env.REACT_APP_BROKER === "RB"
                  ? reloadPage(
                      `${window.location.origin}${
                        process.env.REACT_APP_BASENAME !== "NA"
                          ? `/${process.env.REACT_APP_BASENAME}`
                          : ``
                      }/${
                        Number(temp_data?.productSubTypeId) === 1
                          ? "car"
                          : Number(temp_data?.productSubTypeId) === 2
                          ? "bike"
                          : "cv"
                      }/lead-page`
                    )
                  : //checking where to redirect
                  temp_data?.agentDetails?.find((o) => o?.sellerType === "E")
                  ? reloadPage(UrlFn("E"))
                  : reloadPage(UrlFn()),
                window.Android &&
                  window.Android.SendToHomePage("Redirecting to homepage"),
              ]}
            >
              Go To Homepage
            </Button>
          </div>
        )}
      </Top>
    </Row>
  ) : (
    <Loader />
  );
};

const Top = styled.div`
  font-family: ${({ theme }) => theme.Payment?.fontFamily || ""};
  font-weight: ${({ theme }) => theme.Payment?.fontWeight || ""};
  @media (max-width: 767px) {
    width: 100% !important;
    padding: 0 30px;
    .failure_image {
      height: 50px;
      width: 50px;
    }
    .oops_text {
      font-size: 1.3rem !important;
    }
    .transaction_text {
      font-size: 1rem !important;
    }
    .error_text {
      font-size: 0.9rem !important;
    }
    .content_text {
      font-size: 0.8rem !important;
    }
  }
`;

export default JourneyFailure;
