import React, { useEffect } from "react";
import swal from "sweetalert";
import { Col, Row } from "react-bootstrap";
import { Loader, FloatButton } from "components";
import { useLocation, useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Container, FormContainer, Avatar } from "./style";
import { clear, Prefill, LinkTrigger } from "./home.slice";
import {
  Registration,
  CarDetails,
  LeadPage,
  VehicleType,
  JourneyType,
} from "./steps";
import _ from "lodash";
import { reloadPage, RedirectFn } from "utils";
import { useMediaPredicate } from "react-media-hook";
import RenewalRegistration from "modules/Home/steps/Renewal/RenewalRegistration";
import { TypeReturn } from "modules/type";

export const Home = (props) => {
  const dispatch = useDispatch();
  const { error, temp_data } = useSelector((state) => state.home);
  const { typeAccess } = useSelector((state) => state.login);

  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(location.search);

  const enquiry_id = query.get("enquiry_id");
  const token = query.get("token") || localStorage?.SSO_user_motor;
  const typeId = query.get("typeid");
  const stepperfill = query.get("stepperfill");
  const journey_type = query.get("journey_type");
  const key = query.get("key");
  const savedStep = query.get("stepNo");
  const isPartner = query.get("is_partner");
  const reg_no_url = query.get("reg_no");
  const policy_no_url = query.get("policy_no");
  const { type } = props?.match?.params;

  const lessthan767 = useMediaPredicate("(max-width: 767px)");
  const lessthan400 = useMediaPredicate("(max-width: 400px)");
  const lessthan330 = useMediaPredicate("(max-width: 330px)");

  //IOS check.
  let userAgent = navigator.userAgent;
  let isMobileIOS = false; //initiate as false
  // device detection
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream && lessthan767) {
    isMobileIOS = true;
  }

  //Link-Click & Delivery
  useEffect(() => {
    key && dispatch(LinkTrigger({ key: key }));
  }, [key]);

  //---------------Temp B2C block-------------------------
  useEffect(() => {
    if (
      process.env?.REACT_APP_BROKER === "GRAM" &&
      !token &&
      process.env.REACT_APP_PROD === "YES"
    ) {
      if (process.env?.REACT_APP_BROKER === "GRAM") {
        swal("Access Control Error. User login required.").then(() =>
          reloadPage("https://dashboard.gramcover.com/")
        );
      }
    }

    if (
      process.env?.REACT_APP_BROKER === "OLA" &&
      !token &&
      !journey_type &&
      temp_data?.corporateVehiclesQuoteRequest?.journeyType !== "embeded-excel"
    ) {
      swal("Access Control Error. User login required.").then(() =>
        reloadPage(RedirectFn())
      );
    }
  }, [token]);

  const backgroundSplash = (url) => {
    switch (url) {
      case `/${type}/lead-page`:
        return `linear-gradient(to bottom, transparent, #fff)
						,url(${
              process.env.REACT_APP_BASENAME !== "NA"
                ? `/${process.env.REACT_APP_BASENAME}`
                : ""
            }/assets/images/background-green5-min.jpg)`;
      case `/${type}/journey-type`:
        return `linear-gradient(to bottom, transparent, #fff)
						,url(${
              process.env.REACT_APP_BASENAME !== "NA"
                ? `/${process.env.REACT_APP_BASENAME}`
                : ""
            }/assets/images/background-green5-min.jpg)`;
      case `/${type}/vehicle-type`:
        return `linear-gradient(to bottom, transparent, #fff)
						,url(${
              process.env.REACT_APP_BASENAME !== "NA"
                ? `/${process.env.REACT_APP_BASENAME}`
                : ""
            }/assets/images/background-green5-min.jpg)`;
      case `/${type}/registration`:
        return `linear-gradient(to bottom, transparent, #fff)
						,url(${
              process.env.REACT_APP_BASENAME !== "NA"
                ? `/${process.env.REACT_APP_BASENAME}`
                : ""
            }/assets/images/background-green5-min.jpg)`;
      case `/${type}/renewal`:
        return `linear-gradient(to bottom, transparent, #fff)
						,url(${
              process.env.REACT_APP_BASENAME !== "NA"
                ? `/${process.env.REACT_APP_BASENAME}`
                : ""
            }/assets/images/background-green5-min.jpg)`;
      case `/${type}/vehicle-details`:
        return `linear-gradient(to bottom, transparent, #fff)
						,url(${
              process.env.REACT_APP_BASENAME !== "NA"
                ? `/${process.env.REACT_APP_BASENAME}`
                : ""
            }/assets/images/background-green5-min.jpg)`;
      default:
        return "";
    }
  };

  const SplashPos = (url) => {
    switch (url) {
      case `/${type}/lead-page`:
        return "top right";
      case `/${type}/journey-type`:
        return "top";
      case `/${type}/registration`:
        return "top";
      case `/${type}/renewal`:
        return "top";
      case `/${type}/vehicle-type`:
        return "top";
      case `/${type}/vehicle-details`:
        return "top";
      default:
        return "";
    }
  };

  //Access-Control
  useEffect(() => {
    if (!_.isEmpty(typeAccess)) {
      if (TypeReturn(type)) {
        const AccessControl = !_.isEmpty(typeAccess)
          ? _.compact(
              typeAccess.map((item) =>
                item?.product_sub_type_code
                  ? item?.product_sub_type_code.toLowerCase()
                  : null
              )
            )
          : [];
        let typeRt = TypeReturn(type) === "cv" ? "pcv" : TypeReturn(type);
        let typeRt1 = TypeReturn(type) === "cv" ? "gcv" : TypeReturn(type);
        if (
          !(AccessControl.includes(typeRt) || AccessControl.includes(typeRt1))
        ) {
          swal(
            "Error",
            "Access Control Error. Please contact the administrator for clearance.",
            "error",
            { closeOnClickOutside: false }
          ).then(() => history.replace("/"));
        }
      }
    }
  }, [typeAccess]);

  //check enquiry
  useEffect(() => {
    if (location.pathname !== `/${type}/lead-page`) {
      if (temp_data?.enquiry_id || (enquiry_id && enquiry_id !== "null")) {
      } else {
        swal("Info", "Enquiry id not found, redirecting to homepage", "info", {
          closeOnClickOutside: false,
        }).then(() => history.replace(`/${type}/lead-page`));
      }
    }

    //Redirection after breakin submission
    if (
      temp_data?.userProposal?.isBreakinCase === "Y" ||
      temp_data?.userProposal?.isBreakinCase === "y"
    ) {
      swal("Info", "Breakin policy already generated.", "info", {
        closeOnClickOutside: false,
      }).then(() =>
        token ? reloadPage(RedirectFn()) : history.replace(`/${type}/lead-page`)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temp_data]);

  //Prefill Api
  useEffect(() => {
    if (enquiry_id) dispatch(Prefill({ enquiryId: enquiry_id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enquiry_id]);

  //onError
  useEffect(() => {
    if (error) {
      swal(
        "Error",
        enquiry_id
          ? `${`Trace ID:- ${enquiry_id}.\n Error Message:- ${error}`}`
          : error,
        "error"
      );
    }
    return () => {
      dispatch(clear());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <>
      <Container
        url={backgroundSplash(location.pathname)}
        pos={SplashPos(location.pathname)}
      >
        <FormContainer
          width={location.pathname === `/${type}/vehicle-type` ? "780px" : ""}
        >
          {
            <Row>
              {process.env.REACT_APP_BROKER !== "ABIBL" && !lessthan767 ? (
                <Col className="landing-laxmi mx-auto" xl={3} lg={3} md={3}>
                  <div className="review-details3 text-center">
                    <Avatar
                      src={
                        TypeReturn(type) !== "bike"
                          ? `${
                              process.env.REACT_APP_BASENAME !== "NA"
                                ? `/${process.env.REACT_APP_BASENAME}`
                                : ""
                            }/assets/images/taxi-car1.png`
                          : `${
                              process.env.REACT_APP_BASENAME !== "NA"
                                ? `/${process.env.REACT_APP_BASENAME}`
                                : ""
                            }/assets/images/vehicle/bike3.png`
                      }
                      alt="avatarImage"
                    />
                  </div>
                </Col>
              ) : (
                <noscript />
              )}
            </Row>
          }
          {location.pathname === `/${type}/lead-page` && (
            <LeadPage
              type={type}
              lessthan767={lessthan767}
              TypeReturn={TypeReturn}
              isPartner={isPartner}
            />
          )}
          {location.pathname === `/${type}/journey-type` && (
            <JourneyType
              enquiry_id={enquiry_id}
              type={type}
              token={token}
              errorProp={error}
              typeId={typeId}
              TypeReturn={TypeReturn}
            />
          )}
          {location.pathname === `/${type}/registration` && (
            <Registration
              enquiry_id={enquiry_id}
              type={type}
              token={token}
              errorProp={error}
              lessthan767={lessthan767}
              lessthan400={lessthan400}
              lessthan330={lessthan330}
              typeId={typeId}
              isMobileIOS={isMobileIOS}
              journey_type={journey_type}
              TypeReturn={TypeReturn}
              isPartner={isPartner}
              reg_no_url={reg_no_url}
            />
          )}
          {location.pathname === `/${type}/renewal` && (
            <RenewalRegistration
              enquiry_id={enquiry_id}
              type={type}
              token={token}
              errorProp={error}
              lessthan767={lessthan767}
              lessthan400={lessthan400}
              lessthan330={lessthan330}
              typeId={typeId}
              isMobileIOS={isMobileIOS}
              journey_type={journey_type}
              TypeReturn={TypeReturn}
              isPartner={isPartner}
              policy_no_url={policy_no_url}
              reg_no_url={reg_no_url}
            />
          )}
          {location.pathname === `/${type}/vehicle-type` && (
            <VehicleType
              enquiry_id={enquiry_id}
              type={type}
              token={token}
              errorProp={error}
              typeId={typeId}
              lessthan767={lessthan767}
              isMobileIOS={isMobileIOS}
              journey_type={journey_type}
              TypeReturn={TypeReturn}
              isPartner={isPartner}
            />
          )}
          {location.pathname === `/${type}/vehicle-details` && (
            <CarDetails
              enquiry_id={enquiry_id}
              type={type}
              token={token}
              errorProp={error}
              typeId={typeId}
              stepperfill={stepperfill}
              isMobileIOS={isMobileIOS}
              journey_type={journey_type}
              savedStep={savedStep}
              TypeReturn={TypeReturn}
              isPartner={isPartner}
            />
          )}
        </FormContainer>
      </Container>
      <FloatButton />
    </>
  );
};
