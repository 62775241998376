import React, { useState, useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CompactCard as Card, Button } from "components";
import {
  scrollToTargetAdjusted,
  getLogoCvType,
  reloadPage,
  Encrypt,
  scrollToTop,
} from "utils";
import { Button as Btn, Row, Col, Spinner, Modal } from "react-bootstrap";
import { Label, ShiftingLabel, SubmitDiv } from "./style.js";
import "./bootstrapMod.scss";
import "react-datepicker/dist/react-datepicker.css";
import _ from "lodash";
import { useHistory } from "react-router";
import swal from "sweetalert";
import moment from "moment";
import { Save, clear, SubmitData, Lead, OTP } from "./proposal.slice";
import { ShareQuote, clear as clr } from "../../modules/Home/home.slice";
import { Whatsapp, SaveAddonsData } from "modules/quotesPage/quote.slice";
import { useMediaPredicate } from "react-media-hook";
/*---modals---*/
import OTPPopup from "./otp";
import PaymentModal from "./payment-modal";
import PreSubmit from "./pre-submit";
/*---theme---*/
import ThemeObj from "modules/theme-config/theme-config";
import SecureLS from "secure-ls";
/*---cards---*/
import OwnerCard from "./cards/owner-card";
import NomineeCard from "./cards/nominee-card";
import VehicleCard from "./cards/vehicle-card";
import PolicyCard from "./cards/policy-card";
/*---summary---*/
import SummaryOwner from "./summary/summary-owner";
import SummaryProposal from "./summary/summary-proposal";
import SummaryVehicle from "./summary/summary-vehicle";
import { TypeReturn } from "modules/type.js";

const FormSection = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const lessthan768 = useMediaPredicate("(max-width: 768px)");
  const lessthan376 = useMediaPredicate("(max-width: 376px)");

  const ls = new SecureLS();
  const ThemeLS = ls.get("themeData");
  const Theme = !_.isEmpty(ThemeLS) && ThemeLS ? ThemeLS : ThemeObj;

  const { error, submit, submitProcess, otp, verifyOtp } = useSelector(
    (state) => state.proposal
  );
  const { share } = useSelector((state) => state.home);
  const { cpaSet } = useSelector((state) => state.quotes);
  //modals.
  const [show, setShow] = useState(false); //otp modal
  const [show1, setShow1] = useState(false); // payment confirmation modal
  const [show2, setShow2] = useState(false); // presubmit conditions modal
  //fetched data
  const TempData = !_.isEmpty(props?.temp) ? props?.temp : {};
  //temp card data
  const CardData = !_.isEmpty(TempData)
    ? TempData?.userProposal?.additonalData
      ? TempData?.userProposal?.additonalData
      : {}
    : {};
  const Additional = !_.isEmpty(props?.Additional) ? props?.Additional : {};
  const OwnerPA = !_.isEmpty(Additional?.compulsoryPersonalAccident)
    ? Additional?.compulsoryPersonalAccident?.filter(
        ({ name }) => name === "Compulsory Personal Accident"
      )
    : [];
  const Tenure = !_.isEmpty(Additional?.compulsoryPersonalAccident)
    ? Additional?.compulsoryPersonalAccident?.filter(({ tenure }) => tenure)
    : [];
  const ReasonPA = !_.isEmpty(Additional?.compulsoryPersonalAccident)
    ? Additional?.compulsoryPersonalAccident?.filter(
        ({ reason }) => reason && reason !== "cpa not applicable to company"
      )
    : [];
  //IC's / Brokers / journey which requires nominee data in all cases
  const NomineeBroker =
    TempData?.selectedQuote?.companyAlias === "iffco_tokio" ||
    (TempData?.selectedQuote?.companyAlias === "hdfc_ergo" && false) ||
    "" ||
    (TempData?.selectedQuote?.companyAlias === "royal_sundaram" &&
      TempData?.corporateVehiclesQuoteRequest?.policyType !== "own_damage") ||
    TempData?.selectedQuote?.companyAlias === "edelweiss";
  //condition check for when to display CPA on the nominee card.
  //config
  const configCon =
    props?.fields.includes("cpaOptIn") &&
    TempData?.corporateVehiclesQuoteRequest?.policyType !== "own_damage";
  const conditionChk =
    !_.isEmpty(OwnerPA) || NomineeBroker || configCon ? true : false;
  //CPA check
  const PACondition =
    !_.isEmpty(ReasonPA) &&
    TempData?.corporateVehiclesQuoteRequest?.vehicleOwnerType !== "C" &&
    TempData?.corporateVehiclesQuoteRequest?.policyType !== "own_damage"
      ? true
      : false;
  //previous policy details check
  const PolicyCon =
    //when previous insurer is present and not new
    (TempData?.corporateVehiclesQuoteRequest?.previousInsurer &&
      TempData?.corporateVehiclesQuoteRequest?.previousInsurer !== "NEW" &&
      //incase of adrila hard coding
      TempData?.selectedQuote?.businessType !== "New Business" &&
      TempData?.corporateVehiclesQuoteRequest?.previousPolicyType !==
        "Not sure") ||
    //when third party is selected and business type is not new & previous policy type is not sure
    (TempData?.selectedQuote?.policyType === "Third Party" &&
      TempData?.selectedQuote?.businessType !== "New Business" &&
      TempData?.corporateVehiclesQuoteRequest?.previousPolicyType !==
        "Not sure") ||
    //breakin over 90 days
    (!TempData?.corporateVehiclesQuoteRequest?.previousInsurer &&
      TempData?.corporateVehiclesQuoteRequest?.businessType === "breakin") ||
    //when previos policy type is TP and current policy type is comprehensive / not sure
    TempData?.corporateVehiclesQuoteRequest?.previousPolicyType ===
      "Third-party" ||
    //when previous policy expiry is not sure but previous policy type is Own Damage.
    (TempData?.corporateVehiclesQuoteRequest?.previousPolicyType ===
      "Not sure" &&
      TempData?.corporateVehiclesQuoteRequest?.policyType === "own_damage");
  //excluding validations of policy details in these cases
  const PolicyValidationExculsion =
    //when previous policy expiry is not sure but previous policy type is Own Damage, only TP validations are required
    TempData?.corporateVehiclesQuoteRequest?.previousPolicyType ===
      "Not sure" &&
    TempData?.corporateVehiclesQuoteRequest?.policyType === "own_damage";

  //Is NCB Applicable?
  const isNcbApplicable =
    TempData?.corporateVehiclesQuoteRequest?.previousPolicyType ===
      "Third-party" || TempData?.selectedQuote?.policyType === "Third Party";

  //open to function - used in summary page.
  const removeOpenTo = () => {
    var queryUrl = window.location.search.substring(1);

    // is there anything there ?
    if (queryUrl.length) {
      // are the new history methods available ?
      if (
        window.history != undefined &&
        window.history.pushState != undefined
      ) {
        // if pushstate exists, add a new state to the history, this changes the url without reloading the page
        const newurl =
          window.location.protocol +
          "//" +
          window.location.host +
          window.location.pathname +
          `?enquiry_id=${props?.enquiry_id}${
            props?.token ? `&token=${props?.token}` : ``
          }${props?.typeId ? `&typeid=${props?.typeId}` : ``}${
            props?.journey_type ? `&journey_type=${props?.journey_type}` : ``
          }`;
        window.history.pushState({ path: newurl }, "", newurl);
      }
    }
  };
  /*------------- Dropout-status -----------------*/
  const [dropout, setDropout] = useState(false);
  useEffect(() => {
    if (props.dropout || props.breakinCase) {
      setDropout(true);
    }
  }, [props.dropout, props.breakinCase]);
  /*------x------ Dropout-status -------x---------*/

  /*---------------------form data--------------------*/
  //saving the proposal card data in state

  const [review, setReview] = useState({});
  const [owner, setOwner] = useState({});
  const [nominee, setNominee] = useState({});
  const [vehicle, setVehicle] = useState({});
  const [prepolicy, setPrepolicy] = useState({});
  /*-----------------x---form data-x------------------*/

  /*-----------------Switchers (form/summary) ------------------------*/
  const [formOwner, setFormOwner] = useState("form");
  const [formNominee, setFormNominee] = useState("hidden");
  const [formVehicle, setFormVehicle] = useState("hidden");
  const [formPrepolicy, setFormPrepolicy] = useState("hidden");
  /*-----------------Switchers section end--------------------------*/

  /*--------------------form switchers----------------------------*/
  useEffect(() => {
    if (Number(TempData?.ownerTypeId) === 2 || !conditionChk) {
      if (formOwner === "form") {
        setFormVehicle("hidden");
        setFormPrepolicy("hidden");
      }
      if (formVehicle === "form") {
        setFormPrepolicy("hidden");
      }
    } else {
      if (formOwner === "form") {
        setFormNominee("hidden");
        setFormVehicle("hidden");
        setFormPrepolicy("hidden");
      }
      if (formNominee === "form") {
        setFormVehicle("hidden");
        setFormPrepolicy("hidden");
      }
      if (formVehicle === "form") {
        setFormPrepolicy("hidden");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formOwner, formNominee, formVehicle, formPrepolicy]);
  /*---------x----------form switchers----------------x-----------*/

  /*--------------------form onSubmits----------------------------*/

  //owner
  const onSubmitOwner = (data) => {
    setOwner(data);
    let OwnerRequest = {
      ...data,
      stage: "1",
      street: "street",
      ownerType: Number(TempData?.ownerTypeId) === 1 ? "I" : "C",
      businessType: TempData?.corporateVehiclesQuoteRequest?.businessType,
      productType: TempData?.corporateVehiclesQuoteRequest?.policyType,
      icName: TempData?.selectedQuote?.companyName,
      icId: TempData?.selectedQuote?.insuraneCompanyId,
      idv: TempData?.quoteLog?.idv,
      userProductJourneyId: props.enquiry_id,
      officeEmail: data?.email,
      ...{
        sellerType: TempData?.quoteAgent?.sellerType
          ? TempData?.quoteAgent?.sellerType
          : null,
      },
      ...(props?.token && {
        agentId: TempData?.quoteAgent?.agentId,
        agentName: TempData?.quoteAgent?.agentName,
        agentMobile: TempData?.quoteAgent?.agentMobile,
        agentEmail: TempData?.quoteAgent?.agentEmail,
        token: props?.token,
      }),
      additionalDetails: { review: CardData?.review, owner: { ...data } },
    };
    dispatch(Save(OwnerRequest));
    // remove openTo
    if (props?.openTo === "owner") {
      removeOpenTo();
    }
  };

  //switch(owner -> nominee)
  useEffect(() => {
    if (Number(TempData?.ownerTypeId) === 2 || !conditionChk) {
      if (!_.isEmpty(owner)) {
        setFormOwner("summary");
        setFormVehicle("form");
      }
    } else {
      if (!_.isEmpty(owner)) {
        setFormOwner("summary");
        setFormNominee("form");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [owner]);

  //nominee
  const onSubmitNominee = (data) => {
    setNominee(data);
    let NomineeRequest = {
      ...data,
      stage: "2",
      userProductJourneyId: props.enquiry_id,
      ownerType: Number(TempData?.ownerTypeId) === 1 ? "I" : "C",
      ...(props?.token && { token: props?.token }),
      additionalDetails: {
        review: CardData?.review,
        owner: owner,
        nominee: { ...data },
      },
    };
    dispatch(Save(NomineeRequest));
  };

  //switch(nominee -> vehicle)
  useEffect(() => {
    if (!_.isEmpty(nominee)) {
      setFormNominee("summary");
      setFormVehicle("form");
    }
  }, [nominee]);

  //vehicle
  const onSubmitVehicle = (data) => {
    setVehicle(data);
    let VehicleRequest = {
      ...data,
      ...(data?.hypothecationCity && {
        financerLocation: data?.hypothecationCity,
      }),
      stage: "3",
      ownerType: Number(TempData?.ownerTypeId) === 1 ? "I" : "C",
      userProductJourneyId: props.enquiry_id,
      ...(props?.token && { token: props?.token }),
      additionalDetails: {
        owner: owner,
        nominee: nominee,
        vehicle: { ...data },
      },
    };
    dispatch(Save(VehicleRequest));
    // remove openTo
    if (props?.openTo === "vehicle") {
      removeOpenTo();
    }
  };

  //switch(vehicle -> pre-policy)
  useEffect(() => {
    if (!_.isEmpty(vehicle)) {
      setFormVehicle("summary");
      setFormPrepolicy("form");
    }
  }, [vehicle]);

  //pre-policy
  const onSubmitPrepolicy = (data) => {
    setPrepolicy(data);
    let PrepolicyRequest = {
      ...data,
      stage: "4",
      ownerType: Number(TempData?.ownerTypeId) === 1 ? "I" : "C",
      userProductJourneyId: props.enquiry_id,
      ...(props?.token && { token: props?.token }),
      additionalDetails: {
        review: CardData?.review,
        owner: owner,
        nominee: nominee,
        vehicle: vehicle,
        prepolicy: { ...data },
      },
    };
    dispatch(Save(PrepolicyRequest));
  };

  //switch(pre-policy -> review & submit)
  useEffect(() => {
    if (!_.isEmpty(prepolicy)) {
      setFormPrepolicy("summary");
    }
  }, [prepolicy]);
  /*---------x----------form onSubmits----------------x-----------*/

  /*--------------------Review & Submit Section End-------------------*/
  const [finalSubmit, setFinalSubmit] = useState(true);
  const nomineeCheckCon =
    (PACondition && props?.fields.includes("cpaOptOut")) ||
    "" ||
    PolicyCon ||
    "";
  const finalSubmitCheck = [
    formOwner,
    Number(TempData?.ownerTypeId) !== 2 && conditionChk && formNominee,
    formVehicle,
    nomineeCheckCon && formPrepolicy,
  ];
  useEffect(() => {
    if (_.compact(finalSubmitCheck).every((elem) => elem === "summary")) {
      setFinalSubmit(true);
    } else {
      setFinalSubmit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formOwner, formNominee, formVehicle, formPrepolicy]);

  const PolicyId = TempData?.selectedQuote?.policyId || "";

  const companyAlias = !_.isEmpty(TempData?.selectedQuote)
    ? TempData?.selectedQuote?.companyAlias
    : "";

  const onFinalSubmit = () => {
    if (PolicyId && companyAlias && props?.enquiry_id) {
      navigator && navigator?.vibrate && navigator.vibrate([100, 0, 50]);
      console.log("submitted");
      if (
        TempData?.userProposal?.isInspectionDone === "Y" &&
        props?.TypeReturn(props?.type) !== "bike"
      ) {
        setShow1(true);
      } else {
        //pre-submit conditions here
        //Magma iib
        if (
          Number(TempData?.quoteLog?.icId) === 41 &&
          TempData?.userProposal?.iibresponserequired === "Y"
        ) {
          //when proposal is previously submitted.
          if (
            ["Payment Initiated", "Payment Failed"].includes(
              TempData?.journeyStage?.stage
            )
          ) {
            //opening payment modal.
            setShow1(true);
          } else {
            setShow2(true);
          }
        }
        // journey without presubmit conditions
        else {
          //when proposal is previously submitted.
          if (
            ["Payment Initiated", "Payment Failed"].includes(
              TempData?.journeyStage?.stage
            )
          ) {
            //opening payment modal.
            setShow1(true);
          } else {
            dispatch(
              SubmitData(
                {
                  policyId: PolicyId,
                  companyAlias: companyAlias,
                  userProductJourneyId: props?.enquiry_id,
                  enquiryId: props?.enquiry_id,
                  ...(TempData?.selectedQuote?.isRenewal === "Y" && {
                    is_renewal: "Y",
                  }),
                },
                props?.TypeReturn(props?.type)
              )
            );
          }
          //proposal submit
        }
      }
    } else {
      swal(
        "Error",
        `${`Trace ID:- ${
          props?.enquiry_id
        }.\n Error Message:- ${"Server Error"}`}`,
        "error"
      );
    }
  };
  /*--------------Pre-Submit--------------*/
  //Magma - iib
  const selection = (action) => {
    dispatch(
      SubmitData(
        {
          policyId: PolicyId,
          companyAlias: companyAlias,
          userProductJourneyId: props?.enquiry_id,
          enquiryId: props?.enquiry_id,
          iibncb: action ? true : false,
        },
        props?.TypeReturn(props?.type)
      )
    );
  };
  /*-----x--------Pre-Submit--------x-----*/

  /*--------------OTP--------------*/
  //Payment
  const payment = () => {
    if (props?.enquiry_id) {
      if (
        Number(TempData?.quoteLog?.icId) !== 32 &&
        Number(TempData?.quoteLog?.icId) !== 35 &&
        process.env.REACT_APP_BROKER !== "RB"
      ) {
        !_.isEmpty(TempData?.agentDetails) &&
        !_.isEmpty(
          TempData?.agentDetails.find((item) => item?.category === "Essone")
        )
          ? swal({
              title: "Confirm Action",
              text: `Are you sure you want to make the payment?`,
              icon: "info",
              buttons: {
                cancel: "Cancel",
                catch: {
                  text: "Confirm",
                  value: "confirm",
                },
              },
              dangerMode: true,
            }).then((caseValue) => {
              switch (caseValue) {
                case "confirm":
                  history.push(
                    `/${props?.type}/payment-gateway?enquiry_id=${
                      props?.enquiry_id
                    }${props?.token ? `&token=${props?.token}` : ``}${
                      TempData?.userProposal?.isBreakinCase
                        ? `&breakin=${Encrypt(true)}`
                        : ``
                    }${props?.icr ? `&icr=${props?.icr}` : ``}`
                  );
                  break;
                default:
              }
            })
          : history.push(
              `/${props?.type}/payment-gateway?enquiry_id=${props?.enquiry_id}${
                props?.token ? `&token=${props?.token}` : ``
              }${props?.typeId ? `&typeid=${props?.typeId}` : ``}${
                TempData?.userProposal?.isBreakinCase
                  ? `&breakin=${Encrypt(true)}`
                  : ``
              }${
                props?.journey_type
                  ? `&journey_type=${props?.journey_type}`
                  : ``
              }${props?.icr ? `&icr=${props?.icr}` : ``}`
            );
      } else {
        setShow1(true);
        dispatch(
          ShareQuote(
            {
              enquiryId: props?.enquiry_id,
              notificationType: "all",
              domain: `http://${window.location.hostname}`,
              type: "otpSms",
              applicableNcb:
                TempData?.corporateVehiclesQuoteRequest?.applicableNcb,
              mobileNo: CardData?.owner?.mobileNumber,
              policyEndDate: TempData?.selectedQuote?.policyEndDate,
              policyStartDate: TempData?.selectedQuote?.policyStartDate,
              premiumAmount: TempData?.quoteLog?.finalPremiumAmount,
              productName: TempData?.selectedQuote?.productName,
              registrationNumber: CardData?.vehicle?.vehicaleRegistrationNumber,
              emailId: CardData?.owner?.email,
            },
            true
          )
        );
      }
    } else {
      swal("Error", "No enquiry id found", "error");
    }
  };

  //clear previous success
  useEffect(() => {
    dispatch(clr("share"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (share) {
      setShow(true);
    }

    return () => {
      dispatch(clr("share"));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [share]);

  //on otp enter
  const otpSuccess = () => {
    if (props?.enquiry_id) {
      !_.isEmpty(TempData?.agentDetails) &&
      !_.isEmpty(
        TempData?.agentDetails.find((item) => item?.category === "Essone")
      )
        ? swal({
            title: "Confirm Action",
            text: `Are you sure you want to make the payment?`,
            icon: "info",
            buttons: {
              cancel: "Cancel",
              catch: {
                text: "Confirm",
                value: "confirm",
              },
            },
            dangerMode: true,
          }).then((caseValue) => {
            switch (caseValue) {
              case "confirm":
                history.push(
                  `/${props?.type}/payment-gateway?enquiry_id=${
                    props?.enquiry_id
                  }${props?.token ? `&token=${props?.token}` : ``}${
                    TempData?.userProposal?.isBreakinCase
                      ? `&breakin=${Encrypt(true)}`
                      : ``
                  }${props?.icr ? `&icr=${props?.icr}` : ``}`
                );
                break;
              default:
            }
          })
        : history.push(
            `/${props?.type}/payment-gateway?enquiry_id=${props?.enquiry_id}${
              props?.token ? `&token=${props?.token}` : ``
            }${props?.typeId ? `&typeid=${props?.typeId}` : ``}${
              TempData?.userProposal?.isBreakinCase
                ? `&breakin=${Encrypt(true)}`
                : ``
            }${
              props?.journey_type ? `&journey_type=${props?.journey_type}` : ``
            }${props?.icr ? `&icr=${props?.icr}` : ``}`
          );
    } else {
      swal("Error", "No enquiry id found", "error");
    }
  };

  /*------x-------OTP--------x-----*/

  //onSuccess
  useEffect(() => {
    if (submit) {
      let Data = { ...submit, enquiry_id: props?.enquiry_id };
      // lead trigger after submit & Email trigger
      !["Payment Initiated", "Payment Failed"].includes(
        TempData?.journeyStage?.stage
      ) &&
        (submit?.is_breakin !== "Y" ||
          props?.TypeReturn(props?.type) === "bike") &&
        process.env.REACT_APP_BROKER !== "ACE" &&
        dispatch(
          ShareQuote({
            enquiryId: props?.enquiry_id,
            notificationType: "email",
            domain: `http://${window.location.hostname}`,
            type: "proposalCreated",
            emailId: owner?.email,
            firstName: owner?.firstName,
            lastName: owner?.lastName,
            productName: TempData?.selectedQuote?.productName,
            link: window.location.href.replace(
              /proposal-page/g,
              "proposal-page"
            ),
            logo: props?.getLogoUrl(),
          })
        );
      // process.env.REACT_APP_BROKER === "ACE" &&
      //   dispatch(
      //     ShareQuote({
      //       enquiryId: props?.enquiry_id,
      //       notificationType: "sms",
      //       domain: `http://${window.location.hostname}`,
      //       type: "proposalCreated",
      //       emailId: owner?.email,
      //       firstName: owner?.firstName,
      //       lastName: owner?.lastName,
      //       productName: TempData?.selectedQuote?.productName,
      //       link: window.location.href.replace(
      //         /proposal-page/g,
      //         "proposal-page"
      //       ),
      //       logo: props?.getLogoUrl(),
      //       mobileNo: owner?.mobileNumber,
      //       to: `91${owner?.mobileNumber}`,
      //     })
      //   );

      // dispatch(
      //   ShareQuote({
      //     enquiryId: props?.enquiry_id,
      //     notificationType: "sms",
      //     mobileNo:owner?.mobileNumber,
      //     domain: `http://${window.location.hostname}`,
      //     type: "proposalCreated",
      //     emailId: owner?.email,
      //     firstName: owner?.firstName,
      //     lastName: owner?.lastName,
      //     productName: TempData?.selectedQuote?.productName,
      //     link: window.location.href.replace(/proposal-page/g, "proposal-page"),
      //     logo: props?.getLogoUrl(),
      //   })
      // );

      //whatsapp trigger logic
      if (process.env?.REACT_APP_BROKER === "OLA") {
        // dispatch(
        //   Whatsapp({
        //     proposalData: {
        //       review: CardData?.review,
        //       owner: owner,
        //       nominee: nominee,
        //       vehicle: vehicle,
        //       prepolicy: prepolicy,
        //     },
        //     enquiryId: props?.enquiry_id,
        //     notificationType: "whatsapp",
        //     domain: `http://${window.location.hostname}`,
        //     type: "proposalCreated",
        //     emailId: owner?.email,
        //     firstName: owner?.firstName,
        //     lastName: owner?.lastName,
        //     productName: TempData?.selectedQuote?.productName,
        //     url: window.location.href.replace(
        //       /proposal-page/g,
        //       "proposal-page"
        //     ),
        //     logo: props?.getLogoUrl(),
        //     to: `91${owner?.mobileNumber}`,
        //   })
        // );
      }

      dispatch(Lead({ enquiryId: props?.enquiry_id, leadStageId: 3 }));
      if (
        submit?.is_breakin &&
        submit?.is_breakin === "Y" &&
        !props?.breakinCase &&
        props?.TypeReturn(props?.type) !== "bike"
      ) {
        history.push(
          `/${props?.type}/successful?enquiry_id=${
            props?.enquiry_id
          }&inspection_no=${submit?.inspection_number}${
            dropout ? `&dropout=${Encrypt(true)}` : ``
          }${props?.token ? `&token=${props?.token}` : ``}&IC=${
            TempData?.quoteLog?.icAlias
          }${props?.typeId ? `&typeid=${props?.typeId}` : ``}${
            props?.journey_type ? `&journey_type=${props?.journey_type}` : ``
          }${
            submit?.finalPayableAmount * 1
              ? `&xmc=${window.btoa(submit?.finalPayableAmount)}`
              : ``
          }`
        );
      } else {
        // history.push(
        //   `/${props?.type}/payment-confirmation?enquiry_id=${
        //     props?.enquiry_id
        //   }${dropout ? `&dropout=true` : ``}${
        //     props?.token ? `&token=${props?.token}` : ``
        //   }`
        // )
        setShow1(true);
      }
    }

    return () => {
      dispatch(clear("submit"));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit]);

  //to store checkbox value
  const [terms_condition, setTerms_condition] = useState(
    process.env?.REACT_APP_BROKER !== "" ? true : false
  );

  //T&C
  const BrokerName = () => {
    switch (process.env?.REACT_APP_BROKER) {
      case "OLA":
        return "Ola Financial Services Private Limited";
      case "UNILIGHT":
        return "UNILIGHT Insurance Brokers Limited";
      case "CP":
        return "Compare Policy Private Limited";
      case "FYNTUNE":
        return "Fyntune Solutions Pvt. Ltd.";
      case "ABIBL":
        return "Aditya Birla Insurance Broker Limited";
      case "GRAM":
        return "GramCover Insurance Brokers Private Limited";
      case "ACE":
        return "ACE Insurance Broker Pvt. Ltd.";
      case "SRIYAH":
        return "Sriyah Insurance Brokers Pvt. Ltd";
      case "PINC":
        return "Pioneer Insurance & Reinsurance Brokers Pvt. Ltd.";
      case "EPOCH":
        return "Epoch Insurance Brokers Pvt. Ltd.";
      case "RB":
        return "D2C Insurance Broking Pvt. Ltd.";
      case "SPA":
        return "SPA Insurance Broking Services Ltd.";
      case "BIMA":
        return "SS Insurance Broking Services Ltd.";
      case "BAJAJ":
        return "Bajaj Capital Insurance Broking Limited";
      case "ANALAH":
        return "ANALAH Insurance Broking Limited";
      case "UIB":
        return "UIB Insurance Brokers (India) Pvt. Ltd.";
      default:
        return "Ola Financial Services Private Limited";
    }
  };

  console.log(TempData?.corporateVehiclesQuoteRequest?.previousNcb);

  /*--------------- Handle ZD or RTI Availablity -------------*/
  //ZD and RTI check
  const [zd_rti_condition, setZd_rti_condition] = useState(
    // !["royal_sundaram", "shriram", "icici_lombard", "tata_aig"]?.includes(
    //   TempData?.selectedQuote?.companyAlias
    // )
    //   ? true
    // :
    true
  );

  const ZD_Validation = () => {
    //getting list of addons applied to quote
    let applicableAddons = !_.isEmpty(TempData?.addons?.applicableAddons)
      ? TempData?.addons?.applicableAddons?.map((x) => x?.name)
      : [];

    return applicableAddons?.includes("Zero Depreciation") ? true : false;
  };
  const TS_Validation = () => {
    //getting list of addons applied to quote
    let applicableAddons = !_.isEmpty(TempData?.addons?.applicableAddons)
      ? TempData?.addons?.applicableAddons?.map((x) => x?.name)
      : [];

    return applicableAddons?.includes("Tyre Secure") ? true : false;
  };
  const ES_Validation = () => {
    //getting list of addons applied to quote
    let applicableAddons = !_.isEmpty(TempData?.addons?.applicableAddons)
      ? TempData?.addons?.applicableAddons?.map((x) => x?.name)
      : [];

    return applicableAddons?.includes("Engine Protector") ? true : false;
  };
  const CO_Validation = () => {
    //getting list of addons applied to quote
    let applicableAddons = !_.isEmpty(TempData?.addons?.applicableAddons)
      ? TempData?.addons?.applicableAddons?.map((x) => x?.name)
      : [];

    return applicableAddons?.includes("Consumable") ? true : false;
  };

  //Only for TATA AIG
  const RTI_Validation = () => {
    //getting list of addons applied to quote
    let applicableAddons = !_.isEmpty(TempData?.addons?.applicableAddons)
      ? TempData?.addons?.applicableAddons?.map((x) => x?.name)
      : [];

    return applicableAddons?.includes("Return To Invoice") ? true : false;
  };
  //accessories
  const Kit = () => {
    let accessories = !_.isEmpty(TempData?.addons?.accessories)
      ? TempData?.addons?.accessories?.map((x) => x?.name)
      : [];

    return accessories?.includes("External Bi-Fuel Kit CNG/LPG") ||
      TempData?.corporateVehiclesQuoteRequest?.fuelType === "CNG" ||
      TempData?.corporateVehiclesQuoteRequest?.fuelType === "LPG"
      ? true
      : false;
  };
  /*-------x------- Handle ZD or RTI Availablity ------x------*/

  //button condition check
  const btnColour =
    terms_condition &&
    !props?.overTime &&
    ((props?.breakinCase && TempData?.userProposal?.isInspectionDone === "Y") ||
      props?.TypeReturn(props?.type) === "bike" ||
      !props?.breakinCase) &&
    zd_rti_condition
      ? Theme?.proposalProceedBtn?.hex2
        ? Theme?.proposalProceedBtn?.hex2
        : "#4ca729"
      : "#787878";

  const btnCon =
    (terms_condition &&
    !props?.overTime &&
    ((props?.breakinCase && TempData?.userProposal?.isInspectionDone === "Y") ||
      props?.TypeReturn(props?.type) === "bike" ||
      !props?.breakinCase) &&
    zd_rti_condition
      ? false
      : true) || submitProcess
      ? true
      : false;

  const conOr =
    RTI_Validation() ||
    TS_Validation() ||
    ES_Validation() ||
    CO_Validation() ||
    Kit() ||
    "";
  const conTataAig = TempData?.selectedQuote?.companyAlias === "tata_aig";

  const addonFunction = (suppfn, excludeCon) => {
    return (
      (ZD_Validation() || excludeCon) &&
      conTataAig &&
      suppfn &&
      (props?.TypeReturn(props?.type) === "car" ||
        props?.TypeReturn(props?.type) === "bike")
    );
  };

  const conTS = addonFunction(TS_Validation());
  const conES = addonFunction(ES_Validation());
  const conCO = addonFunction(CO_Validation());
  const conRTI = addonFunction(RTI_Validation());
  const conZD = ZD_Validation();
  const conKit = addonFunction(Kit(), true);

  const addonarr = _.compact([
    conRTI ? "Return To Invoice" : "",
    conZD ? "Zero Depreciation" : "",
    conTS ? "Tyre Secure" : "",
    conES ? "Engine Protector" : "",
    conCO ? "Consumable" : "",
    conKit ? "External Bi-Fuel Kit CNG/LPG" : "",
  ]);

  const contentText = `I confirm that ${addonarr.join(", ")}
  ${
    addonarr.length > 1 ? "was" : "were" || ""
  } available in my previous policy.`;

  //applicable domains for zd
  const brokerList =
    process.env.REACT_APP_BROKER === "ABIBL" ||
    process.env.REACT_APP_BROKER === "ACE" ||
    process.env?.REACT_APP_BROKER === "GRAM" ||
    true;

  const domainsApplicable =
    process.env.REACT_APP_API_BASE_URL === "https://apimotor.fynity.in/api" ||
    brokerList;

  const ZD_preview_conditions =
    //applicable addons check
    (ZD_Validation() ||
      (TempData?.selectedQuote?.companyAlias === "tata_aig" && conOr)) &&
    //IC's which requires ZD in prev policy if ZD is selected in current quote
    // ["royal_sundaram", "shriram", "icici_lombard", "tata_aig"]?.includes(
    //   TempData?.selectedQuote?.companyAlias
    // ) &&
    //when previous insurer is present and not new and is not third party
    TempData?.corporateVehiclesQuoteRequest?.previousInsurer &&
    TempData?.corporateVehiclesQuoteRequest?.previousInsurer !== "NEW" &&
    TempData?.selectedQuote?.policyType !== "Third Party" &&
    TempData?.corporateVehiclesQuoteRequest?.previousPolicyType !==
      "Not sure" &&
    //Domain specific block
    domainsApplicable &&
    //product specific block
    props?.TypeReturn(props?.type) !== "bike" && true;

  const ncbCheck =
    TempData?.corporateVehiclesQuoteRequest?.previousPolicyType &&
    TempData?.selectedQuote?.policyType !== "Third Party" &&
    TempData?.corporateVehiclesQuoteRequest?.previousNcb
      ? TempData?.corporateVehiclesQuoteRequest?.previousNcb * 1
        ? `I confirm that NCB percentage declared is correct and no claims were made in the previous policy.`
        : ""
      : "" || "";

  const FinalSubmit = (
    <Row style={{ padding: "10.5px" }}>
      <Col xl="12" lg="12" md="12" sm="12">
        <SubmitDiv>
          <label className="checkbox-container">
            <input
              className="bajajCheck"
              id="checkboxId"
              defaultChecked={false}
              name="accept"
              type="checkbox"
              disabled={submitProcess}
              value={terms_condition}
              checked={terms_condition ? true : false}
              onChange={(e) => {
                process.env.REACT_APP_BROKER !== ""
                  ? setTerms_condition(e.target.checked)
                  : //for OLA , A confirmation is required before checking the T&C
                  e.target.checked
                  ? swal({
                      title: "Confirm Action",
                      text: `Are you sure you want to submit the proposal?`,
                      icon: "info",
                      buttons: {
                        cancel: "Cancel",
                        catch: {
                          text: "Confirm",
                          value: "confirm",
                        },
                      },
                      dangerMode: true,
                    }).then((caseValue) => {
                      switch (caseValue) {
                        case "confirm":
                          setTerms_condition(true);
                          break;
                        default:
                      }
                    })
                  : setTerms_condition(e.target.checked);
              }}
            />
            <span className="checkmark"></span>
          </label>
          <p
            className="privacyPolicy"
            style={{ cursor: "pointer" }}
            onClick={() => document.getElementById("checkboxId").click()}
          >
            {`I confirm all the details shared are correct and accurate as per my knowledge.
			        I agree with all the T&C and my vehicle has a valid PUC certificate.
			        I also declare that the information provided above is true and accept that if it is found to be false, it may impact claims.
			        I agree any changes to the details post payment might require additional payment.
			        ${BrokerName()} (including its representatives) shall not be held liable for any changes due to incorrect information.
              ${
                TempData?.gcvCarrierType === "PRIVATE"
                  ? `Valid documents supporting Private Carrier Cover required during claims.
                     Otherwise, it may lead to claim rejection by insurer.`
                  : ""
              }
              ${
                TempData?.corporateVehiclesQuoteRequest?.previousNcb
                  ? ncbCheck
                  : ""
              }
              `}
          </p>
        </SubmitDiv>
      </Col>
      {ZD_preview_conditions && (
        <Col xl="12" lg="12" md="12" sm="12">
          <SubmitDiv>
            <label className="checkbox-container">
              <input
                className="bajajCheck"
                defaultChecked={false}
                name="acceptZd"
                disabled={submitProcess}
                type="checkbox"
                value={zd_rti_condition}
                checked={zd_rti_condition ? true : false}
                onChange={(e) => {
                  setZd_rti_condition(e.target.checked);
                }}
              />
              <span className="checkmark"></span>
            </label>
            <p className="privacyPolicy">{contentText}</p>
          </SubmitDiv>
        </Col>
      )}
      <Col
        sm="12"
        md="12"
        lg="12"
        xl="12"
        className="d-flex justify-content-center"
      >
        <Button
          type="submit"
          buttonStyle="outline-solid"
          hex1={btnColour}
          hex2={btnColour}
          borderRadius="5px"
          color="white"
          disabled={btnCon}
          onClick={onFinalSubmit}
        >
          <text
            style={{
              fontSize: lessthan768 ? "12px" : "15px",
              padding: "-20px",
              margin: "-20px -5px -20px -5px",
              fontWeight: "400",
            }}
          >
            {props?.overTime ? (
              ` Please try again in ${
                Math.floor(props?.overTime / 60) +
                " Min : " +
                Math.floor(props?.overTime % 60)
              } Sec`
            ) : submitProcess ? (
              "Processing "
            ) : !props?.breakinCase ? (
              "Review & Submit"
            ) : (props?.breakinCase &&
                TempData?.userProposal?.isInspectionDone === "Y") ||
              props?.TypeReturn(props?.type) === "bike" ? (
              "Proceed"
            ) : (
              <text>
                <i className="fa fa-info-circle" /> Inspection Pending
              </text>
            )}
            {submitProcess ? (
              <Spinner animation="grow" variant="light" size="sm" />
            ) : (
              <noscript />
            )}
          </text>
        </Button>
      </Col>
    </Row>
  );
  /*---------------x----Review & Submit Section End----x--------------*/

  /*---------------------------card titles------------------------*/
  //Card Summary Title Function
  const titleFn = (titleName, stateName) => {
    return (
      <div style={{ display: "flex", width: "100%" }}>
        <Label style={{ width: "95%" }}>{titleName}</Label>
        {(!props?.breakinCase || props?.TypeReturn(props?.type) === "bike") &&
          !props?.icr && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "-2px",
              }}
            >
              <Btn
                size="sm"
                style={{ background: "transparent", border: "none" }}
                variant="light"
                onClick={() => {
                  if (
                    ["Payment Initiated", "Payment Failed"].includes(
                      TempData?.journeyStage?.stage
                    )
                  ) {
                    swal({
                      title: "Confirm Action",
                      text: `Payment status is Incomplete. To edit the Proposal an update is required.`,
                      icon: "info",
                      buttons: {
                        cancel: "Cancel",
                        catch: {
                          text: "Confirm",
                          value: "confirm",
                        },
                      },
                      dangerMode: true,
                    }).then((caseValue) => {
                      switch (caseValue) {
                        case "confirm":
                          return props?.GenerateDulicateEnquiry();
                          break;
                        default:
                      }
                    });
                  } else {
                    navigator &&
                      navigator?.vibrate &&
                      navigator.vibrate([100, 0, 50]);
                    stateName("form");
                    setDropout(false);
                  }
                }}
              >
                <i
                  class="fa fa-pencil-square-o"
                  aria-hidden="true"
                  style={{
                    color: "#fff",
                    fontWeight: "bold",
                    fontSize: "19px",
                  }}
                />
              </Btn>
            </div>
          )}
      </div>
    );
  };
  //owner
  const titleOwnerSummary = titleFn("Vehicle Owner Details", setFormOwner);
  //nominee
  const titleNomineeSummary = titleFn(
    process.env.REACT_APP_BROKER === "OLA" &&
      TempData?.corporateVehiclesQuoteRequest?.journeyType === "embeded-excel"
      ? "Nominee Details"
      : "Nominee Details",
    setFormNominee
  );
  //vehicle
  const titleVehicleSummary = titleFn("Vehicle Details", setFormVehicle);
  //pre-policy
  const titlePrepolicySummary = titleFn(
    PolicyCon && PACondition && props?.fields?.includes("cpaOptOut")
      ? "Previous Policy & CPA Details"
      : PolicyCon
      ? "Previous Policy Details"
      : "CPA Details",
    setFormPrepolicy
  );

  /*--------------- Handle-page-scroll -------------*/
  //using html to scroll instead of refs
  useEffect(() => {
    if (formOwner === "form") {
      !dropout &&
        (lessthan768 ? scrollToTop() : scrollToTargetAdjusted("owner", 45));
    }
    if (formNominee === "form") {
      !dropout && scrollToTargetAdjusted("nominee", 45);
    }
    if (formVehicle === "form") {
      !dropout && scrollToTargetAdjusted("vehicle", 45);
    }
    if (formPrepolicy === "form") {
      !dropout && scrollToTargetAdjusted("prepolicy", 45);
    }
    //scroll to t&c checkbox
    if (_.compact(finalSubmitCheck).every((elem) => elem === "summary")) {
      (process.env.REACT_APP_BROKER !== "OLA" || !dropout) &&
        scrollToTargetAdjusted("review-submit");
    }

    //eslint-disable-next-line
  }, [formOwner, formNominee, formVehicle, formPrepolicy]);
  /*-------x------- Handle-page-scroll ------x------*/

  /*--------------- Handle-dropout-fill -------------*/

  useLayoutEffect(() => {
    if (dropout && !_.isEmpty(CardData)) {
      if (!_.isEmpty(CardData?.owner)) {
        // setTimeout(() => {
        setOwner(CardData?.owner);
        setFormOwner("summary");
        // }, 1);
      }
      if (!_.isEmpty(CardData?.nominee)) {
        setTimeout(() => {
          setNominee(CardData?.nominee);
          setFormNominee("summary");
        }, 3);
      }
      if (!_.isEmpty(CardData?.vehicle)) {
        setTimeout(() => {
          setVehicle(CardData?.vehicle);
          setFormVehicle("summary");
        }, 5);
      }
      if (!_.isEmpty(CardData?.prepolicy)) {
        setTimeout(() => {
          setPrepolicy(CardData?.prepolicy);
          setFormPrepolicy("summary");
        }, 7);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CardData, dropout]);
  /*-------x------- Handle-dropout-fill ------x------*/

  /*--------------- Handle-step-error -------------*/
  useEffect(() => {
    if (error) {
      swal(
        "Error",
        `${`Trace ID:- ${props?.enquiry_id}.\n Error Message:- ${error}`}`,
        "error"
      ).then(() => {
        if (Number(TempData?.ownerTypeId) !== 2 && conditionChk) {
          //general flow
          if (formOwner === "summary" && formNominee === "form") {
            setFormOwner("form");
          }
          if (formNominee === "summary" && formVehicle === "form") {
            setFormNominee("form");
          }
          if (formVehicle === "summary" && formPrepolicy === "form") {
            setFormVehicle("form");
          }
          if (
            _.compact(finalSubmitCheck).every((elem) => elem === "summary") &&
            (PolicyCon || (PACondition && props?.fields?.includes("cpaOptOut")))
          ) {
            setFormPrepolicy("form");
          }
        } else {
          //flow without nominee
          if (formOwner === "summary" && formVehicle === "form") {
            setFormOwner("form");
          }
          if (formVehicle === "summary" && formPrepolicy === "form") {
            setFormVehicle("form");
          }
          if (
            _.compact(finalSubmitCheck).every((elem) => elem === "summary") &&
            (PolicyCon || (PACondition && props?.fields?.includes("cpaOptOut")))
          ) {
            setFormPrepolicy("form");
          }
        }
        //reloading page in certain case
        if (
          error === "Payment Link Already Generated..!" ||
          error === "Payment Initiated"
        ) {
          dispatch(props?.DuplicateEnquiryId({ enquiryId: props?.enquiry_id }));
        }
        //redirecting user if payment is lready done for the proposal
        if (error === "Transaction Already Completed") {
          reloadPage(
            `${window.location.protocol}//${window.location.host}${
              process.env.REACT_APP_BASENAME !== "NA"
                ? `/${process.env.REACT_APP_BASENAME}`
                : ``
            }/payment-success${
              props?.enquiry_id ? `?enquiry_id=${props?.enquiry_id}` : ``
            }`
          );
        }
      });
    }

    return () => {
      dispatch(clear());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  /*-------x------- Handle-step-error ------x------*/

  /*--------------- Handle redirectional-edit -------------*/
  useEffect(() => {
    if (props?.openTo) {
      //set info
      if (!_.isEmpty(CardData?.owner)) {
        setOwner(CardData?.owner);
      }
      if (!_.isEmpty(CardData?.nominee)) {
        setNominee(CardData?.nominee);
      }
      if (!_.isEmpty(CardData?.vehicle)) {
        setVehicle(CardData?.vehicle);
      }
      if (!_.isEmpty(CardData?.prepolicy)) {
        setPrepolicy(CardData?.prepolicy);
      }
      //set form
      setTimeout(() => {
        switch (props?.openTo) {
          case "owner":
            setFormOwner("form");
            setFormNominee("hidden");
            setFormVehicle("hidden");
            setFormPrepolicy("hidden");
            scrollToTargetAdjusted("owner", 45);
            break;
          case "vehicle":
            setFormOwner("summary");
            setFormNominee("summary");
            setFormVehicle("form");
            setFormPrepolicy("hidden");
            scrollToTargetAdjusted("vehicle", 45);
            break;
        }
      }, 200);
    }
  }, [props?.openTo]);
  /*-------x------- Handle redirectional-edit ------x------*/

  /*-----Handling non-availablity of Multi-Year-CPA-----*/
  useEffect(() => {
    if (
      !_.isEmpty(Tenure) &&
      !TempData?.selectedQuote?.multiYearCpa * 1 &&
      !cpaSet &&
      !PACondition
    ) {
      var data1 = {
        enquiryId: TempData?.enquiry_id || props?.enquiry_id,
        addonData: {
          compulsory_personal_accident: [
            {
              reason:
                "I have another motor policy with PA owner driver cover in my name",
            },
          ],
        },
      };
      dispatch(SaveAddonsData(data1, true));
      // swal({
      //   title: "Confirm Action",
      //   text: `${
      //     props?.TypeReturn(props?.type) === "bike" ? "5" : "3"
      //   } Years CPA is not available in this quote. Would you like to OPT for 1 Year CPA ?`,
      //   icon: "info",
      //   buttons: {
      //     catch: {
      //       text: "Yes",
      //       value: "confirm",
      //     },
      //     No: {
      //       text: "No",
      //       value: "No",
      //     },
      //   },
      //   dangerMode: true,
      //   closeOnClickOutside: false,
      // }).then((caseValue) => {
      //   switch (caseValue) {
      //     case "confirm":
      //       var data1 = {
      //         enquiryId: TempData?.enquiry_id || props?.enquiry_id,
      //         addonData: {
      //           compulsory_personal_accident: [
      //             { name: "Compulsory Personal Accident" },
      //           ],
      //         },
      //       };
      //       dispatch(SaveAddonsData(data1, true));
      //       break;
      //     case "No":
      //       var data1 = {
      //         enquiryId: TempData?.enquiry_id || props?.enquiry_id,
      //         addonData: {
      //           compulsory_personal_accident: [
      //             {
      //               reason:
      //                 "I have another motor policy with PA owner driver cover in my name",
      //             },
      //           ],
      //         },
      //       };
      //       dispatch(SaveAddonsData(data1, true));
      //       break;
      //     default:
      //   }
      // });
    } else if (
      _.isEmpty(Tenure) &&
      !_.isEmpty(OwnerPA) &&
      !TempData?.selectedQuote?.compulsoryPaOwnDriver * 1 &&
      !cpaSet &&
      !PACondition
    ) {
      var data1 = {
        enquiryId: TempData?.enquiry_id || props?.enquiry_id,
        addonData: {
          compulsory_personal_accident: [
            {
              reason:
                "I have another motor policy with PA owner driver cover in my name",
            },
          ],
        },
      };
      dispatch(SaveAddonsData(data1, true));
      // swal({
      //   title: "Confirm Action",
      //   text: ` One Year CPA is not available in this quote. Would you like to OPT for ${
      //     props?.TypeReturn(props?.type) === "bike" ? "5" : "3"
      //   } Years CPA ?`,
      //   icon: "info",
      //   buttons: {
      //     catch: {
      //       text: "Yes",
      //       value: "confirm",
      //     },
      //     No: {
      //       text: "No",
      //       value: "No",
      //     },
      //   },
      //   dangerMode: true,
      //   closeOnClickOutside: false,
      // }).then((caseValue) => {
      //   switch (caseValue) {
      //     case "confirm":
      //       var data1 = {
      //         enquiryId: TempData?.enquiry_id || props?.enquiry_id,
      //         addonData: {
      //           compulsory_personal_accident: [
      //             {
      //               name: "Compulsory Personal Accident",
      //               tenure: props?.TypeReturn(props?.type) === "bike" ? 5 : 3,
      //             },
      //           ],
      //         },
      //       };
      //       dispatch(SaveAddonsData(data1, true));
      //       break;
      //     case "No":
      //       var data1 = {
      //         enquiryId: TempData?.enquiry_id || props?.enquiry_id,
      //         addonData: {
      //           compulsory_personal_accident: [
      //             {
      //               reason:
      //                 "I have another motor policy with PA owner driver cover in my name",
      //             },
      //           ],
      //         },
      //       };
      //       dispatch(SaveAddonsData(data1, true));
      //       break;
      //     default:
      //   }
      // });
    }
  }, [Additional]);
  /*--x--Handling non-availablity of Multi-Year-CPA--x--*/
  return (
    <>
      <div>
        {/*--------------------Proposal Form-----------------------------------*/}
        <Card
          title={
            formOwner === "form" ? (
              <Label>Vehicle Owner Details</Label>
            ) : (
              titleOwnerSummary
            )
          }
          removeBottomHeader={true}
          backgroundColor={
            formOwner === "hidden" ? `` : formOwner === "summary" ? 1 : 2
          }
          paddingTop={formOwner === "summary" ? "8px" : "4.5px"}
          borderRadius={
            formOwner === "hidden" ? "5px 5px 5px 5px" : "5px 5px 0px 0px"
          }
          image={
            props?.TypeReturn(props?.type) === "bike"
              ? `${
                  process.env.REACT_APP_BASENAME !== "NA"
                    ? `/${process.env.REACT_APP_BASENAME}`
                    : "" || ""
                }/assets/images/vehicle/bike3.png`
              : props?.TypeReturn(props?.type) === "car"
              ? `${
                  process.env.REACT_APP_BASENAME !== "NA"
                    ? `/${process.env.REACT_APP_BASENAME}`
                    : "" || ""
                }/assets/images/taxi-car1.png`
              : getLogoCvType(Number(TempData?.productSubTypeId))
          }
          imageStyle={
            lessthan768
              ? {
                  position: "relative",
                  top: "1px",
                  left: "18px",
                }
              : {
                  position: "relative",
                  top: "-4px",
                  left: "18px",
                }
          }
          imageTagStyle={{
            boxShadow: "1.753px -3.595px 35px #d9d8d8",
            borderRadius: "15px",
            border:
              Theme?.proposalProceedBtn?.hex1 && !lessthan768
                ? `1px solid ${Theme?.proposalProceedBtn?.hex1} !important`
                : "1px solid #495057",
            background: Theme?.proposalProceedBtn?.hex1
              ? `${Theme?.proposalProceedBtn?.hex1}`
              : "rgba(125,142,3,1)",
            ...(TypeReturn(props?.type) === "cv" && {
              padding:
                TempData?.productSubTypeId * 1 === 6 ? "0px" : "7px" || "",
            }),
          }}
          id="owner"
        >
          {formOwner === "form" ? (
            <div className="ElemFade m-0 p-1">
              <OwnerCard
                onSubmitOwner={onSubmitOwner}
                owner={owner}
                CardData={CardData}
                Theme={Theme}
                conditionChk={conditionChk}
                lessthan768={lessthan768}
                lessthan376={lessthan376}
                enquiry_id={props?.enquiry_id}
                fields={props?.fields}
                type={props?.TypeReturn(props?.type)}
                token={props?.token}
              />
            </div>
          ) : (
            <SummaryOwner
              summary={owner}
              lessthan768={lessthan768}
              fields={props?.fields}
            />
          )}
        </Card>
        {/*---------------------------End of Proposal Card------------------------*/}
        {/*---------------------------Nominee Details Card-----------------------*/}
        {Number(TempData?.ownerTypeId) !== 2 && conditionChk && (
          <Card
            title={
              formNominee === "summary" ? (
                titleNomineeSummary
              ) : (
                <Label colState={formNominee}>
                  {process.env.REACT_APP_BROKER === "OLA" &&
                  TempData?.corporateVehiclesQuoteRequest?.journeyType ===
                    "embeded-excel"
                    ? "Nominee Details"
                    : "Nominee Details"}
                </Label>
              )
            }
            backgroundColor={
              formNominee === "hidden" ? `` : formNominee === "summary" ? 1 : 2
            }
            paddingTop={lessthan768 ? "10px" : "6px"}
            borderRadius={
              formNominee === "hidden" ? "5px 5px 5px 5px" : "5px 5px 0px 0px"
            }
            removeBottomHeader={true}
            marginTop={formNominee === "hidden" ? "5px" : ""}
            id="nominee"
          >
            <div
              style={
                formNominee === "hidden"
                  ? {
                      maxHeight: "0",
                      transition: "max-height 0.4s ease-in-out",
                    }
                  : {
                      maxHeight: "100%",
                      transition: "max-height 0.4s ease-in-out",
                    }
              }
            >
              {formNominee === "form" ? (
                <div className="ElemFade m-0 p-1">
                  <NomineeCard
                    onSubmitNominee={onSubmitNominee}
                    nominee={nominee}
                    CardData={CardData}
                    Theme={Theme}
                    lessthan768={lessthan768}
                    lessthan376={lessthan376}
                    PACondition={PACondition}
                    enquiry_id={props?.enquiry_id}
                    dropout={dropout}
                    NomineeBroker={NomineeBroker}
                    type={props?.TypeReturn(props?.type)}
                    Tenure={Tenure}
                    fields={props?.fields}
                  />
                </div>
              ) : formNominee === "summary" ? (
                <div className="m-0 p-1">
                  <SummaryProposal
                    data={nominee}
                    lessthan768={lessthan768}
                    fields={props?.fields}
                  />
                </div>
              ) : (
                <noscript />
              )}
            </div>
          </Card>
        )}
        {/*---------------x----End of Nominee Details Card--------x-----------*/}
        {/*---------------------------Vehicle Details Card-----------------------*/}
        <Card
          title={
            formVehicle === "summary" ? (
              titleVehicleSummary
            ) : (
              <Label colState={formVehicle}>Vehicle Details</Label>
            )
          }
          backgroundColor={
            formVehicle === "hidden" ? `` : formVehicle === "summary" ? 1 : 2
          }
          paddingTop={lessthan768 ? "10px" : "6px"}
          borderRadius={
            formVehicle === "hidden" ? "5px 5px 5px 5px" : "5px 5px 0px 0px"
          }
          removeBottomHeader={true}
          marginTop={formVehicle === "hidden" ? "5px" : ""}
          id="vehicle"
        >
          <div
            style={
              formVehicle === "hidden"
                ? { maxHeight: "0", transition: "max-height 0.4s ease-in-out" }
                : {
                    maxHeight: "100%",
                    transition: "max-height 0.4s ease-in-out",
                  }
            }
          >
            {formVehicle === "form" ? (
              <div className="ElemFade m-0 p-1">
                <VehicleCard
                  onSubmitVehicle={onSubmitVehicle}
                  vehicle={vehicle}
                  CardData={CardData}
                  Theme={Theme}
                  type={props?.type}
                  lessthan768={lessthan768}
                  lessthan376={lessthan376}
                  fields={props?.fields}
                  PolicyCon={PolicyCon}
                  TypeReturn={props?.TypeReturn}
                  enquiry_id={props?.enquiry_id}
                  token={props?.token}
                />
              </div>
            ) : formVehicle === "summary" ? (
              <div className="m-0 p-1">
                <SummaryVehicle
                  summary={vehicle}
                  Theme={Theme}
                  temp={TempData}
                  lessthan768={lessthan768}
                  fields={props?.fields}
                />
              </div>
            ) : (
              <noscript />
            )}
          </div>
        </Card>
        {/*---------------x----End of Vehicle Details Card--------x-----------*/}
        {/*---------------------------Policy Details Card-----------------------*/}
        {(PolicyCon ||
          (PACondition && props?.fields?.includes("cpaOptOut"))) && (
          <Card
            title={
              formPrepolicy === "summary" ? (
                titlePrepolicySummary
              ) : (
                <Label colState={formPrepolicy}>
                  {PolicyCon &&
                  PACondition &&
                  props?.fields?.includes("cpaOptOut")
                    ? "Previous Policy & CPA Details"
                    : PolicyCon
                    ? "Previous Policy Details"
                    : "CPA Details"}
                </Label>
              )
            }
            backgroundColor={
              formPrepolicy === "hidden"
                ? ``
                : formPrepolicy === "summary"
                ? 1
                : 2
            }
            paddingTop={lessthan768 ? "10px" : "6px"}
            borderRadius={
              formPrepolicy === "hidden" ? "5px 5px 5px 5px" : "5px 5px 0px 0px"
            }
            removeBottomHeader={true}
            marginTop={formPrepolicy === "hidden" ? "5px" : ""}
            id="prepolicy"
          >
            <div
              style={
                formPrepolicy === "hidden"
                  ? {
                      maxHeight: "0",
                      transition: "max-height 0.4s ease-in-out",
                    }
                  : {
                      maxHeight: "100%",
                      transition: "max-height 0.4s ease-in-out",
                    }
              }
            >
              {formPrepolicy === "form" ? (
                <div className="ElemFade m-0 p-1">
                  <PolicyCard
                    onSubmitPrepolicy={onSubmitPrepolicy}
                    prepolicy={prepolicy}
                    CardData={CardData}
                    prevPolicyCon={PolicyCon}
                    PACon={PACondition && props?.fields?.includes("cpaOptOut")}
                    enquiry_id={props?.enquiry_id}
                    Theme={Theme}
                    type={props?.type}
                    OwnDamage={
                      TempData?.selectedQuote?.policyType === "Own Damage" ||
                      TempData?.corporateVehiclesQuoteRequest?.policyType ===
                        "own_damage"
                        ? true
                        : false
                    }
                    lessthan768={lessthan768}
                    lessthan376={lessthan376}
                    isNcbApplicable={isNcbApplicable}
                    TypeReturn={props?.TypeReturn}
                    fields={props?.fields}
                    PolicyValidationExculsion={PolicyValidationExculsion}
                  />
                </div>
              ) : formPrepolicy === "summary" ? (
                <div className="m-0 p-1">
                  <SummaryProposal
                    data={prepolicy}
                    lessthan768={lessthan768}
                    PolicyValidationExculsion={PolicyValidationExculsion}
                  />
                </div>
              ) : (
                <noscript />
              )}
            </div>
          </Card>
        )}
        {/*---------------x----End of Policy Details Card--------x-----------*/}
        {/*---------------x----Review & Submit--------x-----------*/}
        <div id="review-submit">{finalSubmit && FinalSubmit}</div>
        {/*---------------x----End of Review & Submit--------x-----------*/}
      </div>
      {/*--------------------OTP Modal-------------------*/}
      <OTPPopup
        enquiry_id={props?.enquiry_id}
        show={show}
        onHide={() => setShow(false)}
        mobileNumber={owner?.mobileNumber}
        otpSuccess={() => otpSuccess()}
        email={owner?.email}
      />
      {/*---------------x----OTP Modal--------x-----------*/}
      {/*---------------------Payment Modal--------------------*/}
      <PaymentModal
        enquiry_id={props?.enquiry_id}
        show={show1}
        onHide={() => setShow1(false)}
        type={props?.type}
        payment={payment}
        token={props?.token}
      />
      {/*---------------x----Payment Modalt--------x-----------*/}
      {/*--------------------Pre-Submit Modal-------------------*/}
      <PreSubmit
        enquiry_id={props?.enquiry_id}
        show={show2}
        onHide={() => setShow2(false)}
        selection={() => selection()}
      />
      {/*---------------x----Pre-Submit Modal--------x-----------*/}
    </>
  );
};

export default FormSection;
